import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Api from "../../../Api";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import ControlPanel from "../ControlPanel";
import { DateTime } from "luxon";
import ClientsFeedback from "./ClientsFeedback";
import { AppBar, Dialog, Toolbar, Typography } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import ProjectsStatistics from "./ProjectsStatistics";
import InAppStatistics from "./InAppStatistics";
import RegistrationsStatistics from "./RegistrationsStatistics";
import UsersStatistics from "./UsersStatistics";
import SignOffsStatistics from "./SignOffsStatistics";
import FrontendErrorsStatistics from "./FrontendErrorsStatistics";

export default function StatisticsPage() {
  const [lastFeedbackAt, setLastFeedbackAt] = useState();
  const [feedbackMessages, setFeedbackMessages] = useState([]);
  const [lasSignOffAt, setLasSignOffAt] = useState();
  const [signOffMessages, setSignOffMessages] = useState([]);
  const [teambookStatistics, setTeambookStatistics] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogComponent, setDialogComponent] = useState();
  const [frontendErrors, setFrontendErrors] = useState([]);
  const ADMIN_TOKEN_KEY = "tb-admin-token";

  useEffect(() => {
    Api.Administrators.get_feedback(localStorage.getItem(ADMIN_TOKEN_KEY)).then((response) => {
      setFeedbackMessages(response.data);
      setLastFeedbackAt(response.data.at(0).created_at);
    });

    Api.Administrators.get_tb_stats(localStorage.getItem(ADMIN_TOKEN_KEY)).then((response) => {
      setTeambookStatistics(response.data);
    });

    Api.Administrators.get_sign_offs(localStorage.getItem(ADMIN_TOKEN_KEY)).then((response) => {
      setSignOffMessages(response.data);
      setLasSignOffAt(response.data.at(0).created_at);
    });

    Api.Administrators.get_frontend_errors(localStorage.getItem(ADMIN_TOKEN_KEY)).then((response) => {
      setFrontendErrors(response.data);
    });
  }, []);

  const openDialog = (content) => {
    setDialogComponent(content);
    setDialogOpen(true);
  };

  return (
    <>
      <Link to="/administration">
        <TeambookBlueButton
          style={{
            zIndex: "1",
            position: "absolute",
            left: "5px",
            top: "5px",
          }}
          text={"To Administration"}
        />
      </Link>

      <div className="administration__content-list">
        <div
          className="administration__statistics__block"
          onClick={() => openDialog(<ClientsFeedback messages={feedbackMessages} />)}
        >
          <p>
            <b>User Feedback</b>
          </p>
          <p>last at: {DateTime.fromISO(lastFeedbackAt).toFormat("dd/MM/yyyy")}</p>
        </div>

        <div
          className="administration__statistics__block"
          onClick={() => openDialog(<ProjectsStatistics teambookStatistics={teambookStatistics} />)}
        >
          <p>
            <b>Projects Statistics</b>
          </p>
        </div>

        <div
          className="administration__statistics__block"
          onClick={() => openDialog(<UsersStatistics teambookStatistics={teambookStatistics} />)}
        >
          <p>
            <b>Users Statistics</b>
          </p>
        </div>

        <div
          className="administration__statistics__block"
          onClick={() => openDialog(<InAppStatistics teambookStatistics={teambookStatistics} />)}
        >
          <p>
            <b>In-App Statistics</b>
          </p>
        </div>

        <div
          className="administration__statistics__block"
          onClick={() => openDialog(<RegistrationsStatistics teambookStatistics={teambookStatistics} />)}
        >
          <p>
            <b>Registrations</b>
          </p>
        </div>

        <div
          className="administration__statistics__block"
          onClick={() => openDialog(<SignOffsStatistics stats={signOffMessages} />)}
        >
          <p>
            <b>Sign OFFs</b>
          </p>
          <p>last at: {DateTime.fromISO(lasSignOffAt).toFormat("dd/MM/yyyy")}</p>
        </div>

        <div
          className="administration__statistics__block"
          onClick={() => openDialog(<FrontendErrorsStatistics errors={frontendErrors} />)}
        >
          <p>
            <b>Frontend Errors</b>
          </p>
        </div>
      </div>

      <Dialog fullScreen open={dialogOpen} onClose={() => setDialogOpen(false)}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={() => setDialogOpen(false)} aria-label="close">
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        {dialogComponent}
      </Dialog>

      <ControlPanel />
    </>
  );
}
