import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../Api";
import SpreadDialog from "./SpreadDialog";
import { recalculateProjectCapacityArray } from "../lib/recalculateCapacityArray";
import { WhiteTooltip } from "../../default_components/Tooltips";
import lsKeys from "../../default_values/defaultKeys";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { useProfileStore } from "../../../stores/profileStore";
import { shallow } from "zustand/shallow";

const ProjectsBlock = ({
  project,
  date,
  capacityReservations,
  user,
  updateUserReservations,
  jumpToProject,
  selectedViewType,
  isLastProject,
}) => {
  const { t } = useTranslation();
  const [calculatedDurations, setCalculatedDurations] = useState([]);
  const [spreadOpened, setSpreadOpened] = useState(false);
  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const fullAccessRoles = ["admin", "planner"];
  const role = localStorage.getItem(lsKeys.ROLE);

  useEffect(() => {
    setCalculatedDurations(recalculateProjectCapacityArray(date, capacityReservations, project, selectedViewType));
  }, [capacityReservations, date, project]);

  const openSpreadMenu = () => {
    setSpreadOpened(true);
  };

  const updateReservation = (newValue, index) => {
    let newCalculated = [...calculatedDurations];

    newCalculated[index] = newValue;

    Api.CapacityReservations.create({
      user_id: user.id,
      project_id: project.id,
      days_reserved: newValue,
      date: date.plus({ months: index }).toISODate(),
    }).then(() => {
      updateUserReservations(index);
    });

    setCalculatedDurations(newCalculated);
  };

  const handleFocus = (event) => event.target.select();

  const userHasAccessForInput = () => {
    return fullAccessRoles.includes(role) || (role === "self_planner" && profile.id === user.id);
  };

  return (
    <div className="flex project-row">
      <div style={{ borderBottomLeftRadius: isLastProject ? 7 : 0 }} className="users-block__project-info-main">
        <div
          className="flex arrow-name"
          onClick={() => jumpToProject(project.id)}
          title={t("planning.capacity.go_to_project")}
        >
          <div style={{ background: project.color }} className="project-list__project-color" />

          <p>{project.name}</p>

          <TeambookIcon
            className="cursor-pointer"
            name={icons.ARROW_DOWN}
            color="darkblue"
            style={{ transform: "rotate(-90deg)", margin: "0px 40px 0px auto" }}
          />
        </div>

        {userHasAccessForInput() && (
          <div
            className="dashboard__tb-button teambook-icon-frame"
            onClick={openSpreadMenu}
            style={{
              marginRight: "15px",
            }}
            title={t("planning.spread")}
          >
            <TeambookIcon color="alternative_default" name={icons.SPREAD} style={{ width: "18px" }} />
          </div>
        )}
      </div>

      {[...Array(selectedViewType)].map((x, i, arr) => (
        <div
          style={{
            borderRight: arr.length - 1 === i ? "1px solid var(--stroke)" : "",
            borderBottomRightRadius: isLastProject && arr.length - 1 === i && 7,
          }}
          className="table-cell"
        >
          {userHasAccessForInput() ? (
            <input
              onChange={(event) => updateReservation(event.target.value >= 0 ? event.target.value : 0, i)}
              onWheel={(e) => e.target.blur()}
              value={parseInt(calculatedDurations[i]) || 0}
              style={{
                width: "100%",
                boxShadow: "-1px 0px 0px 0px var(--stroke)",
                borderLeft: "unset",
                borderBottomRightRadius: isLastProject && arr.length - 1 === i && 7,
              }}
              type={"number"}
              onFocus={handleFocus}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => e.preventDefault()}
            />
          ) : (
            <p>{parseInt(calculatedDurations[i])}</p>
          )}
        </div>
      ))}

      {spreadOpened && (
        <SpreadDialog
          setDialogOpened={setSpreadOpened}
          date={date}
          project={project}
          user={user}
          updateReservations={updateUserReservations}
        />
      )}
    </div>
  );
};

export default ProjectsBlock;
