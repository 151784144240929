import React from "react";
import "./header_menu.scss";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { LanguagePicker } from "../../default_components/LanguagePicker";
import Menu from "@mui/material/Menu";
import DarkModeButton from "./DarkModeButton";
import ProfileIcon from "../header_icons/profile.svg";
import CalendarIcon from "../header_icons/calendar.svg";
import OrganizationIcon from "../../default_images/clientsite_black.svg";
import RoadmapIcon from "../header_icons/roadmap.svg";
import WhatsNewIcon from "../header_icons/whatsnew.svg";
import ContactUsIcon from "../header_icons/contact_us.svg";
import LogoutIcon from "../header_icons/logout.svg";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const HeaderMenu = ({ anchorEl, userMenuOpened, closeMenu, openWhatsNew, changeLanguage, role, lang, logout }) => {
  const { t } = useTranslation();
  return (
    <Menu id="header-menu" anchorEl={anchorEl} keepMounted open={userMenuOpened} onClose={closeMenu} className="header-menu">
      <div className="header-menu__section mobile_hidden">
        <NavLink to="/profile">
          <div className="header-menu__item-content" style={{ borderRadius: "10px 10px 0px 0px" }}>
            <TeambookIcon name={icons.PROFILE} />
            {t("header.profile")}
          </div>
        </NavLink>

        <NavLink to="/my-week">
          <div className="header-menu__item-content">
            <TeambookIcon name={icons.WEEK} />
            {t("header.my_week")}
          </div>
        </NavLink>

        {["admin"].includes(role) && (
          <NavLink to="/settings">
            <div className="header-menu__item-content">
              <TeambookIcon name={icons.PLACE} />
              {t("header.account_settings")}
            </div>
          </NavLink>
        )}
      </div>

      <div className="header-menu__section">
        <a
          href="https://trello.com/b/tvfKlHuF/teambook-product-roadmap"
          target="_blank"
          rel="noopener noreferrer"
          style={{ borderTop: "1px solid var(--stroke)" }}
        >
          <div className="header-menu__item-content">
            <TeambookIcon name={icons.ROADMAP} />
            {t("header.product_roadmap")}
          </div>
        </a>

        <a target="_blank" rel="noopener noreferrer" onClick={openWhatsNew}>
          <div className="header-menu__item-content">
            <TeambookIcon name={icons.WHATS_NEW} />
            {t("header.whats_new")}
          </div>
        </a>

        <a href={t("header.affiliation_url")} target="_blank" rel="noopener noreferrer">
          <div className="header-menu__item-content">
            <TeambookIcon name={icons.DOLLAR} />
            {t("header.affiliation")}
          </div>
        </a>

        <LanguagePicker
          className="desktop_hidden"
          changeLanguage={changeLanguage}
          currentLang={lang}
          flagStyle={{ width: 28, height: 28 }}
          style={{
            padding: "10px",
            gap: 10,
          }}
        />

        <div className="desktop_hidden">
          <a href={t("header.kb_link")} target="_blank" rel="noopener noreferrer">
            <div className="header-menu__item-content">{t("header.kb")}</div>
          </a>
        </div>
      </div>

      <DarkModeButton />

      <div className="header-menu__item-content" onClick={logout} style={{ borderRadius: "0px 0px 10px 10px" }}>
        <TeambookIcon name={icons.LOG_OUT} />
        <p>{t("header.logout")}</p>
      </div>
    </Menu>
  );
};

export default HeaderMenu;
