import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../Api";

const Notification = ({ text, header, lang }) => {
  const { t } = useTranslation();

  const [whatsNew, setWhatsNew] = useState([]);

  useEffect(() => {
    Api.Administrators.get_whats_new(lang || "en").then((response) => {
      setWhatsNew(response.data);
    });
  }, []);

  return (
    <div className="mobile_hidden notification-body">
      <div className="notification-header">
        <p>{header}</p>
      </div>

      <div className="notification-content">
        <ul className="notification-content-text">
          {whatsNew.slice(0, 3).map((news) => (
            <li style={{ fontFamily: "Montserrat" }}>
              <b>{news.title}</b>: {news.description}
            </li>
          ))}
        </ul>

        <p style={{ fontSize: "14px" }}>
          {t("auth.contact_us").split("__")[0]}
          <a target={"_blank"} href="https://trello.com/b/tvfKlHuF/teambook-product-roadmap">
            Teambook Road Map
          </a>

          {t("auth.contact_us").split("__")[1]}

          <a href="mailto:support@teambookapp.com" target="_blank" rel="noopener noreferrer" style={{ cursor: "pointer" }}>
            {t("header.support_email").toLowerCase()}
          </a>

          {t("auth.contact_us").split("__")[2]}
        </p>
      </div>
    </div>
  );
};

export default Notification;
