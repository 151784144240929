import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import lsKeys from "../../default_values/defaultKeys";
import { WhiteTooltip } from "../../default_components/Tooltips";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const PlannerSelect = ({ className, pageName }) => {
  const { t } = useTranslation();
  const capacityRoles = ["admin", "planner", "self_planner"];
  const role = localStorage.getItem(lsKeys.ROLE);

  const isActive = (page) => page === pageName;
  return (
    role !== "contractor" && (
      <div className={"planner-type-select__shown-component " + className}>
        <div
          style={{
            background: isActive(t("planning.select_by_users")) ? "var(--planner-select-view-button)" : "unset",
            borderTopLeftRadius: 6,
            borderBottomLeftRadius: 6,
          }}
          className="planner-type-select__dropdown-option"
        >
          <WhiteTooltip title={t("planning.select_by_users")}>
            <Link to={"/planners"} style={{ textDecoration: "none" }}>
              <TeambookIcon style={{ width: 22, height: 22 }} name={icons.USERS} color="darkblue" />
            </Link>
          </WhiteTooltip>
        </div>

        <div
          style={{
            background: isActive(t("planning.select_by_grouped")) ? "var(--planner-select-view-button)" : "unset",
            borderLeft: "1px solid var(--stroke)",
          }}
          className="planner-type-select__dropdown-option"
        >
          <WhiteTooltip title={t("planning.select_by_grouped")}>
            <Link to={"/grouped_planners"} style={{ textDecoration: "none" }}>
              <TeambookIcon style={{ width: 22, height: 22 }} name={icons.PROJECT} color="darkblue" />
            </Link>
          </WhiteTooltip>
        </div>

        {/* {capacityRoles.includes(localStorage.getItem(lsKeys.ROLE)) && (
          <div
            style={{
              background: isActive(t("planning.select_by_capacity"))
                ? "var(--planner-select-view-button)"
                : "unset",
              borderTopRightRadius: 6,
              borderBottomRightRadius: 6,
            }}
            className="planner-type-select__dropdown-option"
          >
            <Link to={"/capacity_planning"} style={{ textDecoration: "none" }}>
              <TeambookIcon
                name={icons.INFINITY}
                color="alternative_default"
                tooltipTitle={t("planning.select_by_capacity")}
              />
            </Link>
          </div>
        )} */}
      </div>
    )
  );
};

export default PlannerSelect;
