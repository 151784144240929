import React, { useEffect, useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Api from "../../../Api";
import { useTranslation } from "react-i18next";
import GetAllUsersRequest from "../requests/GetAllUsersRequest";
import GetTeamUsers from "../requests/GetTeamUsers";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import CreateUserDialog from "./CreateUserDialog";
import { TeambookProjectsPicker, TeambookUsersPicker } from "../../default_components/TeambookDefaultPickers";
import DeleteUserFromTeamRequest from "../requests/DeleteUserFromTeamRequest";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { useDataChange } from "../../../lib/ussDataChange";
import WarningModal from "../../default_components/WarningModal";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const AddProjectDialog = ({ handleClose, allProjects, visibleProjectIds, addProjects }) => {
  const { t } = useTranslation();
  const [selectedProjects, setSelectedProjects] = useState([]);

  return (
    <>
      <Dialog
        className="planner-add-user-dialog tb-default-dialog"
        open={true}
        onClose={handleClose}
        aria-labelledby="add-user-dialog"
        maxWidth={"sm"}
        id={"add-user-dialog-popup"}
      >
        <DialogTitle className="planner-add-user-dialog__dialog-title" id="add-user-dialog">
          <p>{t("planning.add_projects_to_screen")}</p>

          <TeambookIcon
            onClick={handleClose}
            className="addUserDialog__closingCross"
            name={icons.CROSS}
            alt={"cross"}
          />
        </DialogTitle>

        <DialogContent style={{ width: 450, padding: "0px 40px 20px 40px" }}>
          <TeambookProjectsPicker
            displayEmpty={true}
            projects={allProjects.filter((pr) => !visibleProjectIds.includes(pr.id))}
            defaultValue={[]}
            placeholder={t("planning.select_projects")}
            maxMenuHeight={150}
            type="multiple"
            onChange={(options, newValue) => {
              setSelectedProjects(Array.isArray(options) ? options : []);
            }}
            isSearchable={true}
          />
        </DialogContent>

        <DialogActions className="add-user__dialog-actions">
          <TeambookBlueButton
            onClick={() => addProjects(selectedProjects)}
            color="primary"
            text={t("save")}
            style={{
              border: "1px solid #4C54A0 !important",
              backgroundColor: "#4C54A0",
            }}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddProjectDialog;
