import { CalcWeeksInMonth } from "../../../lib/DateUtils";
import React from "react";
import moment from "moment";
import ActualWeek from "./ActualWeek";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const ActualDays = ({
  date,
  projects,
  user,
  occupations,
  activities,
  createActivity,
  createOccupation,
  actualLogs,
  updateActuals,
  changeContextMenu,
  changeTooltip,
  copyEnabled,
  copyLogRequest,
  showWeekendLogs,
}) => {
  const [account] = useAccountStore((state) => [state.account], shallow);
  const weeksCount = CalcWeeksInMonth(moment(date), account.first_week_day);
  const beginningDate =
    account.first_week_day === "Sunday" ? date.startOf("week").minus({ day: 1 }) : date.startOf("week");

  let weeks = [];

  for (let index = 0; index < weeksCount; index++) {
    weeks.push(
      <ActualWeek
        projects={projects}
        changeContextMenu={changeContextMenu}
        date={date}
        beginningDate={beginningDate}
        index={index}
        actualLogs={actualLogs}
        user={user}
        updateActuals={updateActuals}
        occupations={occupations}
        activities={activities}
        createActivity={createActivity}
        createOccupation={createOccupation}
        changeTooltip={changeTooltip}
        copyEnabled={copyEnabled}
        copyLogRequest={copyLogRequest}
        showWeekendLogs={showWeekendLogs}
        weeksCount={weeksCount}
      />
    );
  }

  return (
    <div style={{ paddingBottom: 20 }} className="actuals__calendar">
      {weeks}
    </div>
  );
};

export default ActualDays;
