import React from "react";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import { DialogTitle } from "@mui/material";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";

const SubscribeToPlanModal = ({ subscribeToPlan, closeModal, selectedPlan, isActualsSelected }) => {
  const { t } = useTranslation();

  return (
    <>
      <DialogTitle id="add-user-dialog">
        <p>{t("settings.subscription.name")}</p>
        <TeambookIcon name={icons.CROSS} onClick={closeModal} />
      </DialogTitle>

      <DialogContent style={{ margin: "20px 0px" }}>
        <p>
          {t("settings.subscription.are_you_sure", {
            price: ((isActualsSelected ? selectedPlan.price_cents * 2 : selectedPlan.price_cents) / 100).toString(),
          })}
        </p>
      </DialogContent>

      <DialogActions>
        <TeambookBlueButton onClick={() => subscribeToPlan()} text={t("yes")} />
      </DialogActions>
    </>
  );
};

export default SubscribeToPlanModal;
