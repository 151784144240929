import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { getNumberOfDays, getCellWidthForZoom, OffsetDays, GetDaysInZoom } from "../../../lib/DateUtils";
import {
  DAY_VIEW_HEIGHT,
  DAY_VIEW_START_HOUR,
  DAY_VIEW_WIDTH,
  PLANNER_USER_WIDTH,
  getPlannerOffsetX,
  getPlannerOffsetY,
  GROUPED_PLANNER_USER_WIDTH,
} from "../../../lib/PlannerUtils";
import { useZoomStore } from "../../../stores/planner";
import { shallow } from "zustand/shallow";
import { notificationStore } from "../../../stores/notificationStore";
import lsKeys from "../../default_values/defaultKeys";
import { useProfileStore } from "../../../stores/profileStore";

const formatMapping = {
  0: 5,
  1: 10,
  2: 15,
};

const UserBlockedSpace = ({
  maxHeight,
  bookingFormat,
  date,
  weekendsHidden,
  schedule,
  topOffset,
  mouseMoved,
  mouseUped,
  mouseDowned,
  filteredUsers,
  user,
  groupClients,
  groupedView = false,
}) => {
  const [setInAppNotification] = notificationStore((state) => [state.setInAppNotification], shallow);
  const userRole = localStorage.getItem("tb-role") || "regular";
  const isGroupedPlanner = localStorage.getItem(lsKeys.LAST_PLANNER_TYPE) === "grouped_planners";
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);
  const [currentUser] = useProfileStore((state) => [state.profile], shallow);
  const { t } = useTranslation();

  const calculateLeftOffset = (_date) => {
    let dateOffset = getNumberOfDays(date, _date);
    const plannerOffset = groupedView ? GROUPED_PLANNER_USER_WIDTH : PLANNER_USER_WIDTH;
    let finalOffset = isGroupedPlanner ? plannerOffset : 0;
    const initialWeekDay = date.getDay();

    if (groupClients) {
      finalOffset += 50;
    }

    for (let i = 0; i < dateOffset; i++) {
      const weekday = (initialWeekDay + i) % 7;
      const isWeekend = weekendsHidden && (weekday === 0 || weekday === 6);

      finalOffset += isWeekend ? 10 : getCellWidthForZoom(zoom);
    }

    return finalOffset;
  };

  const calculateHeight = (_date) => {
    if (zoom === 1) {
      return DAY_VIEW_HEIGHT;
    }

    if (userNotYetWorking(_date)) {
      return maxHeight;
    }

    return maxHeight - currentRowOffset(_date) - 17;
  };

  const calculateOffsetTop = (_date) => {
    if (zoom === 1) {
      return topOffset;
    }

    if (userNotYetWorking(_date)) {
      return topOffset;
    }

    return topOffset + currentRowOffset(_date);
  };

  const currentRowOffset = (_date) => {
    const dateSchedule = schedule[_date.getDay()];
    return ((dateSchedule[0] + dateSchedule[2]) / 60) * formatMapping[bookingFormat];
  };

  const userNotYetWorking = (date) => {
    if (user.start_date && date < new Date(user.start_date)) {
      return true;
    } else if (user.end_date && date > OffsetDays(new Date(user.end_date), 1)) {
      return true;
    } else {
      return false;
    }
  };

  const bookingObjectStyles = (_date) => {
    return {
      width: `${getCellWidthForZoom(zoom) - 1}px`,
      left: `${calculateLeftOffset(_date)}px`,
      height: `${calculateHeight(_date)}px`,
      top: `${calculateOffsetTop(_date)}px`,
      display: calculateHeight(_date) === 0 ? "none" : "block",
      transform: `translate3d:${calculateLeftOffset(_date)}px ,${calculateOffsetTop(_date)}px, 0`,
    };
  };

  const blockedScheduleStyles = (_date, index) => {
    const schedule = user.schedule[_date.getDay()];
    const morningOffset = DAY_VIEW_WIDTH * ((schedule[3] - DAY_VIEW_START_HOUR * 60) / 60);
    const lunchWidth = DAY_VIEW_WIDTH * (schedule[1] / 60);
    const lunchOffset = morningOffset + DAY_VIEW_WIDTH * (schedule[0] / 60);
    const eveningOffset = lunchOffset + lunchWidth + DAY_VIEW_WIDTH * (schedule[2] / 60);
    const fullWidth = 16 * DAY_VIEW_WIDTH;

    if (index === 0) {
      return {
        height: `${calculateHeight(_date)}px`,
        top: `${calculateOffsetTop(_date)}px`,
        width: morningOffset,
        left: 0,
        cursor: "unset",
      };
    } else if (index === 1) {
      return {
        height: `${calculateHeight(_date)}px`,
        top: `${calculateOffsetTop(_date)}px`,
        width: lunchWidth,
        left: lunchOffset,
        cursor: "unset",
      };
    } else {
      return {
        height: `${calculateHeight(_date)}px`,
        top: `${calculateOffsetTop(_date)}px`,
        width: fullWidth - eveningOffset,
        left: eveningOffset,
        cursor: "unset",
      };
    }
  };

  let blockedSpace = [];

  for (let i = 0; i < GetDaysInZoom(zoom); i += 1) {
    let currentDate = OffsetDays(date, i);
    let isWeekend = currentDate.getDay() === 0 || currentDate.getDay() === 6;

    if (((weekendsHidden && !isWeekend) || !weekendsHidden) && zoom !== 1) {
      blockedSpace.push(
        <div
          className="planner__blocked-space"
          style={bookingObjectStyles(currentDate)}
          onMouseDown={(e) => {
            if (e.button === 1) return;
            if (["contractor", "regular"].includes(userRole)) return;

            if (userRole === "self_planner" && user.id !== currentUser.id) {
              setInAppNotification(t("notifications.planning.self_planner_creating_error"));
              return;
            }

            mouseDowned(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers);
          }}
          onMouseMove={(e) => {
            if (["contractor", "regular"].includes(userRole)) return;

            mouseMoved(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers, e);
          }}
          onMouseUp={(e) => {
            mouseUped(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers, e);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          key={`user-blocked-${user.id}-${i}`}
        />
      );
    } else if (zoom === 1) {
      for (let i = 0; i < 3; i += 1) {
        blockedSpace.push(
          <div
            className="planner__blocked-space"
            style={blockedScheduleStyles(currentDate, i)}
            key={`user-blocked-${user.id}-${i}`}
          />
        );
      }
    }
  }

  return <>{blockedSpace}</>;
};

export default UserBlockedSpace;
