import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTeambookFilter } from "../../../../../stores/planner";
import UsersProfileImage from "../../../../default_components/UsersProfileImage";
import { sortDataBySurname } from "../../lib/sortDataBySurname";
import { WhiteTooltip } from "../../../../default_components/Tooltips";
import sortA from "../../../../default_images/AZsort_blue.svg";
import sortZ from "../../../../default_images/ZAsort_blue.svg";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";

const OverviewPage = ({ stats, sortOrder, setSortOrder }) => {
  const { t } = useTranslation();
  const [userRows, setUserRows] = useState([]);
  const { filterValues } = useTeambookFilter();

  useEffect(() => {
    let newRows =
      stats.users?.length > 0 &&
      sortDataBySurname(stats.users, sortOrder)
        ?.filter((user) => filterValues.length === 0 || userIsFiltered(user))
        .map((user) => (
          <div className="overview-table__data">
            <div className="overview-table__entity-name">
              <UsersProfileImage
                userImage={user?.avatars ? user.avatars["100"] : null}
                size={24}
                user={user}
              />
              {user.name}
            </div>
            <div className="overview-table__entity-capacity">
              {user.capacity}
            </div>
            <div className="overview-table__entity-billable">
              {user.billable}
            </div>
            <div className="overview-table__entity-tentative">
              {user.tentative}
            </div>
            <div className="overview-table__entity-non-billable">
              {user.non_billable}
            </div>
            <div className="overview-table__entity-billable-tentative">
              {user.non_billable_tentative}
            </div>
            <div className="overview-table__entity-time-off">
              {user.time_off}
            </div>
            <div className="overview-table__entity-time-off-tentative">
              {user.time_off_tentative}
            </div>
            <div className="overview-table__entity-availability">
              {user.availability}
            </div>
          </div>
        ));

    // if (filterValues.length === 0) {
    //   newRows?.push(
    //     <div className="overview-table__data">
    //       <div
    //         style={{ justifyContent: "center", fontSize: 20 }}
    //         className="overview-table__entity-name"
    //       >
    //         {t("dashboard.totals")}
    //       </div>
    //       <div className="overview-table__entity-capacity">
    //         {stats?.users
    //           ?.map((user) => user.capacity)
    //           .reduce((ps, a) => ps + a, 0)}
    //       </div>
    //       <div className="overview-table__entity-billable">
    //         {stats?.users
    //           ?.map((user) => user.billable)
    //           .reduce((ps, a) => ps + a, 0)}
    //       </div>
    //       <div className="overview-table__entity-tentative">
    //         {stats?.users
    //           ?.map((user) => user.tentative)
    //           .reduce((ps, a) => ps + a, 0)}
    //       </div>
    //       <div className="overview-table__entity-non-billable">
    //         {stats?.users
    //           ?.map((user) => user.non_billable)
    //           .reduce((ps, a) => ps + a, 0)}
    //       </div>
    //       <div className="overview-table__entity-billable-tentative">
    //         {stats?.users
    //           ?.map((user) => user.non_billable_tentative)
    //           .reduce((ps, a) => ps + a, 0)}
    //       </div>
    //       <div className="overview-table__entity-time-off">
    //         {stats?.users
    //           ?.map((user) => user.time_off)
    //           .reduce((ps, a) => ps + a, 0)}
    //       </div>
    //       <div className="overview-table__entity-time-off-tentative">
    //         {stats?.users
    //           ?.map((user) => user.time_off_tentative)
    //           .reduce((ps, a) => ps + a, 0)}
    //       </div>
    //       <div className="overview-table__entity-availability">
    //         {stats?.users
    //           ?.map((user) => user.availability)
    //           .reduce((ps, a) => ps + a, 0)}
    //       </div>
    //     </div>
    //   );
    // }

    setUserRows(newRows);
  }, [stats, filterValues, sortOrder]);

  const userIsFiltered = (user) => {
    const filteredUsers = filterValues.filter(
      (value) => value.type === t("planning.filter_users")
    );

    if (filteredUsers.length > 0) {
      return filteredUsers.map((r) => r.id).includes(user.id);
    } else {
      return true;
    }
  };

  return (
    <div className="reporting__overview-page">
      <table className="overview-table" style={{ width: "100%" }}>
        <tr>
          <th
            rowSpan="2"
            style={{
              width: "15%",
              paddingLeft: 10,
              borderLeft: "1px solid var(--stroke)",
            }}
          >
            {sortOrder === "a" ? (
              <div
                className="reporting__sort-switch"
                onClick={() => setSortOrder("z")}
              >
                <TeambookIcon
                  color="alternative_default"
                  name={icons.SORT_A}
                  tooltipTitle={t("dashboard.a_z")}
                />
              </div>
            ) : (
              <div
                className="reporting__sort-switch"
                onClick={() => setSortOrder("a")}
              >
                <TeambookIcon
                  color="alternative_default"
                  name={icons.SORT_Z}
                  tooltipTitle={t("dashboard.z_a")}
                />
              </div>
            )}
          </th>
          <th rowspan="2" style={{ width: "10%", verticalAlign: "bottom" }}>
            <p style={{ marginBottom: 4 }}>
              {t("planning.select_by_capacity")}
            </p>
          </th>
          <th
            className="overview-table__header"
            colspan="2"
            style={{ width: "40%", height: 44 }}
          >
            <p>{t("header.projects")}</p>
          </th>
          <th
            className="overview-table__header"
            style={{ width: "20%", height: 44 }}
          >
            <p>{t("dashboard.time_offs")}</p>
          </th>
          <th rowSpan="2" style={{ width: "15%", verticalAlign: "bottom" }}>
            <p style={{ marginBottom: 4 }}>{t("dashboard.availability")}</p>
          </th>
        </tr>
        <tr>
          <td className="overview-table__subheader" style={{ width: "20%" }}>
            <div className="overview-table__subheader-wrapper">
              <p>{t("dashboard.billable")}</p>
              <p>{t("planning.tentative")}</p>
            </div>
          </td>
          <td className="overview-table__subheader" style={{ width: "20%" }}>
            <div className="overview-table__subheader-wrapper">
              <p>{t("dashboard.non_billable")}</p>

              <p>{t("planning.tentative")}</p>
            </div>
          </td>
          <td className="overview-table__subheader" style={{ width: "20%" }}>
            <div className="overview-table__subheader-wrapper">
              <p>{t("dashboard.real")}</p>

              <p>{t("planning.tentative")}</p>
            </div>
          </td>
        </tr>
      </table>
      {userRows}
    </div>
  );
};

export default OverviewPage;
