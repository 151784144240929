import React from "react";
import NotEnoughData from "../../default_images/need_more_data.svg";
import TeambookWhiteButton from "../../default_components/TeambookWhiteButton";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const NoBookingsTeam = ({ addUser }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="no-users-team">
      <img src={NotEnoughData} alt={"No Bookings"} />

      <p>{t("planning.no_bookings")}</p>

      <p>{t("planning.create_on_planner")}</p>

      <TeambookWhiteButton
        text={t("planning.go_planner")}
        onClick={() => {
          navigate(`/planners`, { replace: true });
        }}
      />
    </div>
  );
};

export default NoBookingsTeam;
