import React from "react";
import UsersProfileImage from "../../../default_components/UsersProfileImage";
import ChangeUserPhoto from "../ChangeUserPhoto";
import ShowFields from "./ShowFields";
import EditOrCreateFields from "./EditOrCreateFields";
import GeneralTabInfo from "./GeneralTabInfo";
import ScheduleTabInfo from "./ScheduleTabInfo";
import { useTranslation } from "react-i18next";
import TeambookNotificationComponent from "../../../default_components/TeambookNotificationComponent";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const UsersData = ({
  viewType,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  billable,
  setBillable,
  role,
  setRole,
  timeZone,
  setTimeZone,
  tagName,
  setTagName,
  userTeams,
  setUserTeams,
  closeErrorMessage,
  errorField,
  errorMessage,
  teams,
  tags,
  roleOptions,
  userBillability,
  user,
  getAllUsers,
  users,
  schedule,
  setSchedule,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  tab,
  setTab,
  notificationSetting,
  setNotificationSetting,
  holidays,
  selectedHoliday,
  setSelectedHoliday,
  notes,
  setNotes,
}) => {
  const { t } = useTranslation();
  const [account] = useAccountStore((state) => [state.account], shallow);

  const userField = (name, value, setValue, type, id = "none", options = [], defineDates, setDefineDate) => {
    if (viewType === "show") {
      return ShowFields(name, value, setValue, type, user, userTeams, account);
    } else {
      return EditOrCreateFields(
        name,
        value,
        setValue,
        type,
        id,
        options,
        tags,
        teams,
        errorField,
        errorMessage,
        closeErrorMessage,
        account,
        startDate,
        t,
        defineDates,
        setDefineDate
      );
    }
  };

  const avatarComponent = () => {
    if (viewType === "show") {
      return <UsersProfileImage size={100} user={user} userImage={user.avatars["100"]} className="user-form__avatar" />;
    } else if (viewType === "edit") {
      return <ChangeUserPhoto className="user-form__change-photo" user={user} getAllUsers={getAllUsers} />;
    } else if (viewType === "create") {
      return <UsersProfileImage className="user-form__create-bg" size={100} firstName={"+"} />;
    }
  };

  return (
    <div style={{ width: "100%" }}>
      <div className="flex users-data__tabs">
        <div className={`users-data__tab ${tab === "general" && "active"}`} onClick={() => setTab("general")}>
          <TeambookIcon name={icons.USER} color={tab === "general" ? "alternative_default" : "default"} />
          <p>{t("users.general_info")}</p>
        </div>

        <div className={`users-data__tab ${tab === "schedule" && "active"}`} onClick={() => setTab("schedule")}>
          <TeambookIcon name={icons.CALENDAR} color={tab === "schedule" ? "alternative_default" : "default"} />
          <p>{t("users.schedule")}</p>
        </div>

        <div
          className={`users-data__tab ${tab === "notifications" && "active"}`}
          onClick={() => setTab("notifications")}
        >
          <TeambookIcon name={icons.NOTIFICATION} color={tab === "notifications" ? "alternative_default" : "default"} />
          <p>{t("profile.notifications.name")}</p>
        </div>
      </div>

      {tab === "general" && (
        <GeneralTabInfo
          userField={userField}
          viewType={viewType}
          firstName={firstName}
          avatarComponent={avatarComponent}
          userTeams={userTeams}
          setUserTeams={setUserTeams}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
          billable={billable}
          setBillable={setBillable}
          role={role}
          setRole={setRole}
          timeZone={timeZone}
          setTimeZone={setTimeZone}
          tagName={tagName}
          setTagName={setTagName}
          closeErrorMessage={closeErrorMessage}
          errorField={errorField}
          errorMessage={errorMessage}
          roleOptions={roleOptions}
          userBillability={userBillability}
          user={user}
          users={users}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          notes={notes}
          setNotes={setNotes}
        />
      )}

      {tab === "schedule" && (
        <ScheduleTabInfo
          schedule={schedule}
          setSchedule={setSchedule}
          kind={viewType}
          accountSchedule={account.schedule}
          holidays={holidays}
          selectedHoliday={selectedHoliday}
          setSelectedHoliday={setSelectedHoliday}
        />
      )}

      {tab === "notifications" && (
        <div className="users__notifications">
          <TeambookNotificationComponent
            notificationSetting={notificationSetting}
            setNotificationSetting={setNotificationSetting}
            blockEditing={viewType === "show"}
          />
        </div>
      )}
    </div>
  );
};

export default UsersData;
