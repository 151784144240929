export const icons = {
  CALENDAR: "calendar",
  PENCIL: "pencil",
  CROSS: "cross",
  NEW_CROSS: "new_cross",
  TRASH: "trash",
  USERS: "users",
  USER: "user",
  TAGS: "tags",
  PROJECT: "project",
  PROJECTS: "projects",
  CLIENTS: "clients",
  TASKS: "tasks",
  EYE_OPENED: "eye_opened",
  EYE_CLOSED: "eye_closed",
  LOGIN_BG: "login_bg",
  REGISTER_BG: "register_bg",
  REGISTER_POPUP_BG: "register_popup_bg",
  TB_LOGO: "tb_logo",
  SMALL_TB_LOGO: "small_tb_logo",
  RADIO_CHECKED: "radio_checked",
  RADIO_UNCHECKED: "radio_unchecked",
  CHECKBOX_CHECKED: "checkbox_checked",
  CHECKBOX_UNCHECKED: "checkbox_unchecked",
  ADVANCED: "advanced",
  CHECK: "check",
  CHECK_M: "check_m", //medium size
  WARNING: "warning",
  ARROW_DOWN: "arrow_down",
  ARROW_UP: "arrow_up",
  ADD: "add",
  CIRCLE_ADD: "circle_add",
  TODAY: "today",
  QUESTION: "question",
  QUESTION_SYMBOL: "question_symbol",
  ONBOARDING: "onboarding",
  KNOWLEDGE_BASE: "knowledge_base",
  IDEA: "idea",
  PROFILE: "profile",
  CONTACT_US: "contact_us",
  LOG_OUT: "log_out",
  MOON: "moon",
  PLACE: "place",
  PLACE_NEW: "place_new",
  ROADMAP: "roadmap",
  WHATS_NEW: "whats_new",
  WEEK: "week",
  FILTER: "filter",
  ADVANCED_FILTER: "advanced_filter",
  UPLOAD: "upload",
  SHARE: "share",
  EXPORT_ARROW: "export_arrow",
  LONG_ARROW: "long_arrow",
  NOTIFICATION: "notification",
  ADD_USER: "add_user",
  ADD_PROJECT: "add_project",
  REINVITE: "reinvite",
  SORT_A: "sort_a",
  SORT_Z: "sort_z",
  COPY: "copy",
  COPY_BOLD: "copy_bold",
  DELTA: "delta",
  PRINT: "print",
  REVERT: "revert",
  INFINITY: "infinity",
  REMOVE_USER: "remove_user",
  AT_WORK: "at_work",
  AT_WORK_NEW: "at_work_new",
  HOME: "home",
  HOME_NEW: "home_new",
  ON_GOING: "on_going",
  SPREAD: "spread",
  HOURS: "hours",
  DIAGRAM: "diagram",
  GEAR: "gear",
  SYNC: "sync",
  AZURE: "azure",
  NOTEBOOK: "notebook",
  INFO: "info",
  WARNING_TRIANGLE: "warning_triangle",
  ACTUALS_BLOCKED: "actuals_blocked",
  FILLED_CALENDAR: "filled_calendar",
  CHARTS: "charts",
  HELP_ICON: "help_icon",
  NEW_HELP_ICON: "new_help_icon",
  BOLD: "bold",
  UNDERLINE: "underline",
  ITALIC: "italic",
  TEAM_USERS: "team_users",
  DOUBLE_ARROWS: "double_arrows",
  REPORTING: "reporting",
  DOLLAR: "dollar_sign",
  PLAY_BUTTON: "play_button",
  TEAMBOOK_API_LOGO: "teambook_api_logo",
  SLACK_INTEGRATION_ICON: "slack_integration_icon",
  HUBSPOT_INTEGRATION_ICON: "hubspot_integration_icon",
  ZAPIER_INTEGRATION_ICON: "zapier_integration_icon",
  QB_INTEGRATION_ICON: "qb_integration_icon",
  PERSONIO_INTEGRATION_ICON: "personio_integration_icon",
  CHECK_CIRCLE: "check_circle",
  GOOGLE: "google",
  LEAVE: "leave",
  SMALL_ARROW_LEFT: "small_arrow_left",
  TAG: "tag",
  ADVANCED_SETTINGS: "advanced_settings",
  BLUE_PROJECTS: "blue_projects",
  DARKBLUE_CHECK: "darkblue_check",
  LOGGING_TIME: "logging_time",
  APPROVING_TIME: "approve_time",
  DASHBOARD: "dashboard",
  SINGLE_USER: "single_user",
  PROJECT_PLUS: "project_plus",
  USER_PLUS: "user_plus",
  PLUS: "plus",
  PROJECT_ADD: "project_add",
  OUTLOOK: "outlook",
  LINK: "link",
};
