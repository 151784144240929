import React from "react";
import NotEnoughData from "../../default_images/need_more_data.svg";
import { useTranslation } from "react-i18next";

const NoBookingsFiltered = () => {
  const { t } = useTranslation();

  return (
    <div className="no-bookings-filtered">
      <img src={NotEnoughData} alt={"No Bookings"} />

      <p>{t("dashboard.empty_export_data")}</p>
    </div>
  );
};

export default NoBookingsFiltered;
