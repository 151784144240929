import React from "react";
import TextField from "@mui/material/TextField";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "./Tooltips";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

const TeambookTextForm = ({
  fieldName,
  onChange,
  fieldValue,
  type,
  inputProps,
  width,
  rows,
  multiline,
  style,
  autoFocus,
  placeholder,
  id,
  whiteSpace,
  onFocus,
  onBlur,
  className,
  disabled,
  isPassword = false,
  onKeyDown,
  showPass = false,
  setShowPass = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <div className={`input-form-component text-form__container ${className || ""}`} style={{ width, ...style, position: "relative" }}>
      {fieldName && (
        <p className="input-form__form-text text-form__paragraph" style={{ whiteSpace: whiteSpace }}>
          {fieldName}
        </p>
      )}

      <TextField
        onKeyDown={onKeyDown}
        id={id}
        className="text-field tb__bg__white input-field"
        variant="outlined"
        type={isPassword ? (showPass ? "text" : "password") : type}
        onChange={(event) => onChange(event.target.value)}
        value={fieldValue}
        inputProps={inputProps}
        rows={rows}
        multiline={!!multiline}
        autoFocus={autoFocus}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      />

      {isPassword && (
        <WhiteTooltip title={t(`profile.password.${showPass ? "hide_password" : "show_password"}`)}>
          <TeambookIcon
            name={showPass ? icons.EYE_OPENED : icons.EYE_CLOSED}
            onClick={setShowPass}
            style={{
              position: "absolute",
              right: 10,
              bottom: 9,
              cursor: "pointer",
            }}
          />
        </WhiteTooltip>
      )}
    </div>
  );
};

export default TeambookTextForm;
