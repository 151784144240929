import React, { useEffect, useState } from "react";
import { notificationStore } from "../../stores/notificationStore";
import { shallow } from "zustand/shallow";

const InAppNotification = ({ closeNotification }) => {
  const [inAppNotification] = notificationStore((state) => [state.inAppNotification], shallow);
  const [showNotification, setShowNotification] = useState(false);

  useEffect(() => {
    if (inAppNotification) {
      setShowNotification(true);

      const hideTimeout = setTimeout(() => {
        setShowNotification(false);
        setTimeout(closeNotification, 500);
      }, 3500);

      return () => clearTimeout(hideTimeout);
    }
  }, [inAppNotification, closeNotification]);

  return (
    <div
      className={`mobile_hidden notification-container ${showNotification ? "show" : "hide"}`}
      onClick={closeNotification}
    >
      <p>{inAppNotification}</p>
    </div>
  );
};

export default InAppNotification;
