import React, { useEffect } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { useTranslation } from "react-i18next";
import { IntercomProvider } from "react-use-intercom";
import InAppNotification from "./components/default_components/InAppNotification";
import moment from "moment";
import Middleware from "./components/default_components/Middleware";
import TagManager from "react-gtm-module";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import TeambookRoutes from "./routes/TeambookRoutes";
import ThemeProvider from "./providers/ThemeProvider";
import lsKeys from "./components/default_values/defaultKeys";
import { StagingSettings } from "./components/default_components/StagingSettings";
import ErrorBoundary from "./components/default_components/ErrorBoundary";
import { ThemeProvider as MuiThemeProvider, createTheme } from "@mui/material/styles";
import { useProfileStore } from "./stores/profileStore";
import { useAccountStore } from "./stores/accountStore";
import { notificationStore } from "./stores/notificationStore";
import { shallow } from "zustand/shallow";

const theme = createTheme({
  palette: {
    mode: localStorage.getItem(lsKeys.THEME) === "dark" ? "dark" : "light",
  },
});

const App = () => {
  const [inAppNotification, setInAppNotification] = notificationStore(
    (state) => [state.inAppNotification, state.setInAppNotification],
    shallow
  );

  const { i18n, t } = useTranslation();
  const role = localStorage.getItem(lsKeys.ROLE);
  const [fetchProfile] = useProfileStore((state) => [state.fetchProfile]);
  const [fetchAccount] = useAccountStore((state) => [state.fetchAccount]);

  useEffect(() => {
    if (localStorage.getItem("tb-lang")) {
      i18n.changeLanguage(localStorage.getItem("tb-lang") || "en");
      moment.locale(localStorage.getItem("tb-lang") || "en");
    }

    startGoogleTagManager();
  }, []);

  const startGoogleTagManager = () => {
    if (process.env.REACT_APP_ENV === "production") {
      const tagManagerArgs = {
        gtmId: "GTM-P3FFJ6X",
      };

      TagManager.initialize(tagManagerArgs);
    }
  };

  useEffect(() => {
    if (localStorage.getItem(lsKeys.AUTH_TOKEN)) {
      fetchProfile();
      fetchAccount();
    }
  }, []);

  return (
    <IntercomProvider appId={"pg3s2mmq"}>
      <Middleware />

      <MuiThemeProvider theme={theme}>
        <ThemeProvider>
          <ErrorBoundary t={t}>
            <div className="App">
              <DndProvider backend={HTML5Backend}>
                <LocalizationProvider dateAdapter={AdapterLuxon}>
                  <>
                    <TeambookRoutes role={role} />
                    {inAppNotification !== "" && (
                      <InAppNotification text={inAppNotification} closeNotification={() => setInAppNotification("")} />
                    )}

                    {process.env.REACT_APP_ENV === "staging" &&
                      localStorage.getItem(lsKeys.STAGING_SETTINGS_ENABLED) === "true" && <StagingSettings />}
                  </>
                </LocalizationProvider>
              </DndProvider>
            </div>
          </ErrorBoundary>
        </ThemeProvider>
      </MuiThemeProvider>
    </IntercomProvider>
  );
};

export default App;
