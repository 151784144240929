import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../dashboard.scss";
import FormControl from "@mui/material/FormControl";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import DownloadReportingWindow from "./DownloadReportingWindow";
import gearImg from "../../default_images/gear_blue.svg";
import TeambookFilterComponent from "../../default_components/teambook_filter/TeambookFilterComponent";
import lsKeys from "../../default_values/defaultKeys";
import { TeambookReactSelect } from "../../default_components/TeambookReactSelect";
import { TeambookGroupDropdown } from "../../default_components/TeambookDropdown";
import BlueArrowDown from "../../default_images/darkblue_arrow_down.svg";
import { ReactComponent as ProjectIcon } from "../../default_images/planner_projectsFilter.svg";
import { ReactComponent as UsersIcon } from "../../default_images/user_icon_black.svg";
import { ReactComponent as TasksIcon } from "../../default_images/tasks.svg";
import { ReactComponent as ReportingIcon } from "../../default_images/reporting_black.svg";
import { icons } from "../../default_images/IconsList";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import TeambookDatePicker from "../../default_components/teambook_date_selector/DatePicker";
import { useDashboardStore } from "../../../stores/dashboard";
import { shallow } from "zustand/shallow";
import { AnalyticsSelect } from "../sub_components/AnalyticsSelect";
import { useAccountStore } from "../../../stores/accountStore";

const ReportingControlBar = ({
  from,
  setFrom,
  selectedTeams,
  splitBy,
  setSplitBy,
  type,
  activeUsers,
  clients,
  activeProjects,
  tags,
  teams,
  tasks,
  page,
  selectedViewType,
  setSelectedViewType,
  setReportingPage,
  reporting_page,
  slaveDataType,
  range,
  setRange,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [downloadWindowOpen, setDownloadWindowOpen] = useState(false);
  const [setDashboardType] = useDashboardStore((state) => [state.setDashboardType], shallow);

  const [account] = useAccountStore((state) => [state.account], shallow);

  const reportingLinks = [
    {
      group_value: "default",
      group: t("header.planning"),
      elements: [
        {
          name: t("dashboard.by_projects"),
          param: "project",
          icon: icons.PROJECTS,
        },
        {
          name: t("dashboard.by_users"),
          param: "user",
          icon: icons.USERS,
        },
        {
          name: t("dashboard.overview"),
          param: "overview",
          icon: icons.REPORTING,
        },
      ],
    },
    {
      group_value: "capacity",
      group: t("dashboard.capacity"),
      elements: [
        {
          name: t("dashboard.by_users"),
          param: "capacity_user",
          icon: icons.USERS,
        },
        {
          name: t("dashboard.by_projects"),
          param: "capacity_project",
          icon: icons.PROJECTS,
        },
      ],
    },
    {
      group_value: "actuals",
      group: t("header.actuals"),
      elements: [
        {
          name: t("dashboard.by_projects"),
          param: "actuals_project",
          icon: icons.PROJECTS,
        },
        {
          name: t("dashboard.by_users"),
          param: "actuals_user",
          icon: icons.USERS,
        },
      ],
    },
    {
      group_value: "comparison",
      group: t("dashboard.compare"),
      elements: [
        {
          name: t("dashboard.by_projects"),
          param: "comparison_project",
          icon: icons.PROJECTS,
        },
        {
          name: t("dashboard.by_users"),
          param: "comparison_user",
          icon: icons.USERS,
        },
        {
          name: t("dashboard.by_tasks"),
          param: "comparison_task",
          icon: icons.TASKS, //change
        },
      ],
    },
  ];

  if ([9, 591, 755, 272, 1130].includes(account?.id) || process.env.REACT_APP_ENV === "staging") {
    reportingLinks[0].elements.push({
      name: t("dashboard.satriun_overview"),
      param: "sat_overview",
      icon: icons.PROJECTS,
    });

    reportingLinks[2].elements.push({
      name: t("dashboard.satriun_overview"),
      param: "sat_overview",
      icon: icons.PROJECTS,
    });
  }

  const moveToDashboard = () => {
    let dashboardType;

    switch (type) {
      case "capacity":
        localStorage.setItem(lsKeys.DASHBOARD_RANGE, 3);
        dashboardType = "capacity";
        break;
      case "comparison":
        localStorage.setItem(lsKeys.DASHBOARD_RANGE, 1);
        dashboardType = "compare";
        break;
      default:
        localStorage.setItem(lsKeys.DASHBOARD_RANGE, 1);
        dashboardType = type;
        break;
    }

    setDashboardType(dashboardType);

    navigate("/dashboard");
  };

  const viewOptions = [
    {
      value: 6,
      label: t("planning.range.6_months"),
    },
    {
      value: 12,
      label: t("planning.range.year"),
    },
    {
      value: 24,
      label: t("planning.range.2_year"),
    },
  ];

  const filterPage = {
    user: "reportingUsers",
    project: "reportingProjects",
    capacity_project: "reportingCapacityProjects",
    capacity_user: "reportingCapacityUsers",
    capacity_tag: "reportingCapacityTags",
    comparison_project: "reportingComparisonProject",
    comparison_user: "reportingComparisonUser",
    comparison_task: "reportingComparisonTasks",
    overview: "reportingOverview",
    sat_overview: "reportingSatOverview",
    actuals_user: "reportingActualsUsers",
    actuals_project: "reportingActualsProjects",
  };

  return (
    <div className="reporting__control-bar control-bar flex">
      <TeambookDatePicker
        from={from.start}
        to={from.end}
        formatStart="LLL yyyy"
        formatEnd="d LLL yyyy"
        setRange={setRange}
        range={range}
        setFrom={setFrom}
        style={{ height: 42 }}
        type={type === "capacity" ? "default" : "range"}
        highlight={type === "capacity" ? "today" : "selected"}
        selectOnArrowClick={false}
        onDayClick={(date) => {
          switch (range) {
            case 1:
              setFrom((pr) => ({
                start: date.startOf("month"),
                end: date.endOf("month"),
              }));
              return;
            case 2:
              setFrom((pr) => ({
                start: date.startOf("month"),
                end: date.plus({ month: 2 }).endOf("month"),
              }));
              return;

            default:
          }
        }}
      />

      {type === "capacity" && (
        <TeambookReactSelect
          defaultValue={viewOptions.find((o) => o.value === selectedViewType)}
          value={viewOptions.find((o) => o.value === selectedViewType)}
          options={viewOptions}
          onChange={(activity) => {
            localStorage.setItem(lsKeys.LAST_CAPACITY_ZOOM, activity.value);
            setSelectedViewType(activity.value);
          }}
          height={42}
          width={170}
          maxMenuHeight={120}
          dropdownArrow={BlueArrowDown}
          styles={{
            singleValue: (base) => ({
              ...base,
              color: "var(--text-2) !important",
            }),
          }}
        />
      )}

      <div className="reporting-control__filter-text-field">
        <TeambookFilterComponent
          users={activeUsers}
          clients={clients}
          projects={activeProjects}
          tags={tags}
          tasks={tasks}
          teams={teams}
          filterPage={filterPage[splitBy]}
        />
      </div>

      <button className="reporting__control-bar__button" onClick={() => setSettingsOpen(true)}>
        <img src={gearImg} />
      </button>

      <FormControl style={{ marginLeft: "auto" }}>
        <TeambookGroupDropdown
          options={reportingLinks}
          value={type}
          pageSplit={splitBy}
          onSelect={setSplitBy}
          setReportingPage={setReportingPage}
          reportingPage={reporting_page}
        />
      </FormControl>

      <div style={{ display: "flex", gap: 20 }}>
        <div
          title={t("download")}
          style={{ height: 42 }}
          className="dashboard__tb-button"
          onClick={() => setDownloadWindowOpen(true)}
        >
          <TeambookIcon
            name={icons.EXPORT_ARROW}
            style={{ width: "24px" }}
            tooltipTitle={t("download")}
            color="alternative_default"
          />
        </div>

        <AnalyticsSelect currentPage="reporting" moveToDashboard={moveToDashboard} />
      </div>

      {downloadWindowOpen && (
        <DownloadReportingWindow
          splitBy={splitBy}
          setDownloadWindowOpen={setDownloadWindowOpen}
          date={from.start}
          teams={selectedTeams}
          type={type}
          slaveDataType={slaveDataType}
        />
      )}
    </div>
  );
};

export default ReportingControlBar;
