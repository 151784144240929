import React, { useEffect, useState } from "react";
import "./header.scss";
import { NavLink, useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import UsersProfileImage from "../default_components/UsersProfileImage";
import MobileMenuComponent from "./MobileMenuComponent";
import { useTranslation } from "react-i18next";
import Api from "../../Api";
import HelpMenu from "./header_menu/HelpMenu";
import lsKeys from "../default_values/defaultKeys";
import WhatsNewModal from "./header_modals/WhatsNewModal";
import HeaderMenu from "./header_menu/HeaderMenu";
import FeedbackModal from "./header_modals/FeedbackModal";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";
import { DateTime } from "luxon";
import newYearIcon from "../default_images/christmas_icon.svg";
import { HeaderLink } from "./HeaderLink";
import { googleLogout } from "@react-oauth/google";
import { useProfileStore } from "../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../stores/accountStore";
import LeaveTeambookModal from "./header_modals/LeaveTeambookModal";

const Header = ({ page, setOnboardingOpened, didYouKnowRef, user }) => {
  const { t, i18n } = useTranslation();
  const role = localStorage.getItem("tb-role");
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState();
  const [helpMenuOpened, setHelpMenuOpened] = useState(false);
  const [helpAnchor, setHelpAnchor] = useState();
  const [pagesMenuOpened, setPagesMenuOpened] = useState(false);
  const [isFeedbackModalOpen, setIsFeedbackModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [whatsNewOpen, setWhatsNewOpen] = useState(false);
  const [lang, setLang] = useState(localStorage.getItem("tb-lang"));
  const [logOutModalOpen, setLogOutModalOpen] = useState(false);

  const location = useLocation();

  const [currentUser] = useProfileStore((state) => [state.profile], shallow);
  const [account] = useAccountStore((state) => [state.account], shallow);

  useEffect(() => {
    localStorage.setItem("tb-lang", lang);
    i18n.changeLanguage(lang || "en");
  }, [lang]);

  useEffect(() => {
    if (location.pathname.includes("/my-week/")) {
      localStorage.setItem(lsKeys.LAST_VISITED_PAGE, "/my-week");
    } else if (location.pathname.includes("/planners/")) {
      localStorage.setItem(lsKeys.LAST_VISITED_PAGE, "/planners");
    } else if (location.pathname.includes("/grouped_planners/")) {
      localStorage.setItem(lsKeys.LAST_VISITED_PAGE, "/grouped_planners");
    } else if (["login", "register"].includes(location.pathname)) {
    } else {
      localStorage.setItem(lsKeys.LAST_VISITED_PAGE, location.pathname);
    }
  }, [location]);

  const getActualsUrl = () =>
    ["admin", "planner"].includes(localStorage.getItem(lsKeys.ROLE)) ? "/actuals" : "/actuals/time_logging";

  const actionModule = [
    {
      url: `/${localStorage.getItem(lsKeys.LAST_PLANNER_TYPE) === "planners" ? "planners" : "grouped_planners"}`,
      text: t("header.planning"),
      icon: icons.FILLED_CALENDAR,
    },

    {
      url: `/capacity_planning`,
      text: t("header.capacity"),
      icon: icons.INFINITY,
    },

    {
      url: getActualsUrl(),
      text: t("header.actuals"),
      icon: icons.HOURS,
    },
  ];

  const analyticsModule = [
    {
      url: `/dashboard`,
      text: t("header.dashboard"),
      icon: icons.CHARTS,
    },
  ];

  const dataModule = [
    {
      url: `/users`,
      text: t("header.users"),
      icon: icons.USERS,
    },
    {
      url: `/projects`,
      text: t("header.projects"),
      icon: icons.PROJECTS,
    },
  ];

  const openWhatsNew = () => {
    setWhatsNewOpen(true);
  };

  const openUserMenu = (event) => {
    setAnchorEl(event.currentTarget);
    setUserMenuOpened(true);
  };

  const openHelpMenu = (event) => {
    setHelpAnchor(event.currentTarget);
    setHelpMenuOpened(true);
  };

  const closeMenu = () => {
    setUserMenuOpened(false);
  };

  const logoutFromTeambook = () => {
    if (accountIsFresh() && notLoggedOutYet()) {
      setLogOutModalOpen(true);
    } else {
      localStorage.removeItem("Auth-Token");
      localStorage.removeItem("tb-role");
      googleLogout();
      window.location.replace("/login");
    }
  };

  const accountIsFresh = () => {
    const dayDifference = DateTime.fromISO(account.created_at).diff(DateTime.now(), "days").toObject().days;

    return dayDifference > -3 && account.braintree_customer_id === null;
  };

  const notLoggedOutYet = () => {
    return localStorage.getItem(lsKeys.LOGGED_OUT) === null;
  };

  const changeLanguage = (lng) => {
    Api.Users.update(currentUser.id, {
      settings: {
        language: lng,
      },
    })
      .then((response) => analyzeUpdateResponse(response))
      .catch((error) => console.log(error));
  };

  const analyzeUpdateResponse = (response) => {
    setLang(response.data.language);
  };

  const pageLocation = () => {
    if (pagesMenuOpened) return "Menu";

    if (window.location.href.indexOf("planners") > -1) return t("header.planning");

    if (window.location.href.indexOf("dashboard") > -1) return t("header.dashboard");

    if (window.location.href.indexOf("users") > -1) return t("header.users");

    if (window.location.href.indexOf("projects") > -1) return t("header.projects");

    if (window.location.href.indexOf("profile") > -1) return t("header.profile");

    if (window.location.href.indexOf("my-week") > -1) return t("header.my_week");

    if (window.location.href.indexOf("settings") > -1) return t("header.account_settings");

    if (window.location.href.indexOf("actuals") > -1) return t("header.actuals");
  };

  const toggleMenu = () => {
    setPagesMenuOpened(!pagesMenuOpened);
  };

  const sendMessage = (message) => {
    setErrorMessage("");
    Api.Users.send_review(message)
      .then(() => {
        setIsFeedbackModalOpen(false);
      })
      .catch((e) => {
        setErrorMessage(e.response.data.error.message);
      });
  };

  const newYearTime = () => {
    return (
      (DateTime.now().month === 12 && DateTime.now().day > 10) ||
      (DateTime.now().month === 1 && DateTime.now().day < 10)
    );
  };

  return (
    <div className="header__container">
      <div className="col-3 header__left-side">
        <NavLink to="/planners">
          {newYearTime() ? (
            <img alt={"icon"} src={newYearIcon} className="mobile_hidden header-logo" style={{ height: "40px" }} />
          ) : (
            <TeambookIcon name={icons.SMALL_TB_LOGO} color="blue" className="mobile_hidden" />
          )}

          <TeambookIcon name={icons.SMALL_TB_LOGO} color="blue" className="mobile-logo desktop_hidden" />
        </NavLink>
      </div>

      <div className="col-6 header__center">
        <div className="header__items mobile_hidden">
          <div className="header__items-group">
            {actionModule.map((point) => (
              <HeaderLink to={point.url} icon={point.icon} text={point.text} />
            ))}
          </div>

          <div className="header__items-group">
            {["admin", "planner"].includes(role) &&
              analyticsModule.map((point) => <HeaderLink to={point.url} icon={point.icon} text={point.text} />)}
          </div>

          <div className="header__items-group">
            {["admin", "planner"].includes(role) &&
              dataModule.map((point) => <HeaderLink to={point.url} icon={point.icon} text={point.text} />)}
          </div>
        </div>

        <div className="mobile-menu__select desktop_hidden" onClick={toggleMenu}>
          <p>
            {pageLocation()}
            <KeyboardArrowDownIcon />
          </p>
        </div>
      </div>

      <div className="col-3 header__right-side">
        {currentUser?.first_name && (
          <div className="header__user-info-container">
            <div className="header__help-menu " onClick={openHelpMenu} style={{ cursor: "pointer" }}>
              <TeambookIcon
                name={icons.NEW_HELP_ICON}
                color="grouped_planner_add"
                className="header__help-icon "
                alt={"help-icon"}
              />
              <p>{t("header.help")}</p>
            </div>

            <HelpMenu
              openFeedbackModal={() => setIsFeedbackModalOpen(true)}
              helpAnchor={helpAnchor}
              helpMenuOpened={helpMenuOpened}
              setHelpMenuOpened={setHelpMenuOpened}
              setOnboardingOpened={setOnboardingOpened}
              page={page}
              didYouKnowRef={didYouKnowRef}
            />

            <div id={"openUserMenu"} className="header__user-info" onClick={openUserMenu}>
              <UsersProfileImage size={40} user={currentUser} userImage={currentUser.avatars["50"]} header={true} />
            </div>
          </div>
        )}

        <HeaderMenu
          anchorEl={anchorEl}
          userMenuOpened={userMenuOpened}
          closeMenu={closeMenu}
          openWhatsNew={openWhatsNew}
          changeLanguage={changeLanguage}
          role={role}
          lang={lang}
          logout={logoutFromTeambook}
        />
      </div>

      {isFeedbackModalOpen && (
        <FeedbackModal
          setIsFeedbackModalOpen={setIsFeedbackModalOpen}
          sendMessage={sendMessage}
          errorMessage={errorMessage}
        />
      )}

      {pagesMenuOpened && <MobileMenuComponent setPagesMenuOpened={setPagesMenuOpened} role={role} />}

      {whatsNewOpen && <WhatsNewModal closeWindow={() => setWhatsNewOpen(false)} />}

      <LeaveTeambookModal logOut={logoutFromTeambook} logOutModalOpen={logOutModalOpen} />
    </div>
  );
};

export default Header;
