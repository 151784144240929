import React, { useRef, useState } from "react";
import "./clients_dialog.scss";
import Api from "../../../../Api";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import Dialog from "@mui/material/Dialog";
import Client from "./Client";
import CreateClientForm from "./CreateClientForm";
import EditClientForm from "./EditClientForm";
import DeleteDialog from "../../../default_components/DeleteDialog";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { notificationStore } from "../../../../stores/notificationStore";
import { shallow } from "zustand/shallow";

export const IconWrapper = ({ ...props }) => {
  const title = !props.disable && props.t(props.toolTip);
  const className = `teambook-icon-frame ${props.disable && "disable"}`;
  const onClick = props.disable ? () => {} : props.onClick;
  const icon = props.icon;

  return (
    <WhiteTooltip title={title}>
      <div className={className} onClick={onClick}>
        <TeambookIcon name={icon} color="alternative_default" />
      </div>
    </WhiteTooltip>
  );
};

export const phoneRegex = /^[+0-9][0-9]*$/;

const ClientsDialog = ({ clients, clientsOpen, setClientsOpen, setAllClients, getAllProjects }) => {
  const { t } = useTranslation();
  const filterInputRef = useRef(null);
  const [setInAppNotification] = notificationStore((state) => [state.setInAppNotification], shallow);
  const [isOpenCreateClientForm, setIsOpenCreateClientForm] = useState(false);
  const [editClientId, setEditClientId] = useState(null);
  const [deletionClient, setDeletionClient] = useState(null);
  const [filterValue, setFilterValue] = useState("");

  const getClientsList = () =>
    [...clients]
      .sort((a, b) => (a.name.toUpperCase() > b.name.toUpperCase() ? 1 : -1))
      .filter(
        ({ name, email, phone_number }) =>
          name?.toUpperCase().includes(filterValue.toUpperCase()) ||
          email?.toUpperCase().includes(filterValue.toUpperCase()) ||
          phone_number?.toUpperCase().includes(filterValue.toUpperCase())
      );

  const getAllClients = () => {
    Api.Clients.all().then((response) => {
      setAllClients(response.data);
    });
  };

  const clickOnFilter = () => {
    filterInputRef.current?.focus();
  };

  const closeDialog = () => {
    setClientsOpen(false);
    setFilterValue("");
  };

  const openClientForm = () => {
    editClient(null);
    setIsOpenCreateClientForm(true);
  };

  const closeClientForm = () => {
    setIsOpenCreateClientForm(false);
  };

  const editClient = (id) => {
    closeClientForm();
    setEditClientId(id);
  };

  const openDeletingClient = (id, name) => {
    closeClientForm();
    editClient(null);
    setDeletionClient({ id, name });
  };

  const deleteClient = (id) => {
    Api.Clients.deactivate(id).then(() => {
      getAllClients();
    });
  };

  const copyClientCalendar = (reference_id) => {
    let SERVER_ADDRESS;

    switch (process.env.REACT_APP_ENV) {
      case "production":
        SERVER_ADDRESS = "https://web.teambookapp.com";
        break;
      case "staging":
        SERVER_ADDRESS = "https://web.teambooktest.com";
        break;
      default:
        SERVER_ADDRESS = "http://localhost:3000";
        break;
    }

    navigator.clipboard.writeText(`${SERVER_ADDRESS}/client_projection/${reference_id}`);

    setInAppNotification(t("projects.copied_client_calendar_url"));
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={clientsOpen}
        onClose={closeDialog}
        aria-labelledby="max-width-dialog-title"
        className="tb-default-dialog clients-modal"
      >
        <div className="clients-modal__header">
          <p>{t("projects.clients")}</p>
          <TeambookIcon
            id="createClient"
            name={icons.ADD}
            onClick={openClientForm}
            tooltipTitle={t("projects.new_client")}
          />

          <div className="clients-modal__filter-input">
            <TeambookIcon
              name={icons.FILTER}
              onClick={clickOnFilter}
              color="alternative_default"
              tooltipTitle={t("filter")}
            />

            <input
              ref={filterInputRef}
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
              type="text"
              placeholder={t("planning.enter_filter")}
            />
          </div>

          <TeambookIcon name={icons.CROSS} onClick={closeDialog} />
        </div>

        <div className="clients-modal__content">
          <div className="clients-modal__content-titles">
            <p>{t("projects.client_name")}</p>
            <p>
              {t("projects.client_email")} ({t("projects.optional")})
            </p>
            <p>
              {t("projects.client_phone")} ({t("projects.optional")})
            </p>
          </div>

          <div className="clients-modal__list">
            {isOpenCreateClientForm && (
              <CreateClientForm getAllClients={getAllClients} closeClientForm={closeClientForm} />
            )}

            {getClientsList().map((client) =>
              client.id === editClientId ? (
                <EditClientForm
                  client={client}
                  editClient={editClient}
                  getAllClients={getAllClients}
                  getAllProjects={getAllProjects}
                />
              ) : (
                <Client
                  client={client}
                  editClient={editClient}
                  deleteClient={openDeletingClient}
                  shareCalendar={copyClientCalendar}
                />
              )
            )}
          </div>
        </div>
      </Dialog>

      {deletionClient && (
        <DeleteDialog
          open={deletionClient}
          setOpen={setDeletionClient}
          deleteRequest={() => deleteClient(deletionClient.id)}
          contentText={
            <p>
              {t("projects.delete_client_confirmation_1")}
              <b>{deletionClient.name}</b>
              {t("projects.delete_client_confirmation_2")}
            </p>
          }
        />
      )}
    </>
  );
};

export default ClientsDialog;
