import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import ControlPanel from "../ControlPanel";
import "../administration.scss";
import axios from "axios";
import Api from "../../../Api";

export default function LocalesPage() {
  const adminToken = localStorage.getItem("tb-admin-token");

  const [translationEN, setTranslationEN] = useState("");
  const [translationFR, setTranslationFR] = useState("");
  const [translationDE, setTranslationDE] = useState("");
  const [translationES, setTranslationES] = useState("");
  const [translationIT, setTranslationIT] = useState("");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [requestIsOnTheWay, setRequestIsOnTheWay] = useState(false);

  useEffect(() => {
    fetchTranslations();
  }, []);

  const awsUrl = () => {
    if (process.env.REACT_APP_ENV === "production") {
      return "https://teambook-account-images-production.s3.amazonaws.com/i18n";
    } else {
      return "https://teambook-account-images-production.s3.amazonaws.com/i18n/development";
    }
  };

  const fetchTranslations = () => {
    axios.get(`${awsUrl()}/en.json?_=` + Date.now()).then((res) => {
      setTranslationEN(JSON.stringify(res.data, null, 4));
    });
    axios.get(`${awsUrl()}/fr.json?_=` + Date.now()).then((res) => {
      setTranslationFR(JSON.stringify(res.data, null, 4));
    });
    axios.get(`${awsUrl()}/de.json?_=` + Date.now()).then((res) => {
      setTranslationDE(JSON.stringify(res.data, null, 4));
    });
    axios.get(`${awsUrl()}/es.json?_=` + Date.now()).then((res) => {
      setTranslationES(JSON.stringify(res.data, null, 4));
    });
    axios.get(`${awsUrl()}/it.json?_=` + Date.now()).then((res) => {
      setTranslationIT(JSON.stringify(res.data, null, 4));
    });
  };

  const saveLanguageChanges = (language, newTranslation) => {
    setRequestIsOnTheWay(true);

    Api.Integrations.Locales.update(language, newTranslation, adminToken)
      .then((res) => {
        setRequestIsOnTheWay(false);
      })
      .catch((error) => {
        alert(error.response.data.error);
        setRequestIsOnTheWay(false);
      });
  };

  const handleScroll = (event) => {
    setScrollPosition(event.target.scrollTop);
  };

  return (
    <>
      <Link to="/administration">
        <TeambookBlueButton
          style={{
            zIndex: "1",
            position: "fixed",
            left: "5px",
            top: "5px",
          }}
          text={"To Administration"}
        />
      </Link>

      <div className="flex locales-component">
        <div className="language-column">
          <div className="flex language-header">
            <p>EN</p>

            <TeambookBlueButton
              text="Save"
              onClick={() => saveLanguageChanges("en", translationEN)}
              disabled={requestIsOnTheWay}
            />
          </div>

          <textarea
            onScroll={handleScroll}
            ref={(textarea) => {
              if (textarea) textarea.scrollTop = scrollPosition;
            }}
            value={translationEN}
            onChange={(e) => setTranslationEN(e.target.value)}
          />
        </div>

        <div className="language-column">
          <div className="flex language-header">
            <p>FR</p>

            <TeambookBlueButton
              text="Save"
              onClick={() => saveLanguageChanges("fr", translationFR)}
              disabled={requestIsOnTheWay}
            />
          </div>

          <textarea
            onScroll={handleScroll}
            ref={(textarea) => {
              if (textarea) textarea.scrollTop = scrollPosition;
            }}
            value={translationFR}
            onChange={(e) => setTranslationFR(e.target.value)}
          />
        </div>

        <div className="language-column">
          <div className="flex language-header">
            <p>DE</p>

            <TeambookBlueButton
              text="Save"
              onClick={() => saveLanguageChanges("de", translationDE)}
              disabled={requestIsOnTheWay}
            />
          </div>

          <textarea
            onScroll={handleScroll}
            ref={(textarea) => {
              if (textarea) textarea.scrollTop = scrollPosition;
            }}
            value={translationDE}
            onChange={(e) => setTranslationDE(e.target.value)}
          />
        </div>

        <div className="language-column">
          <div className="flex language-header">
            <p>ES</p>

            <TeambookBlueButton
              text="Save"
              onClick={() => saveLanguageChanges("es", translationES)}
              disabled={requestIsOnTheWay}
            />
          </div>

          <textarea
            onScroll={handleScroll}
            ref={(textarea) => {
              if (textarea) textarea.scrollTop = scrollPosition;
            }}
            value={translationES}
            onChange={(e) => setTranslationES(e.target.value)}
          />
        </div>

        <div className="language-column">
          <div className="flex language-header">
            <p>IT</p>

            <TeambookBlueButton
              text="Save"
              onClick={() => saveLanguageChanges("it", translationIT)}
              disabled={requestIsOnTheWay}
            />
          </div>

          <textarea
            onScroll={handleScroll}
            ref={(textarea) => {
              if (textarea) textarea.scrollTop = scrollPosition;
            }}
            value={translationIT}
            onChange={(e) => setTranslationIT(e.target.value)}
          />
        </div>
      </div>

      <ControlPanel />
    </>
  );
}
