import React from "react";
import "../../users.scss";
import { useUsersVisibilityColumnsStore } from "../../../../stores/users";
import { useTranslation } from "react-i18next";
import UsersProfileImage from "../../../default_components/UsersProfileImage";
import ReinviteIcon from "../../../default_images/reinvite.svg";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import { TeambookCheckbox } from "../../../default_components/TeambookCheckbox";
import ItemsRow from "./ItemsRow/ItemsRow";

export const sortItemsAlphabetically = (items) => {
  return [...items].sort((a, b) => a.name.localeCompare(b.name));
};

const UserRow = ({ user, showUser, teams, selectUser, isSelected }) => {
  const { t } = useTranslation();
  const { columnsVisibility } = useUsersVisibilityColumnsStore();

  const userRole = () => {
    switch (user.role) {
      case "admin":
        return t("users.admin");
      case "regular":
        return t("users.regular");
      case "contractor":
        return t("users.contractor");
      case "self_planner":
        return t("users.self_planner");
      case "planner":
        return t("users.planner");
    }
  };

  return (
    <div className="user-list__block">
      <div
        onClick={() => showUser(user)}
        className={`users-page__user-element ${isSelected && "is-selected"}`}
      >
        <div
          onClick={(e) => e.stopPropagation()}
          className="user-block__select-checkbox"
        >
          <TeambookCheckbox
            id="selectUser"
            type="checkbox"
            checked={isSelected}
            onChange={(e) => {
              selectUser(user);
            }}
          />
        </div>

        <div className="users-block__avatar-container column-width-3">
          {user.invited && (
            <WhiteTooltip title={t("users.invited_cap")}>
              <div className="users-block__invited-indicator">
                <img src={ReinviteIcon}></img>
              </div>
            </WhiteTooltip>
          )}
          <UsersProfileImage
            size={40}
            user={user}
            userImage={user.avatars["40"]}
          />

          <p className="users-block__name">{user.name}</p>
        </div>

        {columnsVisibility["email"] && (
          <p className="users-block__email mobile_hidden column-width-2">
            {user.email || "-"}
          </p>
        )}

        {columnsVisibility["role"] && (
          <p className="users-block__role column-width-1">{userRole()}</p>
        )}

        {columnsVisibility["billable"] && (
          <p className="users-block__billability mobile_hidden column-width-1">
            {user.billable ? t("users.billable") : t("users.non_billable")}
          </p>
        )}

        {columnsVisibility["teams"] && (
          <ItemsRow
            items={sortItemsAlphabetically(teams.filter((team) => team))}
            isTeams
          />
        )}

        {columnsVisibility["tags"] && (
          <ItemsRow items={sortItemsAlphabetically(user.tags)} />
        )}

        {columnsVisibility["start_date"] && (
          <p className="users-block__email mobile_hidden column-width-1">
            {user.start_date || "-"}
          </p>
        )}

        {columnsVisibility["end_date"] && (
          <p className="users-block__email mobile_hidden column-width-1">
            {user.end_date || "-"}
          </p>
        )}
      </div>
    </div>
  );
};

export default UserRow;
