import React, { useEffect, useState } from "react";
import "./clients_dialog.scss";
import Api from "../../../../Api";
import { useTranslation } from "react-i18next";
import { IconWrapper, phoneRegex } from "./ClientsDialog";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { notificationStore } from "../../../../stores/notificationStore";
import { shallow } from "zustand/shallow";

const CreateClientForm = ({ getAllClients, closeClientForm }) => {
  const { t } = useTranslation();
  const [setInAppNotification] = notificationStore((state) => [state.setInAppNotification], shallow);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [wrongComponent, setWrongComponent] = useState(null);

  useEffect(() => {
    setErrorMessage(null);
    setWrongComponent(null);
  }, [name, email, phone_number]);

  const submitNewClient = () => {
    Api.Clients.create({
      active: true,
      name,
      email,
      phone_number,
      notes: "",
    })
      .then(() => {
        getAllClients();
        closeClientForm();
        setErrorMessage(null);
        setWrongComponent(null);
        setInAppNotification(t("notifications.projects.clients.create"));
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error.message);
        setWrongComponent(error.response.data.error.field);
      });
  };

  const phoneOnChange = (event) => {
    const text = event.target.value;

    if (text.length > 0) {
      phoneRegex.test(text) && setPhone_number(text);
    } else {
      setPhone_number(text);
    }
  };

  return (
    <>
      <div className={`clients-modal__row wrong-${wrongComponent}`}>
        <input type="text" value={name} onChange={(e) => setName(e.target.value)} placeholder="John Smith" />
        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="your@email.com" />
        <input type="text" value={phone_number} onChange={phoneOnChange} placeholder="+1 234 567 890" />

        <div className="clients-modal__icons-container">
          <IconWrapper t={t} onClick={submitNewClient} toolTip="save" icon={icons.CHECK} disable={errorMessage} />
          <IconWrapper t={t} onClick={closeClientForm} toolTip="cancel" icon={icons.CROSS} />
        </div>
      </div>

      {errorMessage && (
        <div className={`clients-modal__error-message wrong-${wrongComponent}`}>
          <TeambookIcon name={icons.WARNING} color="red" />
          <p>{errorMessage}</p>
        </div>
      )}
    </>
  );
};

export default CreateClientForm;
