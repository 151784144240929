import { useTranslation } from "react-i18next";
import lsKeys from "../../default_values/defaultKeys";
import TeambookDateSelector from "./TeambookDateSelector";
import { useRef, useState } from "react";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";

const TeambookDatePicker = ({
  from,
  to,
  classes = {
    datePickerClassName: "",
    containerClassName: "tb-date-picker",
    arrowsBlockClassName: "",
  },
  formatStart,
  formatEnd,
  style,
  setRange = () => {},
  range,
  setFrom,
  type = "range",
  onArrowClicks,
  onDayClick = () => {},
  height = 40,
  closeOnSelect = false,
  highlight = "selected",
  selectOnArrowClick = true,
  disabledRanges = ["6_months", "2_years", "1_year"],
  variant = "default",
  menuPosition,
  theme,
}) => {
  const { t } = useTranslation();
  const datePickerRef = useRef(null);
  const [isDatePickerOpened, setIsDatePickerOpened] = useState(false);
  const isRangePicker = type === "range";

  const closeDatePicker = () => setIsDatePickerOpened(false);

  return (
    <>
      <div
        style={{
          ...style,
          height: height,
          padding: "0px 15px",
        }}
        className={`${classes.containerClassName} ${variant === "default" ? "default_picker" : "inline_picker"}`}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 15 }} className={classes.arrowsBlockClassName}>
          {variant === "default" && (
            <TeambookIcon
              alt="arrow-left"
              className="arrow-block__arrow-left"
              name={icons.SMALL_ARROW_LEFT}
              color="darkblue"
              theme={theme}
              onClick={() => {
                if (onArrowClicks) {
                  onArrowClicks.left();
                } else {
                  setFrom((pr) => ({
                    start: pr.start.minus({ month: 1 }).startOf("month"),
                    end: pr.end.minus({ month: 1 }).endOf("month"),
                  }));
                }
              }}
            />
          )}
          <div
            ref={datePickerRef}
            onClick={(e) => {
              setIsDatePickerOpened(true);
              datePickerRef.current.left = e.currentTarget.offsetLeft;
            }}
            className="tb-date-picker__date-show"
          >
            <p>
              {+range === 1 ? (
                <div className="tb-date-picker__start-container">
                  {from.setLocale(localStorage.getItem(lsKeys.LANGUAGE)).toFormat(formatStart || "MMM yyyy")}
                </div>
              ) : (
                <>
                  {from
                    .setLocale(localStorage.getItem(lsKeys.LANGUAGE))
                    .toFormat(formatStart + (from.year === to.year ? "" : " yyyy"))}
                  {"  -  "}
                  {to.setLocale(localStorage.getItem(lsKeys.LANGUAGE)).toFormat(formatEnd)}
                </>
              )}
            </p>
          </div>

          {variant === "default" && (
            <TeambookIcon
              className="arrow-block__arrow-right"
              alt="arrow-right"
              name={icons.SMALL_ARROW_LEFT}
              color="darkblue"
              theme={theme}
              style={{ rotate: "180deg" }}
              onClick={() => {
                if (onArrowClicks) {
                  onArrowClicks.right();
                } else {
                  setFrom((pr) => ({
                    start: pr.start.plus({ month: 1 }).startOf("month"),
                    end: pr.end.plus({ month: 1 }).endOf("month"),
                  }));
                }
              }}
            />
          )}

          {isDatePickerOpened && (
            <TeambookDateSelector
              datePickerRef={datePickerRef}
              setRange={setRange}
              range={range}
              setSelectedDates={setFrom}
              selectedDates={{ start: from, end: to || from }}
              closeDatePicker={closeDatePicker}
              isRangePicker={isRangePicker}
              onDayClick={onDayClick}
              closeOnSelect={closeOnSelect}
              highlight={highlight}
              selectOnArrowClick={selectOnArrowClick}
              disabledRanges={disabledRanges}
              variant={variant}
              menuPosition={menuPosition}
              theme={theme}
            />
          )}
        </div>

        {variant === "inline" && (
          <TeambookIcon
            theme={theme}
            onClick={(e) => setIsDatePickerOpened(true)}
            className="tb-default-picker__inline-calendar-icon"
            name={icons.FILLED_CALENDAR}
          />
        )}
      </div>
    </>
  );
};

export default TeambookDatePicker;
