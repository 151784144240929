import React, { useContext } from "react";
import { getNumberOfDays, ToDateString, getCellWidthForZoom, OffsetDays } from "../../../../lib/DateUtils";
import {
  GROUPED_PLANNER_USER_WIDTH,
  getPlannerOffsetX,
  getPlannerOffsetY,
  usersMaxScheduleTime,
} from "../../../../lib/PlannerUtils";
import {
  useBookingsCreatingStore,
  useDateStore,
  useSelectedProjectStore,
  useZoomStore,
} from "../../../../stores/planner";
import { PlannerContext } from "../../context/PlannerContext";
import { useProfileStore } from "../../../../stores/profileStore";
import { shallow } from "zustand/shallow";

const formatMapping = {
  0: 5,
  1: 10,
  2: 15,
};

const GroupedCreationBooking = ({
  userIndex,
  date,
  filteredUsers,
  mouseMoved,
  weekendsHidden,
  bookingFormat,
  groupClients,
}) => {
  const userRole = localStorage.getItem("tb-role") || "regular";
  const { bookingsDuration, removeBooking } = useBookingsCreatingStore();
  const { selectedProject, projectsType, selectedTimeOff } = useSelectedProjectStore();

  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const { bookings } = useContext(PlannerContext);
  const [plannerDate] = useDateStore((state) => [state.date], shallow);
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);

  let userScheduleMax = usersMaxScheduleTime(filteredUsers.flat()[userIndex]);

  let currentDateBookings = bookings.filter(
    (booking) => booking.date === ToDateString(date) && booking.user_id === filteredUsers.flat()[userIndex].id
  );

  let currentDateDuration =
    (currentDateBookings
      .filter((booking) => booking.deleted_at === null)
      .map((booking) => booking.duration)
      .reduce((x, y) => x + y, 0) /
      60) *
    formatMapping[bookingFormat];

  const calculateLeftOffset = (bookingDate) => {
    let dateOffset = getNumberOfDays(plannerDate, bookingDate);
    let finalOffset = GROUPED_PLANNER_USER_WIDTH;

    if (groupClients) {
      finalOffset += 50;
    }

    for (let i = 0; i < dateOffset; i++) {
      let isWeekend =
        weekendsHidden && (OffsetDays(plannerDate, i).getDay() === 0 || OffsetDays(plannerDate, i).getDay() === 6);
      finalOffset += isWeekend ? 10 : getCellWidthForZoom(zoom);
    }

    return finalOffset;
  };

  const calculateHeight = () => {
    let result = bookingsDuration * formatMapping[bookingFormat];
    let dateEmptySpace = (userScheduleMax / 60) * formatMapping[bookingFormat] - currentDateDuration;

    if (dateEmptySpace < result) {
      return dateEmptySpace > 0 ? dateEmptySpace : 0;
    }

    return result;
  };

  const calculateOffsetTop = () => {
    return currentDateDuration;
  };

  if (weekendsHidden && (date.getDay() === 0 || date.getDay() === 6)) {
    return null;
  }

  return (
    <div
      className="creation-booking"
      style={{
        width: `${getCellWidthForZoom(zoom)}px`,
        left: `${calculateLeftOffset(date)}px`,
        height: `${calculateHeight()}px`,
        top: `${calculateOffsetTop()}px`,
        backgroundColor: projectsType === "time_off" ? selectedTimeOff?.color : selectedProject?.color,
      }}
      onMouseMove={(e) => {
        if (
          ["contractor", "regular"].includes(userRole) ||
          (userRole === "self_planner" && filteredUsers.flat()[userIndex].id !== profile.id)
        ) {
          return;
        }
        mouseMoved(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), e);
      }}
      onClick={() => {
        removeBooking(userIndex, date);
      }}
    >
      <div
        className="booking-line"
        style={{
          backgroundColor: projectsType === "time_off" ? selectedTimeOff?.color : selectedProject?.color,
          width: zoom === 90 ? "2px" : "4px",
        }}
      />
    </div>
  );
};

export default GroupedCreationBooking;
