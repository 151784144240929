import Api from "../../../Api";
import { GetDaysInZoom, getNumberOfDays, OffsetDays } from "../../../lib/DateUtils";

async function UpdateBookingsRequest(
  projectId,
  duration,
  tentative,
  location,
  startTime,
  comment,
  bookingsEditing,
  bookings,
  setBookings,
  closeEditWindow,
  alsoRepetition,
  usesBudget,
  task,
  repetition,
  date,
  zoom
) {
  let new_bookings = bookings || [];
  let updateResponse;

  await Api.Bookings.update(
    bookingsEditing.map((booking) => booking.id),
    {
      project_id: projectId,
      tentative: tentative,
      location: location,
      duration: duration * 60,
      start_time: startTime,
      comment: comment,
      also_repetitions: alsoRepetition,
      using_budget: usesBudget,
      task_id: task,
      repetition: repetition,
    }
  ).then((response) => {
    if (repetition?.on) {
      new_bookings = new_bookings.filter((booking) => booking.id !== bookingsEditing[0].id);

      response.data.forEach((i) => {
        const bookingDate = i.date;

        if (getNumberOfDays(bookingDate, OffsetDays(date, GetDaysInZoom(zoom))) > 0) {
          new_bookings.push(i);
        }
      });
    } else {
      response.data.forEach((booking) => {
        new_bookings[new_bookings.findIndex((b) => b.id === booking.id)] = booking;
      });
    }

    updateResponse = response;
  });

  setBookings(new_bookings);

  closeEditWindow();

  return updateResponse;
}

export default UpdateBookingsRequest;
