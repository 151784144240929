import React, { useContext, useRef, useState } from "react";
import { getNumberOfDays, getCellWidthForZoom, OffsetDays, getUtcTime, ToDateString } from "../../../../lib/DateUtils";
import { getPlannerOffsetX } from "../../../../lib/PlannerUtils";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import { PlannerContext } from "../../context/PlannerContext";
import Api from "../../../../Api";
import GetAllMilestones from "../../requests/GetAllMilestones";
import MilestoneHook from "./MilestoneHook";
import { DateTime } from "luxon";
import { useDateStore, useZoomStore } from "../../../../stores/planner";
import { shallow } from "zustand/shallow";

const Milestone = ({
  groupClients,
  milestone,
  project,
  weekendsHidden,
  projectMouseMoved,
  openEditMilestone,
  row,
  milestones,
  setMilestones,
}) => {
  const userRole = localStorage.getItem("tb-role") || "regular";
  const milestoneRef = useRef(null);

  const [zoom] = useZoomStore((state) => [state.zoom], shallow);
  const [date] = useDateStore((state) => [state.date], shallow);

  const [initialSize, setInitialSize] = useState(null);
  const [initialLeftOffset, setInitialLeftOffset] = useState(null);
  const [initialPos, setInitialPos] = useState(null);
  const [currentDay, setCurrentDay] = useState(0);

  const milestoneStartEarlier = getNumberOfDays(date, milestone.start_date) < 0;

  const calculateLeftOffset = (dateIndicator = milestone.start_date) => {
    let dateOffset = getNumberOfDays(date, dateIndicator);

    let finalOffset = 160;
    if (groupClients) {
      finalOffset += 50;
    }

    for (let i = 0; i < dateOffset; i++) {
      let calculatedDate = OffsetDays(date, i).getDay();
      let isWeekend = weekendsHidden && (calculatedDate === 0 || calculatedDate === 6);
      finalOffset += isWeekend ? 10 : getCellWidthForZoom(zoom);
    }

    return finalOffset;
  };

  const calculateWidth = () => {
    let width = 0;

    let startDate = milestone.start_date;

    if (milestoneStartEarlier) {
      startDate = date;
    }

    for (let i = 0; i <= getNumberOfDays(startDate, milestone.end_date); i++) {
      let calculatedDate = OffsetDays(getUtcTime(startDate), i).getDay();
      let isWeekend = weekendsHidden && (calculatedDate === 0 || calculatedDate === 6);
      width += isWeekend ? 10 : getCellWidthForZoom(zoom);
    }
    return width - 6;
  };

  const getMilestoneName = () => {
    let dayLength = 0;
    let startDate = milestone.start_date;

    if (milestoneStartEarlier) {
      startDate = date;
    }

    dayLength = getNumberOfDays(startDate, milestone.end_date);

    if (zoom === 90) {
      return dayLength ? milestone.name : null;
    } else {
      return milestone.name;
    }
  };

  if (getNumberOfDays(date, milestone.end_date) < 0) {
    return null;
  }

  const startMilestoneDragging = (e, side) => {
    e.stopPropagation();
    setInitialPos(e.clientX);
    setInitialSize(milestoneRef.current?.offsetWidth);
    setInitialLeftOffset(calculateLeftOffset());
    setCurrentDay(getNumberOfDays(date, side === "leftHook" ? milestone.start_date : milestone.end_date));
  };

  const draggingMilestone = (e, side) => {
    const milestonesRow = milestones
      .filter((_milestone) => _milestone.order === row)
      .sort((a, b) => (new Date(a.start_date).getTime() > new Date(b.start_date).getTime() ? 1 : -1));

    const indexMilestone = milestonesRow.indexOf(milestone);
    const nextMilestone = milestonesRow[indexMilestone + 1];
    const prevMilestone = milestonesRow[indexMilestone - 1];

    const nextMilestoneStartDay = getNumberOfDays(date, nextMilestone?.start_date);

    const prevMilestoneEndDay = getNumberOfDays(date, prevMilestone?.end_date);

    if (e.clientX > 160) {
      e.stopPropagation();
      milestoneRef.current.style.zIndex = 2;

      let cursorOffset = e.clientX - initialPos;
      let calculatedDate = OffsetDays(date, currentDay).getDay();
      const zoomStep = getCellWidthForZoom(zoom);
      const stepValue = weekendsHidden && [0, 6].includes(calculatedDate) ? 10 : zoomStep;
      const secondStepValue = weekendsHidden && (side === "leftHook" ? [0, 1] : [5, 6]).includes(calculatedDate) ? 10 : zoomStep;

      if (side === "leftHook") {
        if (cursorOffset > stepValue && initialSize > zoomStep) {
          milestoneRef.current.style.left = `${initialLeftOffset + stepValue}px`;
          milestoneRef.current.style.width = `${initialSize - stepValue}px`;
          setInitialLeftOffset(initialLeftOffset + stepValue);
          setInitialSize(initialSize - stepValue);
          setInitialPos(initialPos + stepValue);
          setCurrentDay(currentDay + 1);
        } else if (cursorOffset < 0 && initialLeftOffset > 160 && currentDay > prevMilestoneEndDay + 1) {
          milestoneRef.current.style.left = `${initialLeftOffset - secondStepValue}px`;
          milestoneRef.current.style.width = `${initialSize + secondStepValue}px`;
          setInitialLeftOffset(initialLeftOffset - secondStepValue);
          setInitialSize(initialSize + secondStepValue);
          setInitialPos(initialPos - secondStepValue);
          setCurrentDay(currentDay - 1);
        }
      }

      if (side === "rightHook") {
        if (cursorOffset < -stepValue && initialSize > stepValue) {
          milestoneRef.current.style.width = `${initialSize - stepValue}px`;
          setInitialSize(initialSize - stepValue);
          setInitialPos(initialPos - stepValue);
          setCurrentDay(currentDay - 1);
        } else if (cursorOffset > 0 && (nextMilestoneStartDay == 0 || currentDay < nextMilestoneStartDay - 1)) {
          milestoneRef.current.style.width = `${initialSize + secondStepValue}px`;
          setInitialSize(initialSize + secondStepValue);
          setInitialPos(initialPos + secondStepValue);
          setCurrentDay(currentDay + 1);
        }
      }
    }
  };

  const endMilestoneDragging = (e, side) => {
    e.stopPropagation();
    milestoneRef.current.style.width = 1;

    if (
      (initialSize !== milestoneRef.current?.offsetWidth || initialLeftOffset !== milestoneRef.current?.style.left) &&
      milestoneRef.current?.id === `milestone-${milestone.id}`
    ) {
      const changedDate = side === "leftHook" ? milestone.start_date : milestone.end_date;
      const daysDifference = currentDay - getNumberOfDays(date, changedDate);
      const newDate = DateTime.fromISO(changedDate).plus({ days: daysDifference }).toFormat("yyyy-MM-dd");

      Api.Milestones.update({
        id: milestone.id,
        name: milestone.name,
        start_date: ToDateString(getUtcTime(side === "leftHook" ? newDate : new Date(milestone.start_date))),
        end_date: ToDateString(getUtcTime(side === "rightHook" ? newDate : new Date(milestone.end_date))),
      })
        .then(() => GetAllMilestones(setMilestones))
        .catch((error) => {
          milestoneRef.current.style.width = `${calculateWidth()}px`;
          alert(error);
        });
    }
  };

  return (
    <>
      <WhiteTooltip title={milestone?.tooltip || milestone.name || ""}>
        <div
          id={`milestone-${milestone.id}`}
          ref={milestoneRef}
          className="milestone milestone-existing"
          style={{
            width: `${calculateWidth()}px`,
            left: `${calculateLeftOffset()}px`,
            backgroundColor: project.color,
            borderTopLeftRadius: milestoneStartEarlier ? 0 : "16px",
            borderBottomLeftRadius: milestoneStartEarlier ? 0 : "16px",
            marginTop: `${row * 20 + 5}px`,
          }}
          onMouseMove={(e) => {
            if (["contractor", "regular"].includes(userRole)) return;

            projectMouseMoved(getPlannerOffsetX(e), project.id);
          }}
          onClick={() => {
            openEditMilestone(milestone);
          }}
        >
          <p>{getMilestoneName()}</p>
        </div>
      </WhiteTooltip>

      {milestone.name &&
        ["leftHook", "rightHook"].map((side) => (
          <MilestoneHook
            side={side}
            row={row}
            zoomWidth={getCellWidthForZoom(zoom)}
            startMilestoneDragging={startMilestoneDragging}
            draggingMilestone={draggingMilestone}
            endMilestoneDragging={endMilestoneDragging}
            calculateWidth={calculateWidth}
            calculateLeftOffset={calculateLeftOffset}
            milestone={milestone}
            weekendsHidden={weekendsHidden}
          />
        ))}
    </>
  );
};

export default Milestone;
