import { GetDaysInZoom, getNumberOfDays, OffsetDays, ToDateString } from "../../../lib/DateUtils";
import Api from "../../../Api";

async function CreateBookingsRequest(
  project,
  duration,
  tentative,
  startTime,
  comment,
  date,
  zoom,
  bookings,
  setBookings,
  weekendsHidden,
  repetition,
  filteredUsers,
  teamId,
  location,
  creationBookingsArray,
  creationBookingDuration,
  usesBudget,
  task,
  weekendCreating
) {
  let userIds = [];
  let creationResponse;
  let bookingsToCreate = [];

  const repetitionRule = {
    on: !!repetition.repetitionType,
    type: repetition.repetitionType || null,
    every: repetition.repetitionEvery || null,
    ends_on: repetition.repetitionEndsAt ? repetition.repetitionEndsAtDate : null,
    ends_after: repetition.repetitionEndsAfter ? repetition.repetitionEndsAfterNumber : null,
    ignore_weekends: repetition.ignoreWeekends,
  };

  userIds = filteredUsers.map((user) => user.id);

  creationBookingsArray.forEach((bookingData) => {
    if (!weekendCreating) {
      if (weekendsHidden && zoom !== 1) {
        if (bookingData[1].getDay() === 6 || bookingData[1].getDay() === 0) {
          return;
        }
      }
    }

    bookingsToCreate.push({
      user_id: userIds[bookingData[0]],
      project_id: project.id,
      tentative: tentative,
      duration: (Math.round(creationBookingDuration * 2) / 2) * 60,
      start_time: startTime,
      comment: comment,
      location: location,
      using_budget: usesBudget,
      date: zoom === 1 ? date : ToDateString(bookingData[1]),
      order: 0,
      team_id: teamId,
      task_id: task,
    });
  });

  await Api.Bookings.create(bookingsToCreate, repetitionRule).then((response) => {
    creationResponse = response;
  });

  let new_bookings = bookings || [];

  creationResponse.data.bookings.forEach((i) => {
    const bookingDate = i.date;

    if (getNumberOfDays(bookingDate, OffsetDays(date, GetDaysInZoom(zoom))) > 0) {
      new_bookings.push(i);
    }
  });

  setBookings(new_bookings);

  return creationResponse;
}

export default CreateBookingsRequest;
