import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import Api from "../../../../Api";
import TeambookOutlinedButton from "../../../default_components/TeambookOutlinedButton";
import { isoCodes } from "../../../default_values/defaultDateFormats";
import { InfoTooltip } from "../../../default_components/InfoTooltip";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const EntityNameCustomization = ({}) => {
  const { t } = useTranslation();
  const [account] = useAccountStore((state) => [state.account], shallow);

  const [projectsCustomization, setProjectsCustomization] = useState(
    account.custom_translations?.filter((c) => c.key === "projects")?.[0]?.value || ""
  );
  const [projectCustomization, setProjectCustomization] = useState(
    account.custom_translations?.filter((c) => c.key === "project")?.[0]?.value || ""
  );
  const [usersCustomization, setUsersCustomization] = useState(
    account.custom_translations?.filter((c) => c.key === "users")?.[0]?.value || ""
  );
  const [userCustomization, setUserCustomization] = useState(
    account.custom_translations?.filter((c) => c.key === "user")?.[0]?.value || ""
  );

  const submitValues = () => {
    let requests = [];

    if (projectsCustomization.length > 0) {
      requests.push(
        Api.CustomTranslations.submit({
          key: "projects",
          value: projectsCustomization,
          language: account.language,
        })
      );
    }

    if (projectCustomization.length > 0) {
      requests.push(
        Api.CustomTranslations.submit({
          key: "project",
          value: projectCustomization,
          language: account.language,
        })
      );
    }

    if (usersCustomization.length > 0) {
      requests.push(
        Api.CustomTranslations.submit({
          key: "users",
          value: usersCustomization,
          language: account.language,
        })
      );
    }

    if (userCustomization.length > 0) {
      requests.push(
        Api.CustomTranslations.submit({
          key: "user",
          value: userCustomization,
          language: account.language,
        })
      );
    }

    Promise.all(requests).then(() => {
      window.location.reload();
    });
  };

  return (
    <div className="customization-sub-component" style={{ marginBottom: "40px" }}>
      <div className="flex">
        <p className="customization-sub-component__title">{t("settings.custom_system_names")}</p>

        <InfoTooltip
          style={{ marginLeft: "10px" }}
          content={<p style={{ marginBottom: "0" }}>{t("settings.custom_system_name_tooltip")}</p>}
        />
      </div>

      <div className="custom-field">
        <TeambookTextForm
          fieldName={t("settings.customization.projects")}
          fieldValue={projectsCustomization}
          onChange={(text) => setProjectsCustomization(text)}
          placeholder={"e.g. Tools"}
        />

        <TeambookTextForm
          fieldName={t("settings.customization.users")}
          fieldValue={usersCustomization}
          onChange={(text) => setUsersCustomization(text)}
          placeholder={"e.g. Resources"}
        />
      </div>

      <div className="custom-field">
        <TeambookTextForm
          fieldName={t("settings.customization.project")}
          fieldValue={projectCustomization}
          onChange={(text) => setProjectCustomization(text)}
          placeholder={"e.g. Tool"}
        />

        <TeambookTextForm
          fieldName={t("settings.customization.user")}
          fieldValue={userCustomization}
          onChange={(text) => setUserCustomization(text)}
          placeholder={"e.g. Resource"}
        />
      </div>

      <div className="customization-action-buttons">
        <TeambookBlueButton onClick={submitValues} variant="contained" text={t("save")}>
          {t("save")}
        </TeambookBlueButton>
      </div>
    </div>
  );
};

export default EntityNameCustomization;
