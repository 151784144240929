import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useTranslation } from "react-i18next";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import TeambookTextForm from "../../default_components/TeambookTextForm";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { TeambookCheckbox } from "../../default_components/TeambookCheckbox";
import Api from "../../../Api";
import TeambookOutlinedButton from "../../default_components/TeambookOutlinedButton";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const DeleteAccountComponent = ({ submitForm }) => {
  const { t } = useTranslation();
  const [account] = useAccountStore((state) => [state.account], shallow);

  const [open, setOpen] = React.useState(false);
  const [inputValue, setInputValue] = useState("");
  const [canDeleteAccount, setCanDeleteAccount] = useState(false);
  const [canSignOff, setCanSignOff] = useState(false);
  const [signOffDialogOpen, setSignOffDialogOpen] = useState(false);
  const [leaveReasons, setLeaveReasons] = useState([]);
  const [leaveReasonComment, setLeaveReasonComment] = useState("");

  const reasonMapping = {
    doesnt_fulfill: t("settings.delete_account.doesnt_fulfill"),
    trouble_using: t("settings.delete_account.trouble_using"),
    interface_issue: t("settings.delete_account.interface_issue"),
    technical_issue: t("settings.delete_account.technical_issue"),
    other: t("settings.delete_account.other"),
  };

  useEffect(() => {
    if (inputValue.toUpperCase() === account.name.toUpperCase()) {
      setCanDeleteAccount(true);
    } else {
      setCanDeleteAccount(false);
    }
  }, [inputValue]);

  useEffect(() => {
    if (leaveReasons.includes("other")) {
      if (leaveReasonComment.length > 0) {
        setCanSignOff(true);
      } else {
        setCanSignOff(false);
      }
    } else if (leaveReasons.length > 0) {
      setCanSignOff(true);
    } else {
      setCanSignOff(false);
    }
  }, [leaveReasons, leaveReasonComment]);

  const switchLeaveReason = (reason) => {
    if (leaveReasons.includes(reason)) {
      setLeaveReasons(leaveReasons.filter((r) => r !== reason));
    } else {
      setLeaveReasons([...leaveReasons, reason]);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSignOffDialogOpen(false);
    setInputValue("");
    setLeaveReasons([]);
    setLeaveReasonComment("");
  };

  const proceedToLeaveReason = () => {
    setSignOffDialogOpen(true);
    setOpen(false);
  };

  const deleteAccount = () => {
    Api.Administrators.create_sign_offs("delete_account", `${leaveReasons.join(", ")} | ${leaveReasonComment}`).then(
      submitForm()
    );
  };

  return (
    <div style={{ padding: "35px 125px", position: "relative", height: "100%" }} className="settings__delete">
      <p className="settings__delete-text">{t("settings.delete_account.text_1")}</p>

      <p className="settings__delete-text">{t("settings.delete_account.text_2")}</p>

      <p className="settings__delete-important-text">{t("settings.delete_account.text_3")}</p>

      <Button
        className="settings__delete-button"
        id="delete-account-button"
        variant="contained"
        onClick={handleClickOpen}
      >
        {t("delete")}
      </Button>

      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className="tb-default-dialog">
        <DialogTitle id="form-dialog-title">
          <p> {t("settings.delete_account.warning")}</p>

          <TeambookIcon name={icons.CROSS} onClick={handleClose} />
        </DialogTitle>

        <DialogContent style={{ margin: "20px 0px" }}>
          <p>
            {t("settings.delete_account.text_4")}
            <span style={{ color: "var(--header-nav-color)", fontWeight: 600 }}>{account.name}</span>.
          </p>

          <TeambookTextForm
            id="name"
            fieldValue={inputValue}
            onChange={setInputValue}
            fieldName={t("settings.delete_account.your_company_name")}
            autoFocus
          />
        </DialogContent>

        <DialogActions>
          <TeambookBlueButton
            id="delete-account"
            onClick={proceedToLeaveReason}
            color="primary"
            disabled={!canDeleteAccount}
            text={t("delete")}
          />
        </DialogActions>
      </Dialog>

      <Dialog
        open={signOffDialogOpen}
        aria-labelledby="form-dialog-title"
        className="tb-default-dialog leave-modal__dialog"
      >
        <DialogTitle id="form-dialog-title">
          <p>{t("settings.delete_account.sorry_to_see")}</p>
        </DialogTitle>

        <DialogContent style={{ margin: "20px 0px" }}>
          <p>{t("settings.delete_account.why_leaving")}:</p>

          {Object.keys(reasonMapping).map((reason) => (
            <div className="leave-modal__option">
              <TeambookCheckbox onChange={() => switchLeaveReason(reason)} checked={leaveReasons.includes(reason)} />

              <p onClick={() => switchLeaveReason(reason)}>{reasonMapping[reason]}</p>
            </div>
          ))}

          {leaveReasons.includes("other") && (
            <textarea
              value={leaveReasonComment}
              onChange={(e) => setLeaveReasonComment(e.target.value)}
              className="leave-modal__textarea"
              placeholder={t("settings.delete_account.thoughts")}
            />
          )}
        </DialogContent>

        <DialogActions>
          <TeambookOutlinedButton
            id="delete-account"
            onClick={deleteAccount}
            color="primary"
            disabled={!canSignOff}
            text={t("delete")}
          />

          <TeambookBlueButton onClick={handleClose} text={t("settings.delete_account.changed_mind")} />
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default DeleteAccountComponent;
