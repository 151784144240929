import React, { useEffect, useState } from "react";
import Api from "../../../../Api";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import Countries from "../../../default_values/Countries";
import FormErrorMessage from "../../../default_components/FormErrorMessage";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import LoadingComponent from "../../../default_components/LoadingComponent";
import { TeambookReactSelect } from "../../../default_components/TeambookReactSelect";
import { DialogTitle } from "@mui/material";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { notificationStore } from "../../../../stores/notificationStore";

const ChangeBillingInformationModal = ({ closeModal, setChangeBillingInformationOpened }) => {
  const { t } = useTranslation();
  const [setInAppNotification] = notificationStore((state) => [state.setInAppNotification], shallow);
  const [customerFirstName, setCustomerFirstName] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerCountry, setCustomerCountry] = useState("");
  const [customerVat, setCustomerVat] = useState("");
  const [customerAccountName, setCustomerAccountName] = useState("");
  const [customerCityName, setCustomerCityName] = useState("");
  const [customerStreetAddress, setCustomerStreetAddress] = useState("");
  const [customerPostalCode, setCustomerPostalCode] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fetchAccount] = useAccountStore((state) => [state.fetchAccount], shallow);

  const CountriesArray = Countries.map((country) => ({
    ...country,
    value: country.code3,
  }));

  useEffect(() => {
    setLoading(true);

    Api.Billing.customer_info().then((response) => {
      setCustomerFirstName(response.data.first_name);
      setCustomerLastName(response.data.last_name);
      setCustomerEmail(response.data.email);
      setCustomerCountry(response.data.country);
      setCustomerVat(response.data.vat_number);
      setCustomerAccountName(response.data.company_name);
      setCustomerCityName(response.data.city);
      setCustomerStreetAddress(response.data.street_address);
      setCustomerPostalCode(response.data.postal_code);
      setLoading(false);
    });
  }, []);

  const updateCustomer = (customerParams) => {
    if (
      customerFirstName === "" ||
      customerLastName === "" ||
      customerEmail === "" ||
      customerCountry === "" ||
      customerAccountName === "" ||
      customerCityName === "" ||
      customerStreetAddress === "" ||
      customerPostalCode === ""
    ) {
      setErrorMessage(t("settings.subscription.set_every_field"));
    } else {
      Api.Billing.update_customer(customerParams)
        .then((response) => {
          fetchAccount();
          setInAppNotification(t("notifications.settings.subscription.billing_info"));
          setChangeBillingInformationOpened(false);
        })
        .catch((error) => {
          setErrorMessage(error.response.data.error);
        });
    }
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  if (loading) {
    return <LoadingComponent left={48} />;
  }

  return (
    <>
      <DialogTitle id="add-user-dialog">
        <p>{t("settings.subscription.billing_information")}</p>
        <TeambookIcon name={icons.CROSS} onClick={() => closeModal()} />
      </DialogTitle>

      <DialogContent style={{ margin: "20px 0px" }}>
        <div className="row">
          <div className="col-md-6">
            <TeambookTextForm
              id={"customerFirstName"}
              fieldName={t("auth.first_name")}
              onChange={setCustomerFirstName}
              fieldValue={customerFirstName}
              type={"text"}
            />
          </div>

          <div className="col-md-6">
            <TeambookTextForm
              id={"customerLastName"}
              fieldName={t("auth.last_name")}
              onChange={setCustomerLastName}
              fieldValue={customerLastName}
              type={"text"}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-6">
            <TeambookTextForm
              id={"customerEmail"}
              fieldName={t("auth.email")}
              onChange={setCustomerEmail}
              fieldValue={customerEmail}
              type={"text"}
            />
          </div>

          <div className="col-md-6">
            <TeambookTextForm
              id={"customerAccountName"}
              fieldName={t("auth.company_name")}
              onChange={setCustomerAccountName}
              fieldValue={customerAccountName}
              type={"text"}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-md-5">
            <TeambookTextForm
              id={"customerStreetAddress"}
              fieldName={t("settings.subscription.street_address")}
              onChange={setCustomerStreetAddress}
              fieldValue={customerStreetAddress}
              type={"text"}
            />
          </div>

          <div className="col-md-3">
            <TeambookTextForm
              style={{ whiteSpace: "nowrap" }}
              id={"customerPostalCode"}
              fieldName={t("settings.subscription.postal_code")}
              onChange={setCustomerPostalCode}
              fieldValue={customerPostalCode}
              type={"text"}
            />
          </div>

          <div className="col-md-4">
            <TeambookTextForm
              id={"customerCityName"}
              fieldName={t("settings.subscription.city_name")}
              onChange={setCustomerCityName}
              fieldValue={customerCityName}
              type={"text"}
            />
          </div>
        </div>

        <div style={{ marginTop: 15 }} className="row">
          <div className="col-md-6">
            {/* <Autocomplete
              className="text-field tb__bg__white"
              name="country"
              type="text"
              options={Countries}
              getOptionLabel={(option) => option.name}
              onChange={(event, newCountry) => {
                newCountry === null
                  ? (newCountry = { code: "" })
                  : setCustomerCountry(newCountry.code);
              }}
              value={
                Countries.filter((value) => value.code === customerCountry)[0]
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            /> */}

            <TeambookReactSelect
              fieldName={t("auth.country")}
              height={43}
              options={CountriesArray}
              onChange={(country) => {
                setCustomerCountry(country.code);
              }}
              isSearchable={true}
              value={CountriesArray.find((value) => value.code === customerCountry)}
              grayPlaceholder
            />
          </div>

          <div className="col-md-6">
            <p style={{ fontSize: "14px", marginBottom: 5 }}>{t("settings.subscription.vat_number")}</p>
            <TeambookTextForm
              id={"customerVat"}
              onChange={setCustomerVat}
              fieldValue={customerVat}
              type={"text"}
              style={{ margin: 0 }}
            />
          </div>
        </div>

        {errorMessage !== null && <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />}

        {customerCountry?.code === "CH" && <p className="vat-text">{t("settings.subscription.vat_text")}</p>}
      </DialogContent>

      <DialogActions style={{ padding: "0px 40px 30px 40px" }}>
        <TeambookBlueButton
          text={t("update")}
          onClick={() =>
            updateCustomer({
              firstName: customerFirstName,
              lastName: customerLastName,
              email: customerEmail,
              country: customerCountry,
              vatNumber: customerVat,
              companyName: customerAccountName,
              city: customerCityName,
              postalCode: customerPostalCode,
              streetAddress: customerStreetAddress,
            })
          }
        />
      </DialogActions>
    </>
  );
};

export default ChangeBillingInformationModal;
