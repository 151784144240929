import React from "react";
import Header from "../header/Header";
import { useTranslation } from "react-i18next";

const NoMatch = ({ role }) => {
  const { t } = useTranslation();

  return (
    <>
      <Header />
      <div className="row header-error__container" id="404-page">
        <div className="col-md-4 offset-md-4 col-xs-12">
          <div className="not_found">
            <div className="top_code">
              <p>404</p>
            </div>
            <h2>{t("oops_error")}</h2>
            <div className="text">{t("cant_find_page")}</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default NoMatch;
