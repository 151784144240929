import Api from "../../Api";
import Header from "../header/Header";
import "./actuals.scss";
import ActualsControlBar from "./ActualsControlBar";
import WeekRow from "./calendar/WeekRow.js";
import React, { useEffect, useRef, useState } from "react";
import ActualDays from "./calendar/ActualDays";
import { IsDateInMonth } from "../../lib/DateUtils";
import LoadingComponent from "../default_components/LoadingComponent";
import { DateTime } from "luxon";
import lsKeys from "../default_values/defaultKeys";
import ActualLogRightClick from "./default_components/ActualLogRightClick";
import TooltipChanger from "./lib/TooltipChanger";
import ActualsTooltip from "./default_components/ActualsTooltip";
import { useTranslation } from "react-i18next";
import ActualsIsBlocked from "./default_components/ActualsIsBlocked";
import OnboardingComponent from "../planner/onboarding/OnboardingComponent";
import { ActualsContext } from "./context/ActualsContext";
import { Week } from "./week_view/Week";
import { useProfileStore } from "../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../stores/accountStore";
import { UserFeedbackBanner } from "../default_components/UserFeedbackBanner";

export default function ActualsPage() {
  const { t } = useTranslation();

  const [projects, setProjects] = useState();
  const [users, setUsers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [activities, setActivities] = useState([]);
  const [reportedHours, setReportedHours] = useState(null);
  const [approvedHours, setApprovedHours] = useState(null);
  const [actualLogs, setActualLogs] = useState([]);
  const [copyEnabled, setCopyEnabled] = useState(false);
  const [copyLogId, setCopyLogId] = useState();
  const [actualsAvailable, setActualsAvailable] = useState(true);
  const [onboardingOpened, setOnboardingOpened] = useState(
    localStorage.getItem(lsKeys.ACTUALS_ONBOARDING_OPENED) === "true"
  );
  const [actualsZoom, setActualsZoom] = useState(localStorage.getItem(lsKeys.ACTUALS_ZOOM) || "month"); // month | week
  const [date, setDate] = useState(DateTime.now().startOf("month"));
  const [showWeekendLogs, setShowWeekendLogs] = useState(
    localStorage.getItem(lsKeys.ACTUALS_SHOW_WEEKEND_LOGS) === "true" || true
  );
  const [disableTooltip, setDisableTooltip] = useState(
    localStorage.getItem(lsKeys.ACTUALS_DISABLE_TOOLTIP) === "true" || false
  );

  const [profile] = useProfileStore((state) => [state.profile], shallow);
  const [account] = useAccountStore((state) => [state.account], shallow);

  const actualLogRightClick = useRef(null);
  const actualLogRefTooltip = useRef(null);

  useEffect(() => {
    updateActuals();
  }, [date, profile]);

  useEffect(() => {
    if (actualsZoom === "month") {
      setDate(DateTime.now().startOf("month"));
    } else {
      setDate(DateTime.now().startOf("week"));
    }

    localStorage.setItem(lsKeys.ACTUALS_ZOOM, actualsZoom);
  }, [actualsZoom]);

  useEffect(() => {
    GetProjects();
    GetUsers();
    GetActivities();
    GetOccupations();
    GetTeams();
  }, []);

  useEffect(() => {
    if (account) {
      setActualsAvailable(account.actuals_is_available);
    }
  }, [account]);

  const isLoaded = !!account && !!projects && !!profile && !!users && !!activities && !!occupations;

  const updateActuals = () => {
    if (profile) {
      Api.ActualLogs.get({
        user_ids: [profile.id],
        start_date: date.startOf("week").toISODate(),
        end_date: date.endOf("month").endOf("week").toISODate(),
      }).then((res) => {
        setActualLogs(res.data.sort((a, b) => (a.created_at > b.created_at ? 1 : -1)));

        setReportedHours(
          res.data
            .filter((day) => IsDateInMonth(day, date) && day)
            .reduce((prev, repHours) => prev + repHours.duration, 0) / 60
        );
        setApprovedHours(
          res.data
            .filter((logProject) => {
              if (IsDateInMonth(logProject, date) && logProject.approved) return logProject;
            })
            .reduce((prev, repHours) => prev + repHours.duration, 0) / 60
        );
      });
    }
  };

  const GetTeams = () => {
    Api.Teams.all().then((res) => {
      setTeams(res.data);
    });
  };

  const GetUsers = () =>
    Api.Users.all().then((response) => {
      let activeUsers = response.data.filter(({ active }) => !!active);
      setUsers(activeUsers);
    });

  const GetProjects = () =>
    Api.Projects.all().then((response) => {
      setProjects([...response.data].sort((a, b) => (a.name > b.name ? 1 : -1)));
    });

  const GetActivities = () => {
    Api.ActualLogs.get_activities().then((response) => {
      setActivities(response.data);
    });
  };

  const GetOccupations = () => {
    Api.ActualLogs.get_occupations().then((response) => {
      setOccupations(response.data);
    });
  };

  const copyLogRequest = (date) => {
    const copyLog = actualLogs.filter((log) => log.id === copyLogId)[0];

    if (!copyLog) return;

    Api.ActualLogs.create({
      user_id: copyLog.user_id,
      project_id: copyLog.project_id,
      duration: copyLog.duration,
      date: date.toISODate(),
      payroll_item_id: copyLog.payroll_item_id,
      task_id: copyLog.task_id,
      note: copyLog.comment,
    }).then(() => {
      updateActuals();
    });
  };

  const copyLog = (logId) => {
    setCopyEnabled(true);
    setCopyLogId(logId);

    changeContextMenu({
      open: false,
    });
  };

  const cancelCopy = () => {
    setCopyEnabled(false);
  };

  const changeTooltip = (newTooltip) => {
    TooltipChanger({
      ...newTooltip,
      positionX: newTooltip.positionX + 7,
      positionY: newTooltip.positionY - 108,
    });
  };

  const createOccupation = (occupation, setSelectedOccupation) => {
    Api.Tasks.create({ name: occupation }).then((response) => {
      setOccupations([...occupations, response.data]);
    });
  };

  const createActivity = (activity, setSelectedActivity) => {
    Api.ActualLogs.create_activity({ name: activity }).then((response) => {
      setActivities([...activities, response.data]);
    });
  };

  const changeContextMenu = (newMenu) => {
    actualLogRightClick.current.setOpen(newMenu.open);
    actualLogRightClick.current.setPos(newMenu.pos);
    actualLogRightClick.current.setLog(newMenu.log);
  };

  const closeManageWindow = () => {
    GetActivities();
    GetOccupations();
  };

  return (
    <ActualsContext.Provider
      value={{
        zoom: actualsZoom,
      }}
    >
      <Header page={"actuals"} setOnboardingOpened={setOnboardingOpened} />

      {!isLoaded ? (
        <LoadingComponent />
      ) : actualsAvailable ? (
        <>
          <ActualsControlBar
            setDate={setDate}
            date={date}
            approvalMode={false}
            reportedHours={reportedHours}
            approvedHours={approvedHours}
            updateActuals={updateActuals}
            teams={teams}
            users={users}
            closeManageWindow={closeManageWindow}
            cancelCopy={cancelCopy}
            setActualsZoom={setActualsZoom}
            disableTooltip={disableTooltip}
            setDisableTooltip={setDisableTooltip}
            showWeekendLogs={showWeekendLogs}
            setShowWeekendLogs={setShowWeekendLogs}
          />

          <div className="actuals-grid">
            {actualsZoom === "month" ? (
              <div
                style={{
                  position: "relative",
                  left: 0,
                  marginLeft: 30,
                  marginRight: 30,
                  width: "100%",
                }}
              >
                <WeekRow showWeekendLogs={showWeekendLogs} />

                <ActualDays
                  changeContextMenu={changeContextMenu}
                  projects={projects}
                  date={date}
                  occupations={occupations}
                  activities={activities}
                  createActivity={createActivity}
                  createOccupation={createOccupation}
                  setReportedHours={setReportedHours}
                  setApprovedHours={setApprovedHours}
                  actualLogs={actualLogs}
                  updateActuals={updateActuals}
                  changeTooltip={changeTooltip}
                  copyEnabled={copyEnabled}
                  copyLogRequest={copyLogRequest}
                  showWeekendLogs={showWeekendLogs}
                />
              </div>
            ) : (
              <Week
                startDate={date.minus({ days: account.first_week_day === "Sunday" ? 1 : 0 })}
                logs={actualLogs}
                projects={projects}
                createActivity={createActivity}
                createOccupation={createOccupation}
                activities={activities}
                occupations={occupations}
                updateActuals={updateActuals}
                changeContextMenu={changeContextMenu}
                copyLogRequest={copyLogRequest}
                showWeekendLogs={showWeekendLogs}
                changeTooltip={changeTooltip}
              />
            )}
          </div>

          {!disableTooltip && <ActualsTooltip ref={actualLogRefTooltip} />}

          <ActualLogRightClick
            updateActuals={updateActuals}
            copyLog={copyLog}
            changeTooltip={changeTooltip}
            page={"actuals"}
            ref={actualLogRightClick}
          />

          {copyEnabled && (
            <div className="planner-control-panel__copy-information">
              <p className="info-text">{t("actuals.copy_header_text")}</p>
              <p className="cancel-button" onClick={cancelCopy}>
                {t("finish")}
              </p>
            </div>
          )}
        </>
      ) : (
        <>
          <ActualsIsBlocked />
        </>
      )}

      <OnboardingComponent
        page="actuals"
        onboardingOpened={onboardingOpened}
        setOnboardingOpened={setOnboardingOpened}
        role={localStorage.getItem(lsKeys.ROLE || "regular")}
      />

      {profile && <UserFeedbackBanner />}
    </ActualsContext.Provider>
  );
}
