import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormErrorMessage from "../../../../default_components/FormErrorMessage";
import Api from "../../../../../Api";
import { projectIconsQuantity, defaultProjectIcons } from "../../../../default_values/DefaultProjectIcons";
import TeambookTextForm from "../../../../default_components/TeambookTextForm";
import TeambookBlueButton from "../../../../default_components/TeambookBlueButton";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";
import { notificationStore } from "../../../../../stores/notificationStore";
import { shallow } from "zustand/shallow";

export default function CreateTimeOff({ open, setOpen, getProjects }) {
  const { t } = useTranslation();
  const [setInAppNotification] = notificationStore((state) => [state.setInAppNotification], shallow);
  const [name, setName] = useState("");
  const [code, setCode] = useState("");
  const [icon, setIcon] = useState(1);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const handleClose = () => {
    getProjects();

    setOpen(false);
    setIcon(null);
    setName("");
    setCode("");
    setErrorMessage(null);
  };

  const handleSubmit = () => {
    Api.Projects.create({
      name: name,
      code: code,
      kind: "time_off",
      color: "#7a7a7a",
      icon_id: icon,
      active: true,
    })
      .then((response) => {
        handleClose();
        setErrorMessage(null);
        setInAppNotification(t("notifications.settings.timeoff_holidays.time_off.create"));
      })
      .catch((error) => analyzeCreationError(error));
  };

  const analyzeCreationError = (error) => {
    setErrorMessage(error.response.data.error.message);
    setErrorField(error.response.data.error.field);
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  let allIcons = [];

  for (let i = 1; i < projectIconsQuantity; i++) {
    allIcons.push(
      <div
        className="time-off__icon"
        onClick={() => setIcon(i)}
        style={{ border: i === icon ? "1px solid var(--stroke)" : "1px solid transparent" }}
      >
        {defaultProjectIcons(i, "var(--text-2)")}
      </div>
    );
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      maxWidth={false}
      className="tb-default-dialog"
    >
      <DialogTitle id="form-dialog-title">
        <p> {t("settings.time_off.create_time_off")}</p>
        <TeambookIcon name={icons.CROSS} onClick={handleClose} />
      </DialogTitle>
      <DialogContent>
        <div>
          <div style={{ display: "flex", gap: 30 }}>
            <TeambookTextForm
              fieldName={t("settings.time_off.to_name")}
              id={"nameTimeOff"}
              onChange={(text) => setName(text.length > 0 ? text[0].toUpperCase() + text.slice(1) : "")}
              type={"text"}
              fieldValue={name}
              width={"250px"}
            />

            <TeambookTextForm
              fieldName={t("settings.time_off.to_short_name")}
              onChange={(text) => setCode(text.slice(0, 10))}
              id={"shortNameTimeOff"}
              type={"text"}
              fieldValue={code}
              width={"120px"}
            />
          </div>

          <div>
            {errorMessage !== null && errorField === "name" ? (
              <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
            ) : null}

            {errorMessage !== null && errorField === "code" ? (
              <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
            ) : null}
          </div>
        </div>

        <div className="time-off_icon-block">{allIcons}</div>

        {errorMessage !== null && errorField === "icon" ? (
          <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
        ) : null}
      </DialogContent>
      <DialogActions>
        <TeambookBlueButton id="createTimeOffButton" onClick={handleSubmit} color="primary" text={t("save")} />
      </DialogActions>
    </Dialog>
  );
}
