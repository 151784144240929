const STRING_FIELDS = ["name", "code", "client_name", "kind", "manager", "status", "business_unit"];
const INTEGER_FIELDS = [
  "bookings_duration",
  "estimated",
  "actual_logs_duration",
  "user_capacity_duration",
  "estimated_capacity",
  "estimated_logs",
];
const DATE_FIELDS = ["end_date", "start_date"];

export const sortProjects = (sortField, sortOrder, setDisplayingProjects, displayingProjects) => {
  if (STRING_FIELDS.includes(sortField)) {
    sortText(sortOrder, displayingProjects, setDisplayingProjects, sortField);
  } else if (INTEGER_FIELDS.includes(sortField)) {
    sortIntegers(sortOrder, displayingProjects, setDisplayingProjects, sortField);
  } else if (DATE_FIELDS.includes(sortField)) {
    sortDates(sortOrder, displayingProjects, setDisplayingProjects, sortField);
  }
};

const objectMapping = (object, field) => {
  if (field === "manager") {
    return object.manager?.last_name;
  } else if (field === "estimated_capacity") {
    return object.estimated;
  } else if (field === "estimated_logs") {
    return object.estimated;
  }

  return object[field];
};

const sortText = (sortOrder, displayingProjects, setDisplayingProjects, sortField) => {
  const sortingNominator = sortOrder === "a" ? 1 : -1;

  setDisplayingProjects(
    [...displayingProjects].sort((a, b) => {
      const mainField = sortOrder === "a" ? objectMapping(a, sortField) : objectMapping(b, sortField);
      const secondaryField = sortOrder === "a" ? objectMapping(b, sortField) : objectMapping(a, sortField);

      if (!mainField) return sortingNominator;

      if (!secondaryField) return -sortingNominator;

      return mainField.toUpperCase() > secondaryField.toUpperCase() ? 1 : -1;
    })
  );
};

const sortIntegers = (sortOrder, displayingProjects, setDisplayingProjects, sortField) => {
  const sortingNominator = sortOrder === "a" ? 1 : -1;

  setDisplayingProjects(
    [...displayingProjects].sort((a, b) => {
      const mainField = sortOrder === "a" ? objectMapping(a, sortField) : objectMapping(b, sortField);
      const secondaryField = sortOrder === "a" ? objectMapping(b, sortField) : objectMapping(a, sortField);

      if (!mainField) return sortingNominator;

      if (!secondaryField) return -sortingNominator;

      return mainField > secondaryField ? -1 : 1;
    })
  );
};

const sortDates = (sortOrder, displayingProjects, setDisplayingProjects, sortField) => {
  const sortingNominator = sortOrder === "a" ? 1 : -1;

  setDisplayingProjects(
    [...displayingProjects].sort((a, b) => {
      const mainField = sortOrder === "a" ? objectMapping(a, sortField) : objectMapping(b, sortField);
      const secondaryField = sortOrder === "a" ? objectMapping(b, sortField) : objectMapping(a, sortField);

      if (!mainField) return sortingNominator;

      if (!secondaryField) return -sortingNominator;

      return new Date(mainField).getTime() - new Date(secondaryField).getTime();
    })
  );
};
