import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ArrowUp from "../../../../default_images/black_arrow_top.svg";
import ArrowDown from "../../../../default_images/black_arrow_down.svg";
import moment from "moment/moment";
import { getCorrectTextLocale } from "../../../../capacity_planning";
import { WhiteTooltip } from "../../../../default_components/Tooltips";
import UsersProfileImage from "../../../../default_components/UsersProfileImage";
import ProjectsSubBlock from "./reporting_tags/ProjectsSubBlock";
import { calculateHeatStyles } from "../../../../capacity_planning/lib/calculateHeatStyles";
import { recalculateCapacityArray } from "../../../../capacity_planning/lib/recalculateCapacityArray";
import { StickyTotal } from "./reporting_tags/StickyTotal";
import { SliceStringWithDots } from "../../../../../lib/StringFormat";
import { TeambookIcon } from "../../../../default_images/TeambookIcon";
import { icons } from "../../../../default_images/IconsList";
import { useAccountStore } from "../../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const UsersBlock = ({
  user,
  date,
  reservations,
  showPercentage,
  projects,
  selectedViewType,
  percentageFormat,
  areAllUsersExpanded,
}) => {
  const { t } = useTranslation();
  const [calculatedDurations, setCalculatedDurations] = useState([]);
  const [percentageDurations, setPercentageDurations] = useState([]);
  const [isListDisplaying, setIsListDisplaying] = useState(false);

  const [account] = useAccountStore((state) => [state.account], shallow);

  const percentageFormatMapping = {
    all: 0,
    billable: 1,
    non_billable: 2,
    time_off: 3,
  };

  useEffect(() => {
    updateCalculations(reservations);
  }, [reservations, date, showPercentage]);

  useEffect(() => {
    if (filteredProjects(Object.keys(groupedProjectCapacities())).length > 0) {
      setIsListDisplaying(areAllUsersExpanded);
    }
  }, [areAllUsersExpanded]);

  const groupedProjectCapacities = () => {
    return reservations.reduce(function (r, a) {
      r[a.project_id] = r[a.project_id] || [];
      r[a.project_id].push(a);

      return r;
    }, Object.create(null));
  };

  const updateCalculations = (reservations) => {
    setPercentageDurations(calculatePercentageDuration(reservations));
    setCalculatedDurations(recalculateCapacityArray(date, reservations, selectedViewType));
  };

  const filteredProjects = (projectsIds) => {
    let time_offs = [];
    let non_time_offs = [];

    projectsIds.forEach((id) => {
      let project = projects.find((project) => +project.id === +id);

      if (project && project?.kind === "time_off" && ["all", "time_off"].includes(percentageFormat)) {
        time_offs.push(project);
      } else if (project && ["all", "billable", "non_billable"].includes(percentageFormat)) {
        if (["billable", "all"].includes(percentageFormat) && project.kind === "billable") {
          non_time_offs.push(project);
        } else if (["non_billable", "all"].includes(percentageFormat) && project.kind === "non_billable") {
          non_time_offs.push(project);
        }
      }
    });

    return [
      ...non_time_offs.sort((a, b) => (a.name > b.name ? 1 : -1)).map((pr) => pr.id),
      ...time_offs.sort((a, b) => (a.name > b.name ? 1 : -1)).map((pr) => pr.id),
    ];
  };

  const calculatePercentageDuration = () => {
    let newCapacityDurations = [];

    [...Array(selectedViewType)].forEach((x, i) => {
      const calculatedDate = date.plus({ months: i }).toISODate();

      const daysRserved = reservations
        .filter((cr) => moment(cr.date).format("DD.MM.YY") === moment(calculatedDate).format("DD.MM.YY"))
        .map((cr) => cr.days_reserved)
        .reduce((ps, a) => ps + a, 0);

      const billableDaysReserved = reservations
        .filter((cr) => moment(cr.date).format("DD.MM.YY") === moment(calculatedDate).format("DD.MM.YY"))
        .filter((cr) => projects.filter((p) => p.id === cr.project_id)[0]?.kind === "billable")
        .map((cr) => cr.days_reserved)
        .reduce((ps, a) => ps + a, 0);

      const nonBillableDaysReserved = reservations
        .filter((cr) => moment(cr.date).format("DD.MM.YY") === moment(calculatedDate).format("DD.MM.YY"))
        .filter((cr) => projects.filter((p) => p.id === cr.project_id)[0]?.kind === "non_billable")
        .map((cr) => cr.days_reserved)
        .reduce((ps, a) => ps + a, 0);

      const timeOffDaysReserved = reservations
        .filter((cr) => moment(cr.date).format("DD.MM.YY") === moment(calculatedDate).format("DD.MM.YY"))
        .filter((cr) => projects.filter((p) => p.id === cr.project_id)[0]?.kind === "time_off")
        .map((cr) => cr.days_reserved)
        .reduce((ps, a) => ps + a, 0);

      const userMonthDuration = monthDuration(date.plus({ months: i }));

      if (userMonthDuration > 0) {
        newCapacityDurations.push([
          ((daysRserved / userMonthDuration) * 100).toFixed(0),
          ((billableDaysReserved / userMonthDuration) * 100).toFixed(0),
          ((nonBillableDaysReserved / userMonthDuration) * 100).toFixed(0),
          ((timeOffDaysReserved / userMonthDuration) * 100).toFixed(0),
        ]);
      } else {
        newCapacityDurations.push([0, 0, 0, 0]);
      }
    });

    return newCapacityDurations;
  };

  const monthDuration = (newDate) => {
    let duration = 0;

    for (let i = 0; i < newDate.endOf("month").day; i += 1) {
      const newDateWeekday = newDate.plus({ days: i }).weekday - 1;

      if (user.schedule[newDateWeekday][0] + user.schedule[newDateWeekday][2] > 0) {
        duration += 1;
      }
    }

    return duration;
  };

  const percentageDuration = (i) => {
    const value = percentageDurations[i]?.[percentageFormatMapping[percentageFormat]];

    if (!value) {
      return "..";
    }

    return `${percentageDurations[i]?.[percentageFormatMapping[percentageFormat]]}%`;
  };

  return (
    <div className="reporting-block">
      <div className="reporting__headline">
        <div style={{ padding: "0px 0px 0px 15px" }} className="reporting__headline__name">
          <div className="flex named-row">
            <UsersProfileImage size={30} user={user} userImage={user.avatars["50"]} />
            <p
              onClick={() => {
                setIsListDisplaying(!isListDisplaying);
              }}
              style={{ cursor: "pointer", fontWeight: 500, fontStyle: "unset" }}
            >
              {SliceStringWithDots(user.name, 16)}

              {reservations.length > 0 && (
                <TeambookIcon
                  name={isListDisplaying ? icons.ARROW_UP : icons.ARROW_DOWN}
                  alt={"Show All Entities"}
                  tooltipTitle={t(`planning.capacity.${isListDisplaying ? "hide" : "show"}_projects`)}
                />
              )}
            </p>
          </div>

          <p style={{ justifyContent: "center" }} className="duration">
            {calculatedDurations.reduce((x, y) => x + y, 0)} {t("planning.days")}
          </p>
        </div>

        {[...Array(selectedViewType)].map((x, i) => {
          const styleBg = calculateHeatStyles(account, user, date.plus({ months: i }), calculatedDurations[i] || 0);
          const toolTipMessage = getCorrectTextLocale(styleBg, t, calculatedDurations[i] || 0);

          return (
            <div className={`table-cell ${styleBg}`} title={toolTipMessage}>
              <p>{(showPercentage ? percentageDuration(i) : calculatedDurations[i]) || "0"}</p>
            </div>
          );
        })}

        <StickyTotal style={{ width: 1, maxWidth: 1, minWidth: 1, borderRight: "unset" }} />
      </div>

      {isListDisplaying &&
        filteredProjects(Object.keys(groupedProjectCapacities())).map((projId) => (
          <ProjectsSubBlock
            project={projects.find((pr) => pr.id === parseInt(projId))}
            date={date}
            prReservations={reservations.filter((prR) => prR.project_id === parseInt(projId))}
            reservations={reservations}
            selectedViewType={selectedViewType}
            showPercentage={showPercentage}
            usersWeeklyTotal={user.schedule.map((schedule) => (schedule[0] + schedule[2] > 0 ? 1 : 0))}
          />
        ))}
    </div>
  );
};

export default UsersBlock;
