import React, { useState } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Api from "../../../Api";
import { useTranslation } from "react-i18next";
import TeambookTextForm from "../../default_components/TeambookTextForm";
import FormErrorMessage from "../../default_components/FormErrorMessage";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import TimeZones from "../../default_values/TimeZones";
import { TeambookReactSelect } from "../../default_components/TeambookReactSelect";
import lsKeys from "../../default_values/defaultKeys";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../stores/accountStore";
import { notificationStore } from "../../../stores/notificationStore";

export default function CreateUserDialog({
  open,
  setAddUserWindowOpen,
  profileRole,
  teams,
  currentTeam,
  setCreatedUsers,
  creatingUserName,
  setCreatingUserName,
  handleClose,
  setAddUser,
}) {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState(creatingUserName.split(" ")[0] || "");
  const [lastName, setLastName] = useState(creatingUserName.split(" ").slice(1).join(" ") || "");
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("regular");

  const [errorMessage, setErrorMessage] = useState(null);
  const [errorField, setErrorField] = useState(null);
  const [userTeams, setUserTeams] = useState([currentTeam]);

  const [account] = useAccountStore((state) => [state.account], shallow);
  const [setInAppNotification] = notificationStore((state) => [state.setInAppNotification], shallow);

  const closeCreateWindow = () => {
    setFirstName(null);
    setLastName(null);

    setEmail("");
    setCreatingUserName("");
    setErrorField(null);
    setErrorMessage(null);
    setAddUserWindowOpen(false);
    setRole("regular");
  };

  const phoneRegex = /^[+0-9][0-9]*$/;

  const roleOptions = () => {
    if (profileRole !== "admin") {
      return [
        { value: "regular", name: t("users.regular") },
        { value: "contractor", name: t("users.contractor") },
        { value: "self_planner", name: t("users.self_planner") },
        { value: "planner", name: t("users.planner") },
      ];
    } else {
      return [
        { value: "regular", name: t("users.regular") },
        { value: "contractor", name: t("users.contractor") },
        { value: "self_planner", name: t("users.self_planner") },
        { value: "planner", name: t("users.planner") },
        { value: "admin", name: t("users.admin") },
      ];
    }
  };

  const submitForm = () => {
    closeErrorMessage();

    if (userTeams.length < 1) {
      setErrorMessage(t("users.noteams"));
      setErrorField("teams");
    } else {
      Api.Users.create({
        first_name: firstName === "" ? null : firstName,
        last_name: lastName === "" ? null : lastName,
        email: email,
        billable: true,
        role: role,
        time_zone: account?.timeZone || TimeZones.timeZonesArray[0].value || null,
        phone_number: "",
        tags: [],

        team_ids: userTeams.map(({ id }) => id),
        schedule: account.schedule,
      })
        .then((response) => {
          setCreatedUsers((pr) => [...pr, response.data]);
          closeCreateWindow();
          handleClose();
          setInAppNotification(`${t("notifications.planning.add_users_to_team")} ${currentTeam.name}`);
          setAddUser(true);
        })
        .catch(analyzeNewUserError);
    }
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  const analyzeNewUserError = (error) => {
    setErrorMessage(error.response.data.error.message);
    setErrorField(error.response.data.error.field);
  };

  return (
    <Dialog
      id="create-user-on-planner"
      className="tb-default-dialog planner__create-user-dialog"
      open={open}
      onClose={closeCreateWindow}
    >
      <DialogTitle id="add-user-dialog">
        <p style={{ fontSize: 22, margin: 0 }}>{t("users.create_user")}</p>
        <TeambookIcon
          style={{ marginLeft: "auto", cursor: "pointer" }}
          onClick={closeCreateWindow}
          name={icons.CROSS}
        />
      </DialogTitle>
      <DialogContent style={{ width: 490, padding: "0px 24px 0px 40px" }}>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <TeambookTextForm
            placeholder="John"
            id={"firstName"}
            fieldName={t("users.first_name")}
            onChange={(text) => setFirstName(text.length > 0 ? (text[0]?.toUpperCase() || "") + text.slice(1) : "")}
            type={"text"}
            width="190px"
            fieldValue={firstName}
          />

          <TeambookTextForm
            placeholder="Smith"
            id={"lastName"}
            fieldName={t("users.last_name")}
            width="190px"
            onChange={(text) => setLastName(text.length > 0 ? (text[0]?.toUpperCase() || "") + text.slice(1) : "")}
            type={"text"}
            fieldValue={lastName}
          />
        </div>

        {errorMessage !== null && (errorField === "first_name" || errorField === "last_name") ? (
          <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
        ) : null}

        <div>
          <TeambookTextForm
            placeholder="johnsmith@gmail.com"
            id={"email"}
            fieldName={t("users.email")}
            onChange={(text) => setEmail(text)}
            type={"text"}
            fieldValue={email}
          />
          {errorMessage !== null && errorField === "email" ? (
            <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
          ) : null}
        </div>

        <div className="edit-user__value__select">
          <TeambookReactSelect
            nameStyles={{ fontSize: 14 }}
            fieldName={t("users.user_role")}
            maxWidth={true}
            value={roleOptions().find((option) => option.value === role)}
            onChange={(new_role) => setRole(new_role.value)}
            options={roleOptions()}
            width={"100%"}
          />
        </div>
      </DialogContent>
      <DialogActions style={{ marginTop: 10 }} id="save-created-user">
        <TeambookBlueButton onClick={submitForm} text={t("save")} />
      </DialogActions>
    </Dialog>
  );
}
