import React from "react";
import MyWeekColumn from "./MyWeekColumn";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import NoBookings from "../default_images/no_bookings.svg";

const MyWeekContent = ({ date, bookings, projects, tasks }) => {
  const { t } = useTranslation();

  const amountWeekHours =
    bookings.reduce((acc, booking) => acc + booking.duration, 0) / 60;
  const isLessToday = (day) => day < DateTime.now().minus({ days: 1 });
  const isToday = (day) =>
    day.toLocaleString() === DateTime.now().toLocaleString();
  const isWeekends = (day) => day.weekday > 5;

  const daysOfWeek = [
    t("actuals.week.monday"),
    t("actuals.week.tuesday"),
    t("actuals.week.wednesday"),
    t("actuals.week.thursday"),
    t("actuals.week.friday"),
    t("actuals.week.saturday"),
    t("actuals.week.sunday"),
  ];

  const columnClassName = (
    day,
    defaultClassName,
    columnBookings
  ) => `${defaultClassName}
  ${isLessToday(day) && "less-today"}
  ${isToday(day) && "today"}
  ${isWeekends(day) && "weekends"}
  ${columnBookings?.length > 0 && "bookings-exist"}`;

  if (bookings.length === 0) {
    return (
      <div className="my-week__no-bookings">
        <img src={NoBookings} />

        <div>
          <p>{t("my_week.no_bookings")}</p>
          <p>{t("my_week.enjoy_day")}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="my-week__content">
      <div className="my-week__content-titles">
        <div className="column__title total">{t("actuals.total")}</div>
        {daysOfWeek.map((day, index) => (
          <div
            className={columnClassName(
              date.startOf("week").plus({ days: index }),
              "column__title"
            )}
          >
            {day}
          </div>
        ))}
      </div>

      <div className="day-columns__container">
        <div className="my-week__column total">
          <p>{amountWeekHours}h</p>
        </div>

        {daysOfWeek.map((day, index) => (
          <MyWeekColumn
            columnDate={date.startOf("week").plus({ days: index })}
            bookings={bookings}
            projects={projects}
            columnClassName={columnClassName}
            tasks={tasks}
          />
        ))}
      </div>
    </div>
  );
};

export default MyWeekContent;
