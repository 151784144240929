import React from "react";
import { useTranslation } from "react-i18next";
import UserRow from "./UserRow";

const UsersList = ({ users, showUser, emptyFiltered, UserInfoTooltipRef, teams, selectUser, selectedUsers }) => {
  const { t } = useTranslation();

  const getUserTeams = (user) =>
    user.team_ids.map((user_team) => {
      return teams.find((team) => team.id === user_team);
    });

  const userIsSelected = (user) => selectedUsers.some(({ id }) => id === user.id);

  return (
    <div style={{ width: emptyFiltered ? "unset" : "fit-content" }} className="users-page__user-list">
      {emptyFiltered ? (
        <p style={{ fontWeight: 500 }}>{t("users.users_not_found")}</p>
      ) : (
        users?.map((user) => (
          <UserRow
            key={`user-block-${user.id}`}
            user={user}
            showUser={showUser}
            teams={getUserTeams(user)}
            selectUser={selectUser}
            isSelected={userIsSelected(user)}
          />
        ))
      )}
    </div>
  );
};

export default UsersList;
