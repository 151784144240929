import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import TeambookRadioButton from "../../default_components/TeambookRadioButton";
import Api from "../../../Api";
import { TeambookCheckbox } from "../../default_components/TeambookCheckbox";
import { InputNumber } from "../../default_components/InputNumber";
import TeambookNotificationComponent from "../../default_components/TeambookNotificationComponent";
import { useProfileStore } from "../../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { notificationStore } from "../../../stores/notificationStore";

const NotificationsSettings = () => {
  const { t } = useTranslation();
  const [user] = useProfileStore((state) => [state.profile], shallow);
  const [setInAppNotification] = notificationStore((state) => [state.setInAppNotification], shallow);
  const [notificationSetting, setNotificationSetting] = useState(user.notification_setting);

  const saveChanges = () => {
    Api.Users.update(user.id, {
      notifications: notificationSetting,
    }).then((response) => {
      // setUser(response.data);
      setInAppNotification(t("profile.notifications.updated"));
    });
  };

  return (
    <div
      style={{
        padding: "35px 120px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
      className="settings__general-info__form"
    >
      <TeambookNotificationComponent
        notificationSetting={notificationSetting}
        setNotificationSetting={setNotificationSetting}
      />

      <TeambookBlueButton
        className="save-notification"
        id="save-notifications-changes"
        onClick={saveChanges}
        text={t("save")}
      />
    </div>
  );
};

export default NotificationsSettings;
