import { useTranslation } from "react-i18next";
import { TeambookReactSelect } from "./TeambookReactSelect";
import { components } from "react-select";

export const TeambookAuthorizationPicker = ({ value, setValue, options }) => {
  const { t } = useTranslation();

  const Option = (props) => {
    return (
      <components.Option {...props}>
        <p className="tb-role-picker__role-name">{props.children}</p>
        <p className="tb-role-picker__role-description">{t(`users.${props.data.value}_description`)}</p>
      </components.Option>
    );
  };

  return (
    <TeambookReactSelect
      width={260}
      maxMenuHeight={421}
      value={value}
      onChange={(option) => setValue(option)}
      options={options}
      grayPlaceholder
      new_components={{
        Option: Option,
      }}
    />
  );
};
