import FormErrorMessage from "../../../default_components/FormErrorMessage";
import TimeZones from "../../../default_values/TimeZones";
import { isoCodes } from "../../../default_values/defaultDateFormats";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { InfoTooltip } from "../../../default_components/InfoTooltip";
import RichTextEditor from "../../../default_components/rich_text_editor/RichTextEditor";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const dateOptions = {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

{
}

const GeneralTabInfo = ({
  userField,
  viewType,
  firstName,
  avatarComponent,
  userTeams,
  setUserTeams,
  setFirstName,
  lastName,
  setLastName,
  email,
  setEmail,
  phone,
  setPhone,
  billable,
  setBillable,
  role,
  setRole,
  timeZone,
  setTimeZone,
  tagName,
  setTagName,
  closeErrorMessage,
  errorField,
  errorMessage,
  roleOptions,
  userBillability,
  user,
  users,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  notes,
  setNotes,
}) => {
  const { t } = useTranslation();
  const [defineDates, setDefineDates] = useState({
    startDate: !!startDate,
    endDate: !!endDate,
  });
  const [account] = useAccountStore((state) => [state.account], shallow);

  // const switchDefineDates = () => {
  //   if (!defineDates.start === false && d) {
  //     setStartDate(null);
  //     setEndDate(null);
  //   }

  //   setDefineDates(!defineDates);
  // };

  useEffect(() => {
    if (viewType !== "show") {
      if (!defineDates.startDate) {
        setStartDate(null);
      }
      if (!defineDates.endDate) {
        setEndDate(null);
      }
    }
  }, [defineDates]);

  return (
    <div style={{ gap: 20 }} className="flex">
      <div className="user-form__left-side">{avatarComponent()}</div>

      <div className="user-form__right-side">
        <div className="user-form__row">
          <div className="user-form__value-section first_name">
            {userField(t("users.first_name"), firstName, setFirstName, "text", "userFirstName")}
          </div>

          <div className="user-form__value-section">
            {userField(t("users.last_name"), lastName, setLastName, "text", "userLastName")}
          </div>
        </div>
        {errorMessage !== null && (errorField === "first_name" || errorField === "last_name") ? (
          <FormErrorMessage
            className={"form-error-message"}
            text={errorMessage}
            closeErrorMessage={closeErrorMessage}
          />
        ) : null}

        <div className="user-form__row">
          <div className="user-form__value-section">
            {userField(t("projects.client_email"), email, setEmail, "email", "userEmail")}
          </div>

          <div className="user-form__value-section">
            {userField(t("users.authorization"), role, setRole, "role", "userRole", roleOptions)}
          </div>
        </div>
        {errorMessage !== null && errorField === "email" ? (
          <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
        ) : null}

        <div className="user-form__row">
          <div className="user-form__value-section">
            {userField(t("users.analytics"), billable, setBillable, "array", "billability", userBillability)}
          </div>
          <div className="user-form__value-section">
            {userField(t("users.teams"), userTeams, setUserTeams, "teams")}
          </div>
        </div>

        {errorMessage !== null && errorField === "teams" ? (
          <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} />
        ) : null}

        <div className="user-form__row">
          <div className="user-form__value-section">{userField("Tags", tagName, setTagName, "tags")}</div>
          <div className="user-form__value-section">
            {userField(
              t("users.time_zone"),
              timeZone,
              setTimeZone,
              "array",
              "timeZoneSelect",
              TimeZones.timeZonesArray
            )}
          </div>
        </div>

        <div className="user-form__row">
          <div className="user-form__value-section">
            {userField(
              t("projects.start_date"),
              startDate,
              setStartDate,
              "date",
              "userStartDate",
              [],
              defineDates,
              setDefineDates
            )}
          </div>
          <div className="user-form__value-section">
            {userField(
              t("projects.end_date"),
              endDate,
              setEndDate,
              "date",
              "userEndDate",
              [],
              defineDates,
              setDefineDates
            )}
          </div>
        </div>

        <div className="user-form__row">
          <div className="user-form__value-section" style={{ flex: "1", maxWidth: "100%" }}>
            {userField("Notes", notes, setNotes, "richText", "notes")}
          </div>
        </div>
      </div>

      {viewType === "show" && (
        <InfoTooltip
          style={{ position: "absolute", left: 40, bottom: 50 }}
          content={
            viewType === "show" && (
              <div className="user-form__creation-info" style={{ flexDirection: "column" }}>
                <div>
                  <p>
                    <i style={{ marginRight: 4 }}>{t("reference_id")}:</i>
                    {user.reference_id}
                  </p>
                </div>

                {user.created_by && (
                  <div>
                    <p>
                      <i> {t("created_by")}</i>

                      <b>{users.find(({ id }) => id === user.created_by)?.name}</b>
                      {" at " +
                        new Date(user.created_at).toLocaleDateString(isoCodes(account.date_format), dateOptions)}
                    </p>
                  </div>
                )}

                {user.updated_by && (
                  <div>
                    <p>
                      <i> {t("updated_by")}</i>
                      <b> {users.find(({ id }) => id === user.updated_by)?.name}</b>
                      {" at " +
                        new Date(user.updated_at).toLocaleDateString(isoCodes(account.date_format), dateOptions)}
                    </p>
                  </div>
                )}
              </div>
            )
          }
        />
      )}
    </div>
  );
};

export default GeneralTabInfo;
