export const recalculateCapacityArray = (
  date,
  reservations,
  selectedViewType
) => {
  let newCapacityDurations = [];

  [...Array(selectedViewType)].forEach((x, i) => {
    const calculatedDate = date.plus({ months: i }).toISODate();

    newCapacityDurations.push(
      reservations.reduce((acc, cr) => {
        if (cr.date !== calculatedDate) return acc;

        return acc + cr.days_reserved;
      }, 0)
    );
  });

  return newCapacityDurations;
};

export const recalculateProjectCapacityArray = (
  date,
  reservations,
  project,
  selectedViewType
) => {
  let newCapacityDurations = [];

  [...Array(selectedViewType)].forEach((x, i) => {
    const calculatedDate = date.plus({ months: i }).toISODate();

    newCapacityDurations.push(
      reservations.reduce((acc, cr) => {
        if (cr.date !== calculatedDate) return acc;

        if (cr.project_id !== project.id) return acc;

        return acc + cr.days_reserved;
      }, 0)
    );
  });

  return newCapacityDurations;
};
