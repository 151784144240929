import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Slider, Switch } from "@mui/material";
import { useTranslation } from "react-i18next";

import { icons } from "../default_images/IconsList";
import lsKeys from "../default_values/defaultKeys";
import { TeambookIcon } from "../default_images/TeambookIcon";

const BOOKING_FORMAT = "bookingFormat";

const CapacityVisualSettings = ({ open, closeWindow, showTimeOff, setShowTimeOff, selectedViewType, setSelectedViewType }) => {
  const { t } = useTranslation();

  const marks = {
    6: 0,
    12: 1,
    24: 2,
  };

  const language = localStorage.getItem(lsKeys.LANGUAGE);
  const [currentView, setCurrentView] = useState(marks[selectedViewType]);

  const viewOptions = [
    {
      value: 0,
      label: t("planning.range.6_months"),
    },
    {
      value: 1,
      label: t("planning.range.year"),
    },
    {
      value: 2,
      label: t("planning.range.2_year"),
    },
  ];

  const viewValues = [6, 12, 24];

  return (
    <Dialog className="tb-default-dialog" open={open || false} onClose={() => closeWindow()}>
      <DialogTitle>
        <p style={{ margin: "auto 0" }}>{t("planning.control_panel.visual_settings")}</p>

        <TeambookIcon name={icons.CROSS} onClick={() => closeWindow()} />
      </DialogTitle>
      <DialogContent language={language} className="planner-settings-dialog__content capacity-settings">
        <div className="planner-settings-dialog__setting">
          <p className="planner-settings-dialog__setting-text">{t("planning.capacity.time_offs")}</p>
          <div className="planner-settings-dialog__setting-control" style={{ marginRight: -6 }}>
            <Switch
              id={"showTimeOff"}
              checked={showTimeOff}
              onChange={(event) => {
                setShowTimeOff(event.target.checked);
              }}
              color="primary"
              name="checkedB"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
          </div>
        </div>
        <div className="planner-settings-dialog__setting">
          <p className="planner-settings-dialog__setting-text">{t("planning.capacity.capacity_zoom")}</p>

          <div className="planner-settings-dialog__setting-control" style={{ width: "220px", alignItems: "center" }}>
            <Slider
              id={"cellsSize"}
              className={"planner-settings-dialog__slider"}
              aria-label="Restricted values"
              defaultValue={0}
              step={null}
              marks={viewOptions}
              max={2}
              value={currentView}
              onChange={(e, view) => {
                setSelectedViewType(viewValues[view]);
                setCurrentView(view);
              }}
            />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CapacityVisualSettings;
