const getRoleImportance = (role) => {
  switch (role) {
    case "admin":
      return 1;
    case "planner":
      return 2;
    case "self_planner":
      return 3;
    case "regular":
      return 4;
    case "contractor":
      return 5;
  }
};

export const sortUsers = (sortOrder, setDisplayingUsers, sortField, displayingUsers, teams) => {
  const getTeamNameStr = (team_ids) => {
    const getTeamName = (id) => {
      return teams.find((team) => team.id === id).name;
    };

    return [...team_ids]
      .sort((a, b) => (getTeamName(a).toUpperCase() < getTeamName(b).toUpperCase() ? -1 : 1))
      .reduce((prev, cur) => prev + getTeamName(cur).toUpperCase(), "");
  };

  switch (sortOrder) {
    case "a":
      if (sortField === "last_name") {
        setDisplayingUsers(
          [...displayingUsers].sort((a, b) => {
            return a[sortField].toLowerCase() < b[sortField].toLowerCase() ? -1 : 1;
          })
        );
      }
      if (sortField === "email") {
        setDisplayingUsers(
          [...displayingUsers].sort((a, b) => {
            if (!a.email) {
              return 1;
            }

            if (!b.email) {
              return -1;
            }
            return a[sortField].toLowerCase() > b[sortField].toLowerCase() ? 1 : -1;
          })
        );
      } else if (sortField === "team_ids") {
        setDisplayingUsers(
          [...displayingUsers].sort((a, b) => {
            if (a.team_ids.length === 0) {
              return 1;
            }

            if (b.team_ids.length === 0) {
              return -1;
            }

            return getTeamNameStr(a.team_ids) < getTeamNameStr(b.team_ids) ? -1 : 1;
          })
        );
      } else if (sortField === "role") {
        setDisplayingUsers(
          [...displayingUsers].sort((a, b) =>
            getRoleImportance(a[sortField]) > getRoleImportance(b[sortField]) ? -1 : 1
          )
        );
      } else if (sortField === "billable") {
        setDisplayingUsers([...displayingUsers].sort((a, b) => (!!a[sortField] < !!b[sortField] ? -1 : 1)));
      } else if (sortField === "tags") {
        setDisplayingUsers(
          [...displayingUsers].sort((a, b) => {
            if (a.tags.length === 0) return 1;
            if (b.tags.length === 0) return -1;

            const tagA = a.tags[0].name.toLowerCase();
            const tagB = b.tags[0].name.toLowerCase();

            return tagA < tagB ? -1 : 1;
          })
        );
      } else if (sortField === "start_date") {
        setDisplayingUsers(
          [...displayingUsers].sort((a, b) => {
            if (!a.start_date) return 1;
            if (!b.start_date) return -1;
            const dateA = new Date(a.start_date);
            const dateB = new Date(b.start_date);
            return dateA < dateB ? -1 : 1;
          })
        );
      } else if (sortField === "end_date") {
        setDisplayingUsers(
          [...displayingUsers].sort((a, b) => {
            if (!a.end_date) return 1;
            if (!b.end_date) return -1;
            const dateA = new Date(a.end_date);
            const dateB = new Date(b.end_date);
            return dateA < dateB ? -1 : 1;
          })
        );
      }
      break;

    case "z":
      if (sortField === "last_name") {
        setDisplayingUsers(
          [...displayingUsers].sort((a, b) => (a[sortField].toLowerCase() < b[sortField].toLowerCase() ? 1 : -1))
        );
      }
      if (sortField === "email") {
        setDisplayingUsers(
          [...displayingUsers].sort((a, b) => {
            if (!a.email) {
              return 1;
            }

            if (!b.email) {
              return -1;
            }
            return a[sortField].toLowerCase() > b[sortField].toLowerCase() ? -1 : 1;
          })
        );
      } else if (sortField === "team_ids") {
        setDisplayingUsers(
          [...displayingUsers].sort((a, b) => {
            if (a.team_ids.length === 0) {
              return 1;
            }

            if (b.team_ids.length === 0) {
              return -1;
            }

            return getTeamNameStr(a.team_ids) < getTeamNameStr(b.team_ids) ? 1 : -1;
          })
        );
      } else if (sortField === "role") {
        setDisplayingUsers(
          [...displayingUsers].sort((a, b) =>
            getRoleImportance(a[sortField]) > getRoleImportance(b[sortField]) ? 1 : -1
          )
        );
      } else if (sortField === "billable") {
        setDisplayingUsers([...displayingUsers].sort((a, b) => (!!a[sortField] < !!b[sortField] ? 1 : -1)));
      } else if (sortField === "tags") {
        setDisplayingUsers(
          [...displayingUsers].sort((a, b) => {
            if (a.tags.length === 0) return 1;
            if (b.tags.length === 0) return -1;

            const tagA = a.tags[0].name.toLowerCase();
            const tagB = b.tags[0].name.toLowerCase();

            return tagA > tagB ? -1 : 1;
          })
        );
      } else if (sortField === "start_date") {
        setDisplayingUsers(
          [...displayingUsers].sort((a, b) => {
            if (!a.start_date) return 1;
            if (!b.start_date) return -1;
            const dateA = new Date(a.start_date);
            const dateB = new Date(b.start_date);
            return dateA > dateB ? -1 : 1;
          })
        );
      } else if (sortField === "end_date") {
        setDisplayingUsers(
          [...displayingUsers].sort((a, b) => {
            if (!a.end_date) return 1;
            if (!b.end_date) return -1;
            const dateA = new Date(a.end_date);
            const dateB = new Date(b.end_date);
            return dateA > dateB ? -1 : 1;
          })
        );
      }
      break;
  }
};
