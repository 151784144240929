import React, { useState } from "react";
import TeambookBlueButton from "../../default_components/TeambookBlueButton";
import { useTranslation } from "react-i18next";
import TeambookScheduleComponent from "../../default_components/schedule/TeambookScheduleComponent";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";
import { notificationStore } from "../../../stores/notificationStore";

const ScheduleComponent = ({ submitForm }) => {
  const { t } = useTranslation();
  const [account] = useAccountStore((state) => [state.account], shallow);
  const [setInAppNotification] = notificationStore((state) => [state.setInAppNotification], shallow);
  const [schedule, setSchedule] = useState(account.schedule);

  const submitValues = () => {
    if (schedule.map((day) => day[0] + day[2]).reduce((ps, a) => ps + a, 0) === 0) {
      setInAppNotification(t("notifications.settings.schedule.incorrect_hours"));
    } else {
      submitForm({
        accountName: account.name,
        defaultBookingTime: account.default_booking_duration / 60,
        timeZone: account.time_zone,
        firstWeekDay: account.first_week_day,
        schedule: schedule,
        dateFormat: account.date_format,
      });

      setInAppNotification(t("notifications.settings.schedule.schedule_update"));
    }
  };

  return (
    <div className="account-schedule">
      <TeambookScheduleComponent schedule={schedule} setSchedule={setSchedule} kind={"edit"} />

      <p style={{ fontSize: "16px", textAlign: "left", marginTop: "20px" }}>{t("settings.schedule.important")}</p>

      <TeambookBlueButton
        onClick={submitValues}
        className="settings__submit"
        variant="contained"
        style={{ position: "absolute", right: "50px", bottom: "49px" }}
        text={t("save")}
      >
        {t("save")}
      </TeambookBlueButton>
    </div>
  );
};

export default ScheduleComponent;
