import React from "react";
import { useTranslation } from "react-i18next";
import ProjectBudget from "../../default_components/ProjectBudget";
import { TeambookCheckbox } from "../../default_components/TeambookCheckbox";
import { displayDate } from "../../default_values/defaultDateFormats";
import { useProjectsVisibilityColumnsStore } from "../../../stores/projects";
import { useAccountStore } from "../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const ProjectListBlock = ({ project, ShowProject, isSelected, selectProject }) => {
  const { t } = useTranslation();
  const { columnsVisibility } = useProjectsVisibilityColumnsStore();
  const [account] = useAccountStore((state) => [state.account], shallow);

  return (
    <div className={`project-list__block ${isSelected && "is-selected"}`}>
      <div onClick={() => ShowProject(project)} className="projects-page__project-element">
        <div onClick={(e) => e.stopPropagation()} className="project-list__select-checkbox">
          <TeambookCheckbox
            id="selectProject"
            type="checkbox"
            checked={isSelected}
            onChange={(e) => {
              selectProject(project);
            }}
          />
        </div>

        <div className="project-list__project-main-data column-width-3">
          <div style={{ background: project.color }} className="project-list__project-color" />

          <p className="project-list__name">{project.name}</p>
        </div>

        {columnsVisibility["short_name"] && <p className="project-list__code mobile_hidden column-width-2">{project.code}</p>}

        {columnsVisibility["client"] && <p className="project-list__client column-width-1">{project.client_name || "-"} </p>}

        {columnsVisibility["billable"] && (
          <p className="project-list__kind column-width-1">
            {project.kind === "billable" ? t("projects.billable") : t("projects.non_billable")}
          </p>
        )}

        {columnsVisibility["start_date"] && (
          <p className="project-list_date mobile_hidden column-width-1">
            {project.start_date ? displayDate(project.start_date, account?.date_format || null) : "-"}
          </p>
        )}

        {columnsVisibility["end_date"] && (
          <p className="project-list_date mobile_hidden column-width-1">
            {project.end_date ? displayDate(project.end_date, account?.date_format || null) : "-"}
          </p>
        )}

        {columnsVisibility["manager"] && (
          <p className="project-list__client mobile_hidden column-width-1">{project?.manager?.name || "-"}</p>
        )}

        {columnsVisibility["status"] && <p className="project-list__client mobile_hidden column-width-1">{project.status || "-"}</p>}

        {columnsVisibility["business_unit"] && (
          <p className="project-list__client mobile_hidden column-width-1">{project.business_unit || "-"}</p>
        )}

        {columnsVisibility["planned_hours"] && (
          <div className="project-list__estimated mobile_hidden column-width-4">
            <ProjectBudget project={project} type="planned" />
          </div>
        )}

        {columnsVisibility["logged_hours"] && (
          <p className="project-list__estimated mobile_hidden column-width-4">
            <ProjectBudget project={project} value={project.actual_logs_duration} type="logged" />
          </p>
        )}

        {columnsVisibility["capacity_days"] && (
          <div className="project-list__estimated mobile_hidden column-width-4">
            <ProjectBudget project={project} daysFormat={true} value={project.user_capacity_duration} type="capacity" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProjectListBlock;
