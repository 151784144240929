import React from "react";
import { useTranslation } from "react-i18next";
import { WhiteTooltip } from "../../../default_components/Tooltips";
import { dateFormat } from "../../../default_values/defaultDateFormats";
import { LanguagePicker } from "../../../default_components/LanguagePicker";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import betaImage from "../../../default_images/beta.png";
import { useAccountStore } from "../../../../stores/accountStore";
import { shallow } from "zustand/shallow";

const ShowSettings = ({ setEditing }) => {
  const { t } = useTranslation();
  const [account] = useAccountStore((state) => [state.account], shallow);

  const showStartTime = (time) => {
    let startTime = Math.floor(time / 100) + ":";
    if (time % 100 < 10) {
      startTime += "0";
    }
    startTime += time % 100;

    return startTime;
  };

  return (
    <div className="settings__show-container">
      <div className="settings_show-block">
        <div className="settings_show-value">
          <p className="name">{t("settings.general_info.account_name")}</p>

          <p className="value">{account.name}</p>
        </div>

        <div className="settings_show-value" style={{ textAlign: "left" }}>
          <p className="name">{t("settings.language.name")}</p>

          <LanguagePicker showCurrent={true} currentLang={account.language} type="text" />
        </div>

        <div className="settings_show-value">
          <p className="name">{t("settings.general_info.first_day_of_the_week")}</p>

          <p className="value">
            {account.first_week_day === "Monday" ? t("settings.general_info.monday") : t("settings.general_info.sunday")}
          </p>
        </div>
        <div className="settings_show-value">
          <p className="name">{t("settings.general_info.two_factor")}</p>

          <p className="value">{account.two_factor_enabled ? "Enabled" : "Disabled"}</p>
        </div>
      </div>

      <div className="settings_show-block">
        <div className="settings_show-value">
          <p className="name">{t("settings.general_info.default_booking_hours")}</p>

          <p className="value">{account.default_booking_duration / 60}h</p>
        </div>

        <div className="settings_show-value">
          <p className="name">{t("settings.general_info.default_time_zone")}</p>

          <p className="value">{account.time_zone}</p>
        </div>

        <div className="settings_show-value">
          <p className="name">{t("settings.general_info.date_format")}</p>

          <p className="value">{dateFormat(account.date_format).toUpperCase()}</p>
        </div>

        <div className="settings_show-value">
          <p className="name">
            {t("settings.general_info.capacity_auto_update")}
            &nbsp;
            <WhiteTooltip title={t("settings.general_info.capacity_auto_update_description")}>
              <img src={betaImage} alt={"BETA IMAGE"} />
            </WhiteTooltip>
          </p>

          <p className="value">{account.capacity_auto_sync ? "Enabled" : "Disabled"}</p>
        </div>
      </div>

      <WhiteTooltip title={t("edit")} placement="right">
        <div className="teambook-icon-frame is-settings" onClick={() => setEditing(true)}>
          <TeambookIcon name={icons.PENCIL} color="alternative_default" id="update" alt={"edit"} />
        </div>
      </WhiteTooltip>
    </div>
  );
};

export default ShowSettings;
