import ScheduleDay from "./ScheduleDay";
import { TeambookCheckbox } from "../TeambookCheckbox";
import { useTranslation } from "react-i18next";

const DEFAULT_SCHEDULE = [
  [240, 60, 240, 540],
  [240, 60, 240, 540],
  [240, 60, 240, 540],
  [240, 60, 240, 540],
  [240, 60, 240, 540],
  [240, 60, 240, 540],
  [240, 60, 240, 540],
];

const TeambookScheduleComponent = ({ schedule, setSchedule = () => {}, kind }) => {
  const { t } = useTranslation();

  const daysOfWeek = [
    t("my_week.monday"),
    t("my_week.tuesday"),
    t("my_week.wednesday"),
    t("my_week.thursday"),
    t("my_week.friday"),
    t("my_week.saturday"),
    t("my_week.sunday"),
  ];

  const checkboxClicked = (index) => {
    if (schedule[index][0] + schedule[index][2] !== 0) {
      const newSchedule = [...schedule];
      newSchedule[index] = [0, 60, 0, 540];
      setSchedule(newSchedule);
    } else {
      const newSchedule = [...schedule];
      newSchedule[index] = DEFAULT_SCHEDULE[index];
      setSchedule(newSchedule);
    }
  };

  return (
    <div className="schedule__component">
      <div className="schedule__weekdays">
        {schedule.map((scheduleDay, i) => (
          <div>
            <TeambookCheckbox
              checked={schedule[(i + 1) % 7][0] + schedule[(i + 1) % 7][2] !== 0}
              onChange={() => checkboxClicked((i + 1) % 7)}
            />
            <p className="pointer" onClick={() => checkboxClicked((i + 1) % 7)}>
              {daysOfWeek[i]}
            </p>
          </div>
        ))}
      </div>

      <div className="schedule__charts">
        <div className="schedule__hours">
          <div className="schedule__background" />
          {[6, 8, 10, 12, 14, 16, 18].map((hour) => (
            <p className="schedule__hour">{hour > 12 ? `${hour - 12}pm` : `${hour}am`}</p>
          ))}
        </div>

        <div className="schedule__user-rows" id="schedule-user-rows">
          <div className="schedule__background grid" />
          {schedule.map((_, i) => (
            <ScheduleDay index={(i + 1) % 7} schedule={schedule} setSchedule={setSchedule} kind={kind} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TeambookScheduleComponent;
