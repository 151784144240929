import React, { useState } from "react";
import Api from "../../Api";
import "./login.css";
import "../default_styles/tb-colors.css";
import FormErrorMessage from "../default_components/FormErrorMessage";
import { useTranslation } from "react-i18next";
import TeambookBlueButton from "../default_components/TeambookBlueButton";
import TeambookTextForm from "../default_components/TeambookTextForm";
import PasswordRules from "../register/PasswordRules";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { icons } from "../default_images/IconsList";

const ForgotPassword = ({ email: initialEmail, setForgotPasswordOpen }) => {
  const [email, setEmail] = useState(initialEmail);
  const [codeSent, setCodeSent] = useState(false);
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordUpdated, setPasswordUpdated] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const [passwordRulesVisible, setPasswordRulesVisible] = useState(false);
  const { t } = useTranslation();

  const switchPasswordShow = () => {
    setPasswordShown(!passwordShown);
  };

  const switchConfirmPasswordShow = () => {
    setConfirmPasswordShow(!confirmPasswordShow);
  };

  const sendCode = () => {
    Api.Auth.forgot_password(email)
      .then((response) => {
        setCodeSent(true);
        setErrorMessage(null);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.error);
      });
  };

  const verifyCode = () => {
    let letterRegex = /.*[^0-9].*/;
    let numberRegex = /.*[0-9].*/;
    let lengthPassword = password.length >= 8;
    let letterRule = letterRegex.test(password);
    let numberRule = numberRegex.test(password);

    if (password === passwordConfirmation) {
      if (lengthPassword && letterRule && numberRule) {
        Api.Auth.update_password(code, password)
          .then((response) => {
            setPasswordUpdated(true);
          })
          .catch((error) => {
            setErrorMessage(error.response.data.error.message);
          });
      } else {
        setErrorMessage("Please check your password to fit all rules.");
      }
    } else {
      setErrorMessage("Passwords do not match.");
    }
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  const PasswordRulesShow = () => {
    setPasswordRulesVisible(true);
  };

  return (
    <div className="login">
      <div className="login__left-side mobile_hidden">
        <div className="auth__left-side-background" />

        <div className="login__left-side-images">
          <TeambookIcon name={icons.TB_LOGO} color="blue" className="register__tb-logo mobile_hidden" />

          <TeambookIcon name={icons.LOGIN_BG} color="blue" style={{ left: 0, right: 0, margin: "0 auto", top: 210, zIndex: 2 }} />
        </div>
      </div>

      <div className="login__right-side">
        <TeambookIcon
          name={icons.TB_LOGO}
          color="blue"
          className="login__tb-logo desktop_hidden"
          style={{ top: 40, left: 30, position: "absolute" }}
        />
        <div className="login__form">
          <p className="login__input-side__login-text">{t("auth.reset_password")}</p>

          <p
            onClick={() => setForgotPasswordOpen(false)}
            className="register__input-side__login-link"
            style={{ margin: 0, cursor: "pointer" }}
          >
            {t("auth.go_back")}
          </p>

          {codeSent ? (
            passwordUpdated ? (
              <div className="login__input-side__form__data">
                <p style={{ color: "var(--text-2)" }}>{t("auth.password_updated")}</p>
              </div>
            ) : (
              <div className="login__input-side__form__data">
                <TeambookTextForm
                  className="text-field"
                  fieldName={t("auth.code")}
                  variant="outlined"
                  type="text"
                  onChange={setCode}
                  value={code}
                />

                <div style={{ position: "relative" }}>
                  <TeambookTextForm
                    className="text-field"
                    fieldName={t("auth.password")}
                    placeholder={t("auth.min_8_chars")}
                    type={passwordShown ? "text" : "password"}
                    onChange={setPassword}
                    value={password}
                    onFocus={PasswordRulesShow}
                  />

                  <TeambookIcon
                    name={passwordShown ? icons.EYE_OPENED : icons.EYE_CLOSED}
                    style={{
                      position: "absolute",
                      bottom: 9,
                      right: 15,
                      cursor: "pointer",
                    }}
                    onClick={switchPasswordShow}
                  />
                </div>

                <PasswordRules password={password} visible={passwordRulesVisible} />

                <div style={{ position: "relative" }}>
                  <TeambookTextForm
                    className="text-field"
                    fieldName={t("auth.password_confirmation")}
                    placeholder={t("auth.min_8_chars")}
                    type={confirmPasswordShow ? "text" : "password"}
                    onChange={setPasswordConfirmation}
                    value={passwordConfirmation}
                  />

                  <TeambookIcon
                    name={confirmPasswordShow ? icons.EYE_OPENED : icons.EYE_CLOSED}
                    style={{
                      position: "absolute",
                      bottom: 9,
                      right: 15,
                      cursor: "pointer",
                    }}
                    onClick={switchConfirmPasswordShow}
                  />
                </div>

                {errorMessage !== null ? (
                  <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} style={{ marginBottom: "20px" }} />
                ) : null}

                <TeambookBlueButton
                  className="login__input-side__submit"
                  text={t("auth.update_password")}
                  style={{ width: "100%" }}
                  onClick={verifyCode}
                />
              </div>
            )
          ) : (
            <div className="login__input-side__form__data">
              <div className="form-value">
                <TeambookTextForm
                  className="text-field"
                  fieldName={t("auth.email")}
                  placeholder="john_smith@mail.com"
                  type="email"
                  onChange={setEmail}
                  fieldValue={email}
                />
              </div>

              {errorMessage !== null ? (
                <FormErrorMessage text={errorMessage} closeErrorMessage={closeErrorMessage} style={{ marginBottom: "20px" }} />
              ) : null}

              <TeambookBlueButton text={t("auth.send_code")} style={{ width: "100%" }} onClick={sendCode} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
