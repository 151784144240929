import React, { useEffect, useState } from "react";
import Api from "../../Api";
import "./login.css";
import "../default_styles/tb-colors.css";
import { Link, Navigate, useParams } from "react-router-dom";
import FormErrorMessage from "../default_components/FormErrorMessage";
import ForgotPassword from "./ForgotPassword";
import { useTranslation } from "react-i18next";
import { languages } from "../default_values/Languages";
import TwoFactorModal from "./modals/TwoFactorModal";
import Notification from "./modals/Notification";
import TeambookBlueButton from "../default_components/TeambookBlueButton";
import lsKeys from "../default_values/defaultKeys";
import { useSearchParams } from "react-router-dom";
import "./login.scss";
import TeambookTextForm from "../default_components/TeambookTextForm";
import TeambookWhiteButton from "../default_components/TeambookWhiteButton";
import { icons } from "../default_images/IconsList";
import { TeambookIcon } from "../default_images/TeambookIcon";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useProfileStore } from "../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../stores/accountStore";
import GoogleButton from "../default_components/GoogleButton";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "../../lib/authConfig";

const ENTER_KEY = 13;

const Login = () => {
  const [navigate, setNavigate] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [forgotPasswordOpen, setForgotPasswordOpen] = useState(false);
  const [twoAuthWindowOpen, setTwoAuthWindowOpen] = useState(false);
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const [params] = useSearchParams();
  const [passwordShown, setPasswordShown] = useState(false);
  const [language, setLanguage] = useState(lang || localStorage.getItem("tb-lang") || "en");
  const [profile, fetchProfile] = useProfileStore((state) => [state.profile, state.fetchProfile], shallow);
  const [fetchAccount] = useAccountStore((state) => [state.fetchAccount], shallow);

  const { instance } = useMsal();

  const handleKeyDown = (e) => {
    if (e.keyCode === ENTER_KEY) {
      submitForm();
    }
  };

  useEffect(() => {
    if (lang && languages.includes(lang)) {
      localStorage.setItem("tb-lang", lang);
      i18n.changeLanguage(lang || "en");
      setLanguage(lang || "en");
    }

    if (localStorage.getItem("tb-lang") && languages.includes(localStorage.getItem("tb-lang"))) {
      i18n.changeLanguage(localStorage.getItem("tb-lang") || "en");
      setLanguage(localStorage.getItem("tb-lang") || "en");
    } else {
      localStorage.setItem("tb-lang", "en");
      i18n.changeLanguage("en");
      setLanguage("en");
    }

    if (profile && localStorage.getItem(lsKeys.AUTH_TOKEN)) {
      window.location.replace(`/planners`);
    }
  }, []);

  useEffect(() => {
    if (navigate) {
      localStorage.setItem(lsKeys.GTM_MOVE_ACTION, "login");
      // Push data to the Data Layer for GTM
      window.dataLayer.push({
        event: "navigationEvent", // Define a custom GTM event
        pageTitle: "Teambook Planner",
        pageUrl: "/planners",
      });
    }
  }, [navigate]);

  useEffect(() => {
    if (params.get("token")) {
      localStorage.setItem(lsKeys.AUTH_TOKEN, params.get("token"));
      Api.updateParamTokens(params.get("token"));
      redirectToMainPage();
    }

    document.title = t("auth.login_page_title");
  }, []);

  const redirectToMainPage = async () => {
    await fetchAccount();
    await fetchProfile();

    setNavigate(true);
  };

  const analyzeResponse = (response) => {
    if (response.status === 201) {
      redirectToMainPage();
    } else {
      window.location.reload();
    }
  };

  const loginUsingAzure = (token) => {
    Api.Auth.login_azure(token)
      .then(() => redirectToMainPage())
      .catch((error) => {
        setErrorMessage(error.response.data.error);
      });
  };

  const submitForm = () => {
    Api.Auth.login(email, password)
      .then(analyzeResponse)
      .catch((error) => {
        if (error.response.status === 401 && error.response.data.error === "2FA") {
          Api.Auth.send_two_factor(email).then(() => {
            setTwoAuthWindowOpen(true);
            closeErrorMessage();
          });
        } else {
          setErrorMessage(error.response.data.error);
        }
      });
  };

  const switchPasswordShow = () => {
    setPasswordShown(!passwordShown);
  };

  const closeErrorMessage = () => {
    setErrorMessage(null);
  };

  if (navigate) {
    return <Navigate to="/planners" />;
  }

  if (forgotPasswordOpen) {
    return <ForgotPassword email={email} setForgotPasswordOpen={setForgotPasswordOpen} />;
  }

  const responseGoogle = (response) => {
    console.log("GOOGLE LOGIN ERROR", response);
  };

  const successGoogle = (response) => {
    Api.Auth.login_google(response.code)
      .then(() => redirectToMainPage())
      .catch((error) => {
        setErrorMessage(error.response.data.error);
      });
  };

  const handleEmailChange = (newEmail) => {
    setEmail(newEmail);
  };

  return (
    <div className="login">
      <div className="login__left-side mobile_hidden">
        <div className="auth__left-side-background" />

        <div className="login__left-side-images">
          <TeambookIcon name={icons.TB_LOGO} color="blue" className="register__tb-logo mobile_hidden" />

          <TeambookIcon
            name={icons.LOGIN_BG}
            color="blue"
            style={{ left: 0, right: 0, margin: "0 auto", top: 210, zIndex: 2 }}
          />
        </div>

        <div className="notifications_container">
          <Notification header={t("auth.new_features")} lang={language} />
        </div>
      </div>

      <div className="login__right-side">
        <TeambookIcon
          name={icons.TB_LOGO}
          color="blue"
          className="login__tb-logo desktop_hidden"
          style={{ top: 40, left: 30, position: "absolute" }}
        />

        <div className="login__form">
          <p className="login__input-side__login-text">{t("auth.login")}</p>

          <Link to="/register" className="login__input-side__login-link">
            {t("auth.or_create")}
          </Link>

          <div style={{ marginTop: 50 }} className="login-form__inputs">
            <TeambookTextForm
              style={{ margin: 0 }}
              onChange={handleEmailChange}
              fieldValue={email}
              fieldName={t("auth.email")}
              onKeyDown={handleKeyDown}
              placeholder="john.smith@mail.com"
            />

            <div style={{ display: "flex", width: "100%", position: "relative" }}>
              <TeambookTextForm
                style={{ width: "100%", margin: "10px 0 5px 0px" }}
                onChange={setPassword}
                fieldValue={password}
                fieldName={t("auth.password")}
                placeholder={t("auth.password_placeholder")}
                onKeyDown={handleKeyDown}
                type={passwordShown ? "text" : "password"}
              />

              <TeambookIcon
                name={passwordShown ? icons.EYE_OPENED : icons.EYE_CLOSED}
                onClick={switchPasswordShow}
                style={{
                  position: "absolute",
                  bottom: 15,
                  right: 15,
                  cursor: "pointer",
                }}
              />
            </div>

            <div className="forgot-password" onClick={() => setForgotPasswordOpen(true)}>
              <p>{t("auth.forgot_password")}</p>
            </div>

            {errorMessage !== null ? (
              <FormErrorMessage
                text={errorMessage}
                closeErrorMessage={closeErrorMessage}
                style={{ margin: "10px 0" }}
              />
            ) : null}
          </div>

          <TwoFactorModal
            setNavigate={setNavigate}
            open={twoAuthWindowOpen}
            setOpen={setTwoAuthWindowOpen}
            email={email}
            redirectToMainPage={redirectToMainPage}
          />

          <TeambookBlueButton
            onClick={submitForm}
            id="login_btn"
            text={t("auth.login")}
            style={{
              width: "100%",
              marginTop: errorMessage ? 0 : 50,
            }}
            textId={"login_btn"}
          />

          <div className="login-register__or-line">
            <div className="line" />
            <p>{t("or_login_with").toLowerCase()}</p>
            <div className="line" />
          </div>

          <div className="login-options">
            <div id="google_login_btn">
              <GoogleOAuthProvider clientId="185032279806-68abgrt8pn29od30uhgbnfspchqc0tre.apps.googleusercontent.com">
                <GoogleButton
                  responseGoogle={responseGoogle}
                  successGoogle={successGoogle}
                  text={t("auth.sign_in_google")}
                />
              </GoogleOAuthProvider>
            </div>

            <TeambookWhiteButton
              text={
                <div className="flex">
                  <TeambookIcon
                    style={{
                      marginRight: "10px",
                    }}
                    name={icons.AZURE}
                    color={"red"}
                  />
                  <p>Azure</p>
                </div>
              }
              onClick={() => {
                instance
                  .loginPopup({
                    scopes: loginRequest.scopes,
                  })
                  .then((response) => {
                    loginUsingAzure(response.account.idToken);
                  })
                  .catch((e) => {
                    console.log("ERROR", e);
                  });
              }}
              id={"azure_login_btn"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
