import { useTranslation } from "react-i18next";

const ScheduleChart = ({ schedule, index, startedDragging, kind }) => {
  const { t } = useTranslation();

  const calculateWidth = (duration) => {
    return (duration / 840) * 98 || 1;
  };

  const calculateMarginLeft = () => {
    return ((schedule[3] - 360) / 840) * 98;
  };

  const startTime = () => {
    const hours = schedule[3] / 60;

    return parseHours(hours);
  };

  const endTime = () => {
    const hours = schedule.reduce((ps, a) => ps + a, 0) / 60;

    return parseHours(hours);
  };

  const parseHours = (hours) => {
    if (hours % 1 !== 0) {
      return hours > 12
        ? `${Math.floor(hours - 12)}:30 pm`
        : `${Math.floor(hours)}:30 am`;
    }

    return hours > 12 ? `${hours - 12} pm` : `${hours} am`;
  };

  const draggingStyle = () => {
    if (kind === "show") {
      return {};
    }

    return { cursor: "ew-resize" };
  };

  const calculateTotalStyle = () => {
    return {
      right: -50,
      marginLeft: "auto",
      whiteSpace: "nowrap",
      width: 45,
      textAlign: "left",
      position: "absolute",
    };
  };

  const dayDuration = () => {
    return (schedule[0] + schedule[2]) / 60;
  };

  return (
    <>
      <div
        className="schedule__chart__working"
        style={{
          borderRadius: "10px 0 0 10px",
          width: `${calculateWidth(schedule[0])}%`,
          marginLeft: `${calculateMarginLeft()}%`,
        }}
      >
        <div
          className="schedule__dragging"
          onMouseDown={(e) => startedDragging(e, "left")}
          style={draggingStyle()}
        />
        {/* <p className="schedule__start-time">{startTime()}</p> */}
        <p
          className={
            schedule[0] > 100
              ? "schedule__start-time"
              : "schedule__start-time-short"
          }
        >
          {startTime()}
        </p>
      </div>

      <div
        className="schedule__chart__lunch"
        style={{ width: `${calculateWidth(schedule[1])}%` }}
      >
        <div
          className="schedule__dragging"
          onMouseDown={(e) => startedDragging(e, "left-lunch")}
          style={draggingStyle()}
        />

        <div
          className="schedule__dragging"
          onMouseDown={(e) => startedDragging(e, "right-lunch")}
          style={draggingStyle()}
        />
      </div>

      <div
        className="schedule__chart__working-end"
        style={{
          borderRadius: "0 10px 10px 0",
          width: `${calculateWidth(schedule[2])}%`,
        }}
      >
        <div
          className="schedule__dragging"
          onMouseDown={(e) => startedDragging(e, "right")}
          style={draggingStyle()}
        />

        <p
          className={
            schedule[2] < 120
              ? "schedule__end-time-short"
              : "schedule__end-time"
          }
        >
          {endTime()}
        </p>
      </div>

      <p className="schedule__day-total" style={calculateTotalStyle()}>
        {dayDuration() + " h"}
      </p>
    </>
  );
};

export default ScheduleChart;
