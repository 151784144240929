import React from "react";
import { TeambookIcon } from "../../default_images/TeambookIcon";
import { icons } from "../../default_images/IconsList";
import { useTranslation } from "react-i18next";

const ActualsIsBlocked = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="actuals-blocked">
        <p className="header-text">{t("actuals.blocked.title")}</p>

        <p className="group-text">{t("actuals.blocked.group_text")}</p>

        <div className="check-group">
          <TeambookIcon name={icons.CHECK} style={{ width: "18px" }} color="blue" />

          <p>{t("actuals.blocked.check_1")}</p>
        </div>
        <div className="check-group">
          <TeambookIcon name={icons.CHECK} style={{ width: "18px" }} color="blue" />

          <p>{t("actuals.blocked.check_2")}</p>
        </div>
        <div className="check-group">
          <TeambookIcon name={icons.CHECK} style={{ width: "18px" }} color="blue" />

          <p>{t("actuals.blocked.check_3")}</p>
        </div>
        <div className="check-group" style={{ marginBottom: "40px" }}>
          <TeambookIcon name={icons.CHECK} style={{ width: "18px" }} color="blue" />

          <p>{t("actuals.blocked.check_4")}</p>
        </div>

        <p className="ending-text">
          {t("actuals.blocked.ending_1")} <b>{t("actuals.blocked.ending_2")}</b>
        </p>

        <p className="ending-text">{t("actuals.blocked.ending_3")}</p>

        <p className="ending-text">{t("actuals.blocked.ending_4")}</p>
      </div>

      <TeambookIcon className="actuals-blocked-icon" color="grouped_planner_add" name={icons.ACTUALS_BLOCKED} />
    </>
  );
};

export default ActualsIsBlocked;
