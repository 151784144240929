import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import TeambookTextForm from "../../../default_components/TeambookTextForm";
import Api from "../../../../Api";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import { DeleteTaskWarning } from "../lib/DeleteTaskWarning";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../stores/accountStore";

const ProjectAdvancedTab = ({
  tasks = [],
  setTasks = () => {},
  asyncCreate = false,
  project,
  setProjectTasks = () => {},
  updateProjectsList = () => {},
  customFields,
  setCustomFields = () => {},
}) => {
  const { t } = useTranslation();
  const [editTaskId, setEditTaskId] = useState(null);

  const [editTaskName, setEditTaskName] = useState("");

  const [isTaskCreating, setIsTaskCreating] = useState(false);
  const [creatingTaskName, setCreatingTaskName] = useState("");
  const [deleteConfirmationWindowOpened, setDeleteConfirmationWindowOpened] = useState(false);
  const [selectedTask, setSelectedTask] = useState();

  const [account] = useAccountStore((state) => [state.account], shallow);

  const startEditingTask = (id) => {
    cancelCreatingTask();

    setEditTaskId(id);
    setEditTaskName(tasks.find((task) => task.value === id || task.id === id).name);
  };

  const finishEditingTask = () => {
    if (editTaskName.length < 2 || editTaskName.length > 30) {
      return;
    }

    if (asyncCreate) {
      Api.Tasks.update(editTaskId, editTaskName).then((res) => {
        setTasks((pr) =>
          pr.map((task) => {
            if (task.value === editTaskId) {
              return { ...task, name: editTaskName };
            } else {
              return { ...task };
            }
          })
        );
        setProjectTasks((pr) =>
          pr.map((task) => {
            if (task.value === editTaskId) {
              return { ...task, name: editTaskName };
            } else {
              return { ...task };
            }
          })
        );
      });

      updateProjectsList();
    } else {
      setTasks((pr) =>
        pr.map((task) => {
          if (task.value === editTaskId) {
            return { value: editTaskName, name: editTaskName };
          } else {
            return { ...task };
          }
        })
      );
    }
    cancelEditingTask();
  };

  const cancelEditingTask = () => {
    setEditTaskId(null);
    setEditTaskName("");
  };

  const openConfirmationDelete = (task) => {
    setDeleteConfirmationWindowOpened(true);
    setSelectedTask(task);
  };

  const deleteTask = (task) => {
    openConfirmationDelete(task);
  };

  const startCreatingTask = () => {
    cancelEditingTask();
    setIsTaskCreating(true);
  };

  const cancelCreatingTask = () => {
    setIsTaskCreating(false);
    setCreatingTaskName("");
  };

  const finishCreatingTask = () => {
    if (creatingTaskName.length < 1 || creatingTaskName.length > 30) {
      return;
    }

    if (asyncCreate) {
      Api.Tasks.create({
        project_id: project.id,
        name: creatingTaskName,
      }).then((res) => {
        setTasks((pr) => [...pr, { id: res.data.id, value: res.data.id, name: res.data.name }]);
        setProjectTasks((pr) => [...pr, res.data]);
      });
      updateProjectsList();
    } else {
      setTasks((pr) => [...pr, { value: creatingTaskName, name: creatingTaskName }]);
    }
    cancelCreatingTask();
  };

  return (
    <div className="project-form__tasks-tab">
      <div className="project-form__tasks-title">
        <p>{t("projects.tasks")}</p> <TeambookIcon onClick={startCreatingTask} name={icons.ADD} />
      </div>

      {deleteConfirmationWindowOpened && (
        <DeleteTaskWarning
          close={() => setDeleteConfirmationWindowOpened(false)}
          task={selectedTask}
          onYes={(id) => {
            if (asyncCreate) {
              Api.Tasks.delete(id).then((res) => {
                setTasks((pr) => pr.filter((task) => task.value !== id));
                setProjectTasks((pr) => pr.filter((task) => task.id !== id));
              });
              updateProjectsList();
            } else {
              setTasks((pr) => pr.filter((task) => task.id !== id || task.value !== id));
            }
          }}
        />
      )}

      <div className="project-form__task-list">
        {isTaskCreating && (
          <div className="project-form__task" mode="create">
            <TeambookTextForm fieldValue={creatingTaskName} placeholder={t("projects.task_name")} onChange={setCreatingTaskName} />
            <div className="project-form__task-actions">
              <div onClick={finishCreatingTask} className="project-form__icon-wrapper">
                <TeambookIcon name={icons.CHECK} color="alternative_default" />
              </div>
              <div onClick={cancelCreatingTask} className="project-form__icon-wrapper">
                <TeambookIcon name={icons.CROSS} color="alternative_default" />
              </div>
            </div>
          </div>
        )}

        {tasks.map((task) =>
          editTaskId === task.id || editTaskId === task.value ? (
            <div className="project-form__task">
              <TeambookTextForm placeholder={t("projects.task_name")} fieldValue={editTaskName} onChange={setEditTaskName} />

              <div className="project-form__task-actions">
                <div onClick={finishEditingTask} className="project-form__icon-wrapper">
                  <TeambookIcon name={icons.CHECK} color="alternative_default" />
                </div>
                <div onClick={cancelEditingTask} className="project-form__icon-wrapper">
                  <TeambookIcon name={icons.CROSS} color="alternative_default" />
                </div>
              </div>
            </div>
          ) : (
            <div className="project-form__task">
              <p>{task.name}</p>

              <div className="project-form__task-actions">
                <div onClick={() => startEditingTask(task.id || task.value)} className="project-form__icon-wrapper">
                  <TeambookIcon name={icons.PENCIL} color="alternative_default" />
                </div>
                <div onClick={() => deleteTask(task)} className="project-form__icon-wrapper">
                  <TeambookIcon name={icons.TRASH} color="alternative_default" />
                </div>
              </div>
            </div>
          )
        )}
      </div>

      <div className="custom-fields">
        <p className="custom-fields-title">{t("projects.custom_fields")}</p>

        <div className="custom-fields-inputs">
          {account.custom_project_fields[0] && (
            <TeambookTextForm
              fieldName={account.custom_project_fields[0] || ""}
              onChange={(newValue) => setCustomFields({ ...customFields, field_value_1: newValue })}
              fieldValue={customFields.field_value_1 || ""}
              type={"text"}
              className={"custom-field__input"}
            />
          )}

          {account.custom_project_fields[1] && (
            <TeambookTextForm
              fieldName={account.custom_project_fields[1] || ""}
              onChange={(newValue) => setCustomFields({ ...customFields, field_value_2: newValue })}
              fieldValue={customFields.field_value_2 || ""}
              type={"text"}
              className={"custom-field__input"}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectAdvancedTab;
