import React from "react";
import NotEnoughData from "../../default_images/need_more_data.svg";
import TeambookWhiteButton from "../../default_components/TeambookWhiteButton";
import { useTranslation } from "react-i18next";

const NoUsersTeam = ({ addUser }) => {
  const { t } = useTranslation();
  return (
    <div className="no-users-team">
      <img src={NotEnoughData} alt={"No Users"} />

      <p>{t("planning.no_users_planner")}</p>

      <TeambookWhiteButton
        text={`${t("planning.capacity.add_users")} +`}
        onClick={addUser}
      />
    </div>
  );
};

export default NoUsersTeam;
