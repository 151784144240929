import "./schedule.scss";
import ScheduleChart from "./ScheduleChart";
import { useEffect, useState } from "react";
import $ from "jquery";

const ScheduleDay = ({ index, schedule, setSchedule, kind }) => {
  const [draggingDirection, setDraggingDirection] = useState();
  const [draggingX, setDraggingX] = useState();
  const [localSchedule, setLocalSchedule] = useState([...schedule]);
  const [scheduleShown, setScheduleShown] = useState(true);
  const [draggingDuration, setDraggingDuration] = useState(0);
  const [draggingPreviousValue, setDraggingPreviousValue] = useState(0);

  useEffect(() => {
    setScheduleShown(schedule[index][0] + schedule[index][2] !== 0);
    setLocalSchedule([...schedule]);
  }, [schedule]);

  useEffect(() => {
    const newSchedule = [...schedule];
    const durationDifference = draggingDuration - draggingPreviousValue;

    if (draggingDirection === "left") {
      newSchedule[index][0] = newSchedule[index][0] - durationDifference * 30;
      newSchedule[index][3] = newSchedule[index][3] + durationDifference * 30;
    } else if (draggingDirection === "left-lunch") {
      newSchedule[index][0] = newSchedule[index][0] + durationDifference * 30;
      newSchedule[index][1] = newSchedule[index][1] - durationDifference * 30;
    } else if (draggingDirection === "right-lunch") {
      newSchedule[index][2] = newSchedule[index][2] - durationDifference * 30;
      newSchedule[index][1] = newSchedule[index][1] + durationDifference * 30;
    } else if (draggingDirection === "right") {
      newSchedule[index][2] = newSchedule[index][2] + durationDifference * 30;
    }

    setLocalSchedule(newSchedule);
    setSchedule(newSchedule);
    setDraggingPreviousValue(draggingDuration);
  }, [draggingDuration]);

  const startedDragging = (event, direction) => {
    if (kind === "show") {
      return;
    }

    setSchedule((pr) => pr);
    setDraggingX(event.clientX);
    setDraggingDirection(direction);
  };

  const mouseMoved = (event) => {
    if (draggingDirection) {
      const changeInHours = Math.floor(
        Math.abs(event.clientX - draggingX) /
          $("#schedule-user-rows").width() /
          0.035
      );

      const realChange =
        event.clientX - draggingX > 0 ? changeInHours : -changeInHours;

      setDraggingDuration(
        Math.abs(realChange) === 0 ? Math.abs(realChange) : realChange
      );
    }
  };

  const stopResize = () => {
    setDraggingX(null);
    setDraggingDirection(null);
    setDraggingPreviousValue(0);
  };

  return (
    <div
      className="schedule__day"
      onMouseMove={mouseMoved}
      onMouseUp={stopResize}
    >
      {scheduleShown && (
        <ScheduleChart
          schedule={localSchedule[index]}
          index={index}
          startedDragging={startedDragging}
          kind={kind}
        />
      )}
    </div>
  );
};

export default ScheduleDay;
