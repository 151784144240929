import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Api from "../../../Api";
import { recalculateCapacityArray } from "../lib/recalculateCapacityArray";
import lsKeys from "../../default_values/defaultKeys";

const PlannedRow = ({ project, date, projectReservations, updateProjectReservations, selectedViewType }) => {
  const { t } = useTranslation();
  const [calculatedDurations, setCalculatedDurations] = useState([]);
  const fullAccessRoles = ["admin", "planner"];
  const role = localStorage.getItem(lsKeys.ROLE);

  useEffect(() => {
    setCalculatedDurations(recalculateCapacityArray(date, projectReservations, selectedViewType));
  }, [projectReservations, date, project]);

  const updateReservation = (newValue, index) => {
    let newCalculated = [...calculatedDurations];

    newCalculated[index] = newValue;

    Api.CapacityReservations.create_project_reservations({
      project_id: project.id,
      days_reserved: newValue,
      date: date.plus({ months: index }).toISODate(),
    }).then(() => {
      updateProjectReservations(index);
    });

    setCalculatedDurations(newCalculated);
  };

  const handleFocus = (event) => event.target.select();

  return (
    <div className="flex project-row">
      <div className="users-block__project-info">
        <p className="col-md-5">{t("planning.capacity.days_planned")}</p>
      </div>

      {[...Array(selectedViewType)].map((x, i, arr) => (
        <div style={{ borderRight: arr.length - 1 === i ? "1px solid var(--stroke)" : "" }} className="table-cell">
          {fullAccessRoles.includes(role) ? (
            <input
              onChange={(event) => updateReservation(event.target.value >= 0 ? event.target.value : 0, i)}
              onFocus={handleFocus}
              onWheel={(e) => e.target.blur()}
              value={parseInt(calculatedDurations[i]) || 0}
              style={{ width: "100%" }}
              onDragOver={(e) => e.preventDefault()}
              onDrop={(e) => e.preventDefault()}
            />
          ) : (
            <p>{parseInt(calculatedDurations[i])}</p>
          )}
        </div>
      ))}
    </div>
  );
};

export default PlannedRow;
