import React, { useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "../dashboard.scss";
import { useTranslation } from "react-i18next";
import DashboardTopsBar from "./DashboardTopsBar";

const DashboardTops = ({ stats, type }) => {
  const { t } = useTranslation();

  const [topsSelected, setTopsSelected] = useState(true);

  return (
    <>
      <div className="dashboard-tops" style={{ height: type === "compare" ? 155 : 142 }}>
        <p className="dashboard-tops__title" data-active={topsSelected} onClick={() => setTopsSelected(true)}>
          Top 5
        </p>

        {type === "capacity" && (
          <p className="dashboard-tops__low-title" data-active={!topsSelected} onClick={() => setTopsSelected(false)}>
            Low 5
          </p>
        )}

        <div className="dashboard-tops__rows">
          {stats.map(({ tops, lows }, i) => {
            const display_title = (type === "compare" && i === 1) || type !== "compare";

            return (
              <div className="dashboard-tops__row">
                {type === "compare" && <p style={{ margin: 0 }}>{i === 0 ? t("dashboard.planned") : t("dashboard.logged")}</p>}

                <div className="dashboard-tops__block">
                  <DashboardTopsBar
                    topData={(topsSelected ? tops : lows).users_productivity}
                    type={"user"}
                    view={type}
                    entityType={["bookings", "actuals"][i]}
                  />

                  {display_title && <p style={{ position: "absolute" }}>{t("dashboard.productivity")}</p>}
                </div>

                <div className="dashboard-tops__block">
                  <DashboardTopsBar
                    topData={(topsSelected ? tops : lows).users_utilization}
                    type={"user"}
                    view={type}
                    entityType={["bookings", "actuals"][i]}
                  />

                  {display_title && <p style={{ position: "absolute" }}>{t("dashboard.utilization")}</p>}
                </div>

                <div className="dashboard-tops__block">
                  <DashboardTopsBar
                    topData={(topsSelected ? tops : lows).projects}
                    type={"project"}
                    view={type}
                    entityType={["bookings", "actuals"][i]}
                  />

                  {display_title && <p style={{ position: "absolute" }}>{t("dashboard.project")}</p>}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default DashboardTops;
