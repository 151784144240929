import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { getPlannerOffsetX, getPlannerOffsetY } from "../../../../lib/PlannerUtils";
import { useShiftPressedStore, useZoomStore } from "../../../../stores/planner";
import { PlannerContext } from "../../context/PlannerContext";
import { useProfileStore } from "../../../../stores/profileStore";
import { shallow } from "zustand/shallow";
import { notificationStore } from "../../../../stores/notificationStore";

const GroupedUserRowWeek = ({
  mouseDowned,
  user,
  mouseMoved,
  mouseUped,
  weekendsHidden,
  changeTooltip,
  filteredUsers,
  isLastWeek,
  isLastUser,
}) => {
  const userRole = localStorage.getItem("tb-role") || "regular";
  const [zoom] = useZoomStore((state) => [state.zoom], shallow);
  const [setInAppNotification] = notificationStore((state) => [state.setInAppNotification], shallow);
  const [profile] = useProfileStore((state) => [state.profile], shallow);

  const { t } = useTranslation();

  const calculateWidth = () => {
    let width;

    if (zoom === 28) {
      width = 360;
    } else if (zoom === 14) {
      width = 700;
    } else {
      width = 100;
    }

    return {
      width: `${width}px`,
      borderRight: weekendsHidden && "1px solid transparent",
      background: `repeating-linear-gradient(90deg,var(--background-1),var(--background-1) ${
        width / 5 - 1
      }px,var(--stroke) 1px,var(--stroke) ${width / 5}px)`,
    };
  };

  const calculateWeekendStyle = (index) => {
    if (weekendsHidden) {
      return {
        width: "20px",
        borderLeft: "1px solid var(--text-3)",
        background: `var(--background-2)`,
        opacity: "0.5",
      };
    } else {
      if (zoom === 28) {
        return {
          width: "144px",
          borderRight: "1px solid var(--accent-light)",
          background: `var(--background-2)`,
        };
      } else if (zoom === 14) {
        return {
          width: "280px",
          borderRight: "1px solid var(--accent-light)",
          background: `var(--background-2)`,
        };
      } else {
        return {
          width: "40px",
          borderRight: "1px solid var(--accent-light)",
          background: `var(--background-2)`,
        };
      }
    }
  };

  return (
    <>
      <div
        className="user-row-week"
        style={calculateWidth()}
        onMouseDown={(e) => {
          if (e.button === 1) return;
          if (["contractor", "regular"].includes(userRole)) {
            setInAppNotification(t("notifications.planning.create_bookings_permissions"));
            return;
          }

          if (userRole === "self_planner" && user.id !== profile.id) {
            setInAppNotification(t("notifications.planning.self_planner_creating_error"));
            return;
          }

          mouseDowned(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers);
        }}
        onMouseMove={(e) => {
          if (["contractor", "regular"].includes(userRole) || (userRole === "self_planner" && user.id !== profile.id)) {
            return;
          }

          mouseMoved(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), e);
        }}
        onMouseUp={(e) => {
          if (e.button === 1) return;
          mouseUped(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers, e);
        }}
        onDragOver={(e) => {
          changeTooltip({ open: false });
          e.preventDefault();
          e.stopPropagation();
        }}
      />

      <div className="user-row-weekends">
        <div
          className="user-row-weekends"
          onMouseDown={(e) => {
            if (e.button === 1) return;
            if (
              (["contractor", "regular"].includes(userRole) || weekendsHidden) &&
              !useShiftPressedStore.getState().shiftPressed
            )
              return;

            if (userRole === "self_planner" && user.id !== profile.id) {
              setInAppNotification(t("notifications.planning.self_planner_creating_error"));
              return;
            }

            mouseDowned(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers);
          }}
          onMouseMove={(e) => {
            if (weekendsHidden && !useShiftPressedStore.getState().shiftPressed) {
              return;
            }
            if (["contractor", "regular"].includes(userRole) || (userRole === "self_planner" && user.id !== profile.id))
              return;

            mouseMoved(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), e);
          }}
          onMouseUp={(e) => {
            if (weekendsHidden) {
              return;
            }

            mouseUped(getPlannerOffsetX(e), getPlannerOffsetY(e, zoom), filteredUsers, e);
          }}
          onDragOver={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          style={{
            ...calculateWeekendStyle(filteredUsers.findIndex(({ id }) => user.id === id)),
            ...{
              borderRight: isLastWeek ? "1px solid var(--stroke)" : "unset",
              borderBottomRightRadius: isLastWeek && isLastUser && 7,
            },
          }}
        />

        {weekendsHidden && !isLastWeek && <div className="date-row__weekend-cell__right-border" />}
      </div>
    </>
  );
};

export default GroupedUserRowWeek;
