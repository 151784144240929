import React, { useEffect, useState } from "react";
import LoadingComponent from "../../../default_components/LoadingComponent";
import { useTranslation } from "react-i18next";
import DialogContent from "@mui/material/DialogContent";
import CreditCardForm from "./CreditCardForm";
import HostedFieldsUpdate from "./HostedFieldsUpdate";
import { Dialog } from "@mui/material";
import clockImage from "../../../default_images/clock.svg";
import $ from "jquery";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import lsKeys from "../../../default_values/defaultKeys";

const ChangePaymentModal = ({ changePaymentRequest, loading, closeModal, creditCard, setChangePaymentDialogOpened }) => {
  const isDarkTheme = localStorage.getItem(lsKeys.THEME) === "dark";

  const { t } = useTranslation();

  const [token, setToken] = useState();
  const [errorText, setErrorText] = useState();
  const [loadingText, setLoadingText] = useState();

  useEffect(() => HostedFieldsUpdate(setToken, changePaymentRequest, setErrorText, setLoadingText, isDarkTheme), []);

  useEffect(() => {
    setLoadingText(null);

    setTimeout(() => {
      setErrorText(null);
      $(".pay-button").prop("disabled", false);
    }, 4000);
  }, [errorText]);

  return (
    !loading &&
    (token ? (
      <Dialog
        className="subscription-modal"
        maxWidth={430}
        open={true}
        onClose={() => setChangePaymentDialogOpened(false)}
        aria-labelledby="max-width-dialog-title"
      >
        <div className="subscription-modal-dialog__title" id="add-user-dialog">
          <p>{t("settings.subscription.customer_text")}</p>

          <TeambookIcon
            name={icons.CROSS}
            onClick={closeModal}
            style={{
              height: 24,
              width: 24,
            }}
          />
        </div>

        <DialogContent style={{ width: 400 }}>
          {creditCard.last_4 && <p>Your current credit card number is **** **** **** {creditCard.last_4}.</p>}
          <p>
            Expiring at {creditCard.expiration_month}/{creditCard.expiration_year}
          </p>

          <CreditCardForm />

          {loadingText && (
            <p className="blink-text">
              {loadingText} <img src={clockImage} />
            </p>
          )}

          {errorText && <p className="error-text">{errorText}</p>}
        </DialogContent>
      </Dialog>
    ) : (
      <LoadingComponent />
    ))
  );
};

export default ChangePaymentModal;
