import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ExceedProjectsDialog from "../ExceedProjectsDialog";
import TeambookBlueButton from "../../../default_components/TeambookBlueButton";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { displayShortDate, isoCodes } from "../../../default_values/defaultDateFormats";
import ProjectAdvancedTab from "../default_components/ProjectAdvancedTab";
import { TeambookIcon } from "../../../default_images/TeambookIcon";
import { icons } from "../../../default_images/IconsList";
import GeneralContent from "./GeneralContent";
import StatisticsContent from "./StatisticsContent";
import { shallow } from "zustand/shallow";
import { useAccountStore } from "../../../../stores/accountStore";

const ShowProject = ({
  project,
  closeShow,
  onEdit,
  page,
  deleteProject,
  users,
  copyCalendarURL,
  reactivateProject,
  archivedSelect,
  tab,
  setTab,
  getProjects,
}) => {
  const { t } = useTranslation();

  const projectTabs = [
    { value: "general", title: t("users.general_info"), icon: icons.PROJECT },
    { value: "tasks", title: t("planning.filter.advanced"), icon: icons.ADVANCED },
    { value: "statistics", title: t("projects.statistics"), icon: icons.ADVANCED },
  ];

  const [exceedWindowOpened, setExceedWindowOpened] = useState(false);

  const [selectedTasks, setSelectedTasks] = useState(project.tasks.map((t) => ({ value: t.id, name: t.name })));

  const [customFields] = useState(JSON.parse(project.custom_fields || "{}"));

  const [account] = useAccountStore((state) => [state.account], shallow);

  const managerName = (project) => {
    if (project.manager_id === null) return "-";

    return users.filter((user) => user.id === project.manager_id)[0]?.name;
  };

  const renderTabContent = () => {
    switch (tab.value) {
      case "general":
        return <GeneralContent project={project} projectsData={projectsData} users={users} />;
      case "tasks":
        return (
          <ProjectAdvancedTab
            project={project}
            asyncCreate
            changeable={false}
            tasks={selectedTasks}
            setTasks={setSelectedTasks}
            updateProjectsList={getProjects}
            customFields={customFields}
          />
        );
      case "statistics":
        return <StatisticsContent project={project} />;
    }
  };

  const projectsData = [
    [
      { title: t("projects.name"), value: project.name },
      { title: t("projects.short_name"), value: project.code },
    ],
    [
      { title: t("projects.client"), value: project.client_name || "-" },
      {
        title: t("users.analytics"),
        value: project.kind === "billable" ? t("projects.billable") : t("projects.non_billable"),
      },
    ],
    [
      {
        title: t("projects.estimated"),
        value: (
          <div className="project-show__estimated">
            <p>{project.estimated || "-"} h</p>
            <p>{Math.round(project.estimated / 8) || "-"} md</p>
          </div>
        ),
      },
      { title: t("projects.manager"), value: managerName(project) || "-" },
    ],
    [
      { title: t("projects.status"), value: project.status || "-" },
      {
        title: t("projects.business_unit"),
        value: project.business_unit || "-",
      },
    ],
    [
      {
        title: t("projects.start_date"),
        value: project.start_date ? displayShortDate(project.start_date, account.date_format) : "-",
      },
      {
        title: t("projects.end_date"),
        value: project.end_date ? displayShortDate(project.end_date, account.date_format) : "-",
      },
    ],
  ];

  return (
    <Dialog
      open={page === "show"}
      onClose={() => {
        closeShow();
        setTab(projectTabs[0]);
      }}
      className="form-show project-form"
    >
      <DialogTitle>
        {tab.value === "general" ? (
          <p>{t("projects.project_info")}</p>
        ) : (
          <p>{`${t("planning.filter.project")}: ${project.code}`}</p>
        )}

        <TeambookIcon
          className="form_close"
          name={icons.CROSS}
          onClick={() => {
            closeShow();
            setTab(projectTabs[0]);
          }}
        />
      </DialogTitle>
      <DialogContent
        style={{
          flexDirection: "column",
          padding: "0px 40px 0px 40px",
        }}
      >
        <div style={{ width: "100%" }} className="flex users-data__tabs">
          {projectTabs.map((tabValue) => (
            <div
              className={`users-data__tab ${tab.value === tabValue.value && "active"}`}
              data-active={tab.value === tabValue.value}
              onClick={() => setTab(tabValue)}
            >
              <TeambookIcon
                color={tab.value === tabValue.value ? "alternative_default" : "default"}
                name={tabValue.icon}
              />
              <p>{tabValue.title}</p>
            </div>
          ))}
        </div>

        {renderTabContent()}
      </DialogContent>

      <DialogActions style={{ padding: "15px 40px 40px 40px" }}>
        <>
          <TeambookIcon
            id="deleteProjectFormButton"
            color="alternative_default"
            name={icons.TRASH}
            onClick={() => deleteProject(project)}
            tooltipTitle={archivedSelect === "Active" ? t("projects.deactivate") : t("delete")}
          />

          {project.bookings_duration > 0 && (
            <TeambookIcon
              id="copyCalendarFormButton"
              color="alternative_default"
              name={icons.CALENDAR}
              onClick={() => copyCalendarURL(project)}
              tooltipTitle={t("projects.copy_calendar")}
            />
          )}

          {archivedSelect === "Archived" && (
            <TeambookIcon
              id="reactivateProjectFormButton"
              name={icons.PROJECT_ADD}
              onClick={() => reactivateProject(project)}
              tooltipTitle={t("projects.activate_project")}
            />
          )}

          <TeambookBlueButton
            onClick={onEdit}
            className="form__edit-button"
            variant="contained"
            text={t("projects.edit_project")}
            disabled={archivedSelect === "Archived"}
            style={{
              border: "1px solid #4C54A0 !important",
              backgroundColor: "#4C54A0",
            }}
          />
        </>
      </DialogActions>

      <ExceedProjectsDialog
        open={exceedWindowOpened}
        handleClose={() => {
          setExceedWindowOpened(false);
        }}
      />
    </Dialog>
  );
};

export default ShowProject;
