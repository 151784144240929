import React, { useContext, useEffect, useState } from "react";
import Api from "../../Api";
import accountSorting from "./accountSorting";
import LoginPage from "./LoginPage";
import ControlPanel from "./ControlPanel";
import "./administration.scss";
import { ThemeContext } from "../../contexts/ThemeContext";

const ADMIN_TOKEN_KEY = "tb-admin-token";

export default () => {
  const [adminToken, setAdminToken] = useState(localStorage.getItem(ADMIN_TOKEN_KEY));
  const [accounts, setAccounts] = useState([]);
  const { setIsAdminTheme } = useContext(ThemeContext);

  useEffect(() => {
    setIsAdminTheme(true);
  }, []);

  useEffect(() => {
    if (adminToken) {
      Api.Administrators.accounts(adminToken)
        .then((response) => {
          setAccounts(response.data);
        })
        .catch((error) => {
          if (error.response.status === 500) {
            alert(error.response.status + " " + error.response.statusText);
            return;
          } else {
            localStorage.removeItem(ADMIN_TOKEN_KEY);
            alert(error.response.data.error);
          }
        });
    }
  }, [adminToken]);

  const sortAccounts = (field) => {
    accountSorting(field, accounts, setAccounts);
  };

  if (adminToken === null) {
    return <LoginPage setAdminToken={setAdminToken} />;
  }

  return (
    <div>
      <table className="table table-hover">
        <thead className="table-hover__head">
          <tr>
            <th scope="col" onClick={() => sortAccounts("id")}>
              Id (Team Id)
            </th>
            <th scope="col" onClick={() => sortAccounts("name")}>
              Name
            </th>
            <th scope="col" onClick={() => sortAccounts("language")}>
              Language
            </th>
            <th scope="col" onClick={() => sortAccounts("users")}>
              Users
            </th>
            <th scope="col" onClick={() => sortAccounts("projects")}>
              Projects (a/d/t)
            </th>
            <th scope="col" onClick={() => sortAccounts("clients")}>
              Clients
            </th>
            <th scope="col" onClick={() => sortAccounts("bookings")}>
              B/A/C
            </th>
            <th scope="col" onClick={() => sortAccounts("created_at")}>
              Created At
            </th>
            <th scope="col" onClick={() => sortAccounts("last_seen")}>
              Last Update
            </th>
            <th scope="col" onClick={() => sortAccounts("active_days")}>
              Active days
            </th>
          </tr>
        </thead>
        <tbody>
          {accounts.map((account) => (
            <tr key={account.id} className={account.subscribed && "account-subscribed"}>
              <td>
                {account.id} ({account.teams[0]})
              </td>
              <td>{account.name}</td>
              <td>{account.language}</td>
              <td>{account.users_amount}</td>
              <td>
                {account.active_projects}/{account.deactivated_projects}/{account.time_off_projects}
              </td>
              <td>{account.clients_amount}</td>
              <td>
                {account.bookings_amount}/{account.actual_logs_count}/{account.capacity_reservations}
              </td>

              <td>
                {new Date(account.created_at).getFullYear() +
                  "-" +
                  (new Date(account.created_at).getMonth() + 1) +
                  "-" +
                  new Date(account.created_at).getDate()}
              </td>
              <td>
                {new Date(account.last_updated).getFullYear() +
                  "-" +
                  (new Date(account.last_updated).getMonth() + 1) +
                  "-" +
                  new Date(account.last_updated).getDate()}
              </td>
              <td>{((new Date(account.last_updated) - new Date(account.created_at)) / (1000 * 60 * 60 * 24)).toFixed(0)}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <ControlPanel />
    </div>
  );
};
