import React, { useContext } from "react";
import { MonthsOfTheYear } from "../../../lib/DateUtils";
import { PlannerContext } from "../context/PlannerContext";
import { DateTime } from "luxon";

const monthName = (zoom, date, t, hideMonthName) => {
  let monthsArray = MonthsOfTheYear(t);
  const { plannerDate } = useContext(PlannerContext);

  if (
    !hideMonthName &&
    (date.getDate() === 1 ||
      DateTime.fromJSDate(plannerDate).startOf("day").ts === DateTime.fromJSDate(date).startOf("day").ts)
  ) {
    return <p className="planner-month-name">{monthsArray[date.getMonth()]}</p>;
  }
};

export default monthName;
